import { ChangeDetectorRef, Component } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { banner } from '@services/decorators/class.decorator';

import { WindowRefService } from '@shared/window-ref';

import { BannerBase } from '../banner.base';

@banner({
    position: 3,
})
@Component({
    selector: 'app-banner[mobile-layer]',
    templateUrl: './mobile-layer.component.html',
    styleUrls: ['./mobile-layer.component.scss'],
})
export class BannerMobileLayerComponent extends BannerBase {
    public bannerPositionId = 3;
    public visible: boolean = true;

    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef,
        protected win: WindowRefService) {
        super(deviceService, ref, win);
    }


    public close(): void {
        this.visible = false;
    }
}
