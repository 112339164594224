<ng-container *ngIf="isTennis && loaded && !loadedError">
    <div class="pt-4">
        <ng-container></ng-container>
        <!--ATP-->
        <ng-container *ngIf="atp | async as tab">
            <h6 class="match-info-head text-white mb-0 text-uppercase bg-dark p-2">ATP</h6>
            <ul class="nav nav-tabs" id="tennisRankingTab" role="tablist">
                <li class="nav-item" *ngFor="let nav of tab | keyvalue; first as isFirst">
                    <a
                        class="nav-link"
                        [class.active]="isFirst"
                        id="tennisRankingTab-{{ nav.key }}"
                        data-toggle="tab"
                        href="#tab-atp-{{ nav.key }}"
                        role="tab"
                        aria-controls="info"
                        [attr.ariaSelected]="isFirst ? 'true' : 'false'"
                    >
                        {{ 'web.' + nav.key + '_tab_tennis' | translate }}
                    </a>
                </li>
            </ul>

            <div class="tab-content">
                <div
                    class="tab-pane"
                    *ngFor="let nav of tab | keyvalue; first as isFirst"
                    [class.active]="isFirst"
                    id="tab-atp-{{ nav.key }}"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                >
                    <ng-container *ngIf="nav.value?.length > 0">
                        <table class="table table-tennis-ranking">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">&nbsp;</th>
                                    <th scope="col">{{ 'web.player_name' | translate }}</th>
                                    <th
                                        scope="col"
                                        title="{{ 'web.competitions_played' | translate }}"
                                        class="text-center"
                                    >
                                        <svg class="icon icon--16"><use xlink:href="#icon-cup" /></svg>
                                    </th>
                                    <th scope="col" class="text-right">{{ 'web.player_points' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let player of nav.value | slice : 0 : atpslice; first as isFirst2"
                                    [class.tennis-ranking-first-row]="player.ranking === 1"
                                >
                                    <th scope="row" [class.tennis-ranking-first]="player.ranking === 1">
                                        {{ player.ranking }}
                                        <ng-container *ngIf="+player.movement != 0">
                                            <svg *ngIf="+player.movement > 0" class="icon icon--8 icon--success ml-2">
                                                <use xlink:href="#icon-arrow-up"></use>
                                            </svg>
                                            <svg *ngIf="+player.movement < 0" class="icon icon--8 icon--danger ml-2">
                                                <use xlink:href="#icon-arrow-down"></use>
                                            </svg>
                                            &nbsp;<small
                                                [class.text-success]="+player.movement > 0"
                                                [class.text-danger]="+player.movement < 0"
                                                >{{ player.movement }}</small
                                            >
                                        </ng-container>
                                    </th>
                                    <td class="text-center">
                                        <img
                                            *ngIf="player.info.image_country !== null && player.ranking !== 1"
                                            src="{{ player.info.image_country }}"
                                            alt="{{ player.info.name }}"
                                            width="16"
                                            class="mr-2 rounded-circle"
                                        />
                                        <img
                                            *ngIf="player.info.image !== null && player.ranking === 1"
                                            src="{{ player.info.image }}"
                                            alt="{{ player.info.name }}"
                                            style="max-width: 60px"
                                            class="img-fluid rounded-circle"
                                        />
                                    </td>
                                    <td [class.tennis-ranking-first]="player.ranking === 1">
                                        <ng-container *ngIf="isFirst; else doubles">
                                            <a
                                                [routerLink]="[
                                                    '/page/sport/participant/' +
                                                        sportName +
                                                        '-' +
                                                        sportId +
                                                        '/' +
                                                        player.participantId
                                                ]"
                                                class="text-reset"
                                                >{{ player.info.name }}</a
                                            >
                                        </ng-container>
                                        <ng-template #doubles>
                                            {{ player.info.name }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        {{ player.competitions_played ?? '-' }}
                                    </td>
                                    <td [class.tennis-ranking-first]="player.ranking === 1" class="text-right">
                                        {{ player.points }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mt-1 mb-2 text-center">
                            <a (click)="atpslice = 100" class="text-reset app-cursor" *ngIf="atpslice == 5">{{
                                'web.show_more' | translate
                            }}</a>
                            <a (click)="atpslice = 5" class="text-reset app-cursor" *ngIf="atpslice == 100">{{
                                'web.show_less' | translate
                            }}</a>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!--ATP END-->
        <!--WTA-->
        <ng-container *ngIf="wta | async as tab">
            <h6 class="match-info-head text-white mb-0 text-uppercase bg-dark p-2">WTA</h6>
            <ul class="nav nav-tabs" id="tennisRankingTab" role="tablist">
                <li class="nav-item" *ngFor="let nav of tab | keyvalue; first as isFirst">
                    <a
                        class="nav-link"
                        [class.active]="isFirst"
                        id="tennisRankingTab-{{ nav.key }}"
                        data-toggle="tab"
                        href="#tab-wta-{{ nav.key }}"
                        role="tab"
                        aria-controls="info"
                        [attr.ariaSelected]="isFirst ? 'true' : 'false'"
                    >
                        {{ 'web.' + nav.key + 'tab_tennis' | translate }}
                    </a>
                </li>
            </ul>

            <div class="tab-content">
                <div
                    class="tab-pane"
                    *ngFor="let nav of tab | keyvalue; first as isFirst"
                    [class.active]="isFirst"
                    id="tab-wta-{{ nav.key }}"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                >
                    <ng-container *ngIf="nav.value?.length > 0">
                        <table class="table table-tennis-ranking">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">&nbsp;</th>
                                    <th scope="col">{{ 'web.player_name' | translate }}</th>
                                    <th scope="col">
                                        <svg class="icon icon--16 icon--white"><use xlink:href="#icon-cup" /></svg>
                                    </th>
                                    <th scope="col" class="text-right">{{ 'web.player_points' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let player of nav.value | slice : 0 : wtaslice; first as isFirst2"
                                    [class.tennis-ranking-first-row]="player.ranking === 1"
                                >
                                    <th scope="row" [class.tennis-ranking-first]="player.ranking === 1">
                                        {{ player.ranking }}
                                        <ng-container *ngIf="+player.movement != 0">
                                            <svg *ngIf="+player.movement > 0" class="icon icon--8 icon--success ml-2">
                                                <use xlink:href="#icon-arrow-up"></use>
                                            </svg>
                                            <svg *ngIf="+player.movement < 0" class="icon icon--8 icon--danger ml-2">
                                                <use xlink:href="#icon-arrow-down"></use>
                                            </svg>
                                            &nbsp;<small
                                                [class.text-success]="+player.movement > 0"
                                                [class.text-danger]="+player.movement < 0"
                                                >{{ player.movement }}</small
                                            >
                                        </ng-container>
                                    </th>
                                    <td class="text-center">
                                        <img
                                            *ngIf="player.info.image_country !== null && player.ranking !== 1"
                                            src="{{ player.info.image_country }}"
                                            alt="{{ player.info.name }}"
                                            width="16"
                                            class="mr-2 rounded-circle"
                                        />
                                        <img
                                            *ngIf="player.info.image !== null && player.ranking === 1"
                                            src="{{ player.info.image }}"
                                            alt="{{ player.info.name }}"
                                            style="max-width: 60px"
                                            class="img-fluid rounded-circle"
                                        />
                                    </td>
                                    <td [class.tennis-ranking-first]="player.ranking === 1">
                                        <ng-container *ngIf="isFirst; else doubles">
                                            <a
                                                [routerLink]="[
                                                    '/page/sport/participant/' +
                                                        sportName +
                                                        '-' +
                                                        sportId +
                                                        '/' +
                                                        player.participantId
                                                ]"
                                                class="text-reset"
                                                >{{ player.info.name }}</a
                                            >
                                        </ng-container>
                                        <ng-template #doubles>
                                            {{ player.info.name }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        {{ player.competitions_played ?? '-' }}
                                    </td>
                                    <td [class.tennis-ranking-first]="player.ranking === 1" class="text-right">
                                        {{ player.points }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mt-1 mb-2 text-center">
                            <a (click)="wtaslice = 100" class="text-reset app-cursor" *ngIf="wtaslice == 5">
                                {{ 'web.show_more' | translate }}</a
                            >
                            <a (click)="wtaslice = 5" class="text-reset app-cursor" *ngIf="wtaslice == 100">{{
                                'web.show_less' | translate
                            }}</a>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!--WTA END-->
    </div>
</ng-container>
