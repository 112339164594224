<ng-container *ngIf="ads && !isClosed">
    <div class="d-flex justify-content-center adds-mobile-layer">
        <a (click)="close()" class="adds-mobile-layer-close app-cursor">{{ 'web.close' | translate | transinit }}</a>
        <div
            *ngFor="let add of this.ads; trackBy: trackById"
            [id]="add.pageElementId"
            style="width: {{ add.width ?? 'auto' }};height: {{ add.height ?? 'auto' }}"
        ></div>
    </div>
</ng-container>
