/* eslint-disable no-console */
import { Component, OnInit, OnDestroy, Inject, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Scroll, Event } from '@angular/router';
import { filter, switchMap, distinctUntilChanged, map, delay, concatMap } from 'rxjs/operators';
import { of, Subscription, merge, Observable, timer } from 'rxjs';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { Store } from '@ngxs/store';
import { UpdateMarkets } from 'src/app/store/actions/bets.action';
import * as Hammer from 'hammerjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SportService } from 'src/app/services/sport.service';

import { TranslateService } from '@ngx-translate/core';

import { LangService } from '@services/lang.service';

import APP_CONFIG from '@config/app.config';
import { ViewportScroller } from '@angular/common';

import { MicroModalComponent } from '@components/my-matches/micro-modal/micro-modal.component';

import { PageService } from '@services/page.service';

import { SportConfigToken } from '@config/sport.config';

import { TournamentService } from '@services/tournament.service';

import { UtilsService } from '@services/utils.service';

import { FavoriteService } from '@services/favorite.service';

import { environment } from '@environments/environment';

import { MyMatchesService } from '@services/my-matches.service';


import { PageReload } from '@store/actions/page.action';
import { CodeState, MatchData } from '@interfaces/match-data.interface';
import { MyMatchesNotificationService } from '@services/my-matches-notification.service';

import { StorageService } from '@services/storage.service';

import { SnackBarService } from '@/services/snack-bar.service';

declare const moment: any;


@Component({
    selector: 'app-page-sport',
    templateUrl: './page-sport.component.html',
})
export class PageSportComponent implements OnInit, OnDestroy {
    public sportId: number | null = null;

    public sportName: string;

    public categoryId: number | null = null;

    public date: string | null = null;

    private subscripton: Subscription;

    private subscripton2: Subscription;

    public show: boolean = false;

    private subscriberScroll: Subscription;

    private subscriberMyMatches: Subscription;


    public constructor(
        private router: ActivatedRoute,
        private route: Router,
        private store: Store,
        private tournament: TournamentService,
        public deviceService: DeviceDetectorService,
        private sport: SportService,
        private page: PageService,
        private trans: TranslateService,
        private snackbar: SnackBarService,
        private lang: LangService,
        private viewportScroller: ViewportScroller,
        private storage: StorageService,
        private favorite: FavoriteService,
        public matches: MyMatchesService,
        public matchesNtf: MyMatchesNotificationService,
        private viewRef: ViewContainerRef,

        @Inject(SportConfigToken) public sportConfig: Record<string, any>,
    ) {}


    public ngOnInit(): void {
        // MyMatches notification start
        this.subscriberMyMatches = timer(0, environment.myMatchesNotification)
            .pipe(filter((): boolean => {
                const snap = this.store.selectSnapshot<boolean>((state: any) => state.page.matchNtfMode);
                return +(this.favorite.getMatchFavorites()).length > 0 && snap;
            },
            )).subscribe(() => {
                this.matches.get(this.favorite.getMatchFavorites()).then((subscribe) => {
                    subscribe
                        .pipe(map((val):MatchData[] => {
                            const val2 = [...val.live, ...val.finished, ...val.all].filter(
                                v => this.sportConfig.myMatches.notifications
                                    .includes(v.sport_code_name)) as MatchData[];
                            return val2;
                        }))
                        .subscribe((val: MatchData[]): void => {
                            val.forEach((m: MatchData) => {
                                const currentData = moment();
                                const matchDate = moment(m.start_date);
                                const diffDays = currentData.diff(matchDate, 'days');

                                if (this.sportConfig.myMatches.favoriteDayLimit < diffDays &&
                                    m.code_state === CodeState.ENDED) {
                                    this.favorite.toggleMatchFavorite(m.id);
                                }
                            });
                            val.forEach(async (match) => {
                                await this.matchesNtf.add(match);
                            });
                        });
                });
            });

        this.matchesNtf.matchSubject$.pipe(
            concatMap(val => of(val).pipe(delay(environment.myMatchesNtfDuration)))).subscribe((val) => {
            if ('setAppBadge' in navigator) {
                // Just display the badge, with no number in it.
                (navigator as any).setAppBadge();
            }

            this.openSnackBar(val);
        });
        this.page.onSnackBarMyMatchesClose.subscribe(() => {
            this.snackbar.dismiss(this.viewRef);
        });
        // MyMatches notification end

        // this.scrollTo(1300);
        this.store.dispatch(new PageReload(this.page.detectReload())).subscribe(async (state) => {
            if (state.page.pageReload > APP_CONFIG.pageReloadInfoMin) {
                await this.lang.getLang();
                this.trans
                    .get('web.manual_reload_not_need')

                    .subscribe((message: string) => {
                        this.store.dispatch(new PageReload(false));
                        this.snackbar.openSnackBar(message, 'top', 'info');
                    });
            }
        });
        const one = this.route.events.pipe(
            filter((event): boolean => event instanceof NavigationEnd),
            switchMap((): Observable<any> => (this.router.firstChild && this.router.firstChild.params) || of({})),
            distinctUntilChanged(),
        );

        const two = this.router.firstChild!.paramMap.pipe(switchMap((p): Observable<any> => of(p)));

        this.subscripton = merge(one, two).subscribe((val: any): void => {
            if (!_.isUndefined(val.get)) {
                if (val.has('sport')) {
                    [this.sportId, this.sportName] = UtilsService.parseSportUrl(val.get('sport'));
                    this.checkIfSportExists();
                }
                if (val.has('date')) {
                    this.date = val.get('date');
                }
            } else {
                if (_.has(val, 'sport')) {
                    [this.sportId, this.sportName] = UtilsService.parseSportUrl(val.sport);
                }
                if (_.has(val, 'date')) {
                    this.date = val.date;
                }
            }
        });

        this.tournament.loaded.subscribe((val: any): void => {
            this.categoryId = val;
        });

        if (this.deviceService.isMobile()) {
            let blockSwipe = false;
            let blockSwipeLeft = false;

            this.sport.onBetStatsSwipe.subscribe(() => {
                blockSwipe = true;
                blockSwipeLeft = true;
            });

            const myElement2 = document.querySelector('header') as HTMLElement;
            const mc2 = new Hammer(myElement2);
            mc2.on('swiperight', (): void => {
                blockSwipe = true;
            });

            const myElement = document.querySelector('body') as HTMLElement;
            const mc = new Hammer(myElement);
            mc.on('swipeleft', (): void => {
                if (!blockSwipeLeft) {
                    $('.navbar-mobile__close').trigger('click').trigger('tap');
                }
                blockSwipeLeft = false;
            });
            mc.on('swiperight', (): void => {
                if (!blockSwipe) {
                    $('.navbar-toggler:not(.mobile-back-button)').trigger('click').trigger('tap');
                }
                blockSwipe = false;
            });
        }

        this.store.dispatch(new UpdateMarkets());
    }

    public ngOnDestroy(): void {
        if (this.subscripton instanceof Subscription) {
            this.subscripton.unsubscribe();
        }
        if (this.subscripton2 instanceof Subscription) {
            this.subscripton2.unsubscribe();
        }
        if (this.subscriberScroll instanceof Subscription) {
            this.subscriberScroll.unsubscribe();
        }

        if (this.subscriberMyMatches instanceof Subscription) {
            this.subscriberMyMatches.unsubscribe();
        }
    }


    private openSnackBar(match: MatchData): void {
        this.snackbar.openFromComponent(this.viewRef, MicroModalComponent, {
            duration: environment.myMatchesNtfDuration - 200,
            verticalPosition: 'bottom',
            horizontalPosition: this.deviceService.isMobile() ? 'center' : 'left',
            data: { match },
        });
    }

    private scrollTo(timer: number = 1000): void {
        this.subscriberScroll = this.route.events
            .pipe(filter((e: Event): e is Scroll => e instanceof Scroll))
            .subscribe((e) => {
                const isMatchList = e.routerEvent.urlAfterRedirects.includes('/match-list/');

                // const lastScroll = +this.storage.get('APP_LAST_SCROLL');
                // if (lastScroll === 0) {
                //     this.storage.set('APP_LAST_SCROLL', 1);
                //     setTimeout((): void => {
                //         this.viewportScroller.scrollToPosition([0, 0]);
                //     }, timer);
                // } else
                if (e.position && isMatchList) {
                    // backward navigation
                    setTimeout((): void => {
                        this.viewportScroller.scrollToPosition([0, e.position![1]]);
                    }, timer);
                } else if (!e.position && isMatchList) {
                    // backward navigation
                    setTimeout((): void => {
                        this.viewportScroller.scrollToPosition([0, 0]);
                    }, timer);
                } else if (e.anchor) {
                    // anchor navigation
                    this.viewportScroller.scrollToAnchor(e.anchor);
                }
            });
    }

    /**
     * Check if sport exitst if nto redirect to soccer
     */
    private checkIfSportExists(): void {
        this.subscripton2 = this.sport.onLoad.subscribe((data): void => {
            if (data.today) {
                // navigate to soccer if sport doeas nto exits
                const find = data.today.find((v): boolean => v.code_name === this.sportName);
                if (find === undefined) {
                    this.route.navigate(['/page/sport/match-list/soccer-5']);
                }
            }
        });
    }
}
