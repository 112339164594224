import { ChangeDetectorRef, Component } from '@angular/core';

import { trigger } from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { banner } from '@services/decorators/class.decorator';

import { WindowRefService } from '@shared/window-ref';

import { BANNER_FIXED_ANIMATION } from '../../../shared/animations/banner-fixed.animation';
import { BannerBase } from '../banner.base';

@banner({
    position: 2,
})
@Component({
    selector: 'app-banner[fixed]',
    templateUrl: './bottom-fixed.component.html',
    styleUrls: ['./bottom-fixed.component.scss'],
    animations: [trigger('bannerFixed', BANNER_FIXED_ANIMATION)],
})
export class BannerBottomFixedComponent extends BannerBase {
    public bannerPositionId = 2;
    public wasClosed = false;
    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef,
        protected win: WindowRefService) {
        super(deviceService, ref, win);
    }


    /**
     * Dismiss banner
     */
    public close(): void {
        this.banner = null;
        this.wasClosed = true;
    }
}
