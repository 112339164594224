<nav>
    <ul class="pagination justify-content-center mt-5">
        <li class="page-item app-cursor" [class.disabled]="currentPage === 1">
            <a
                class="page-link"
                (click)="goTo(currentPage - 1)"
                aria-label="Předchozí"
                tabindex="-1"
                [attr.aria-disabled]="currentPage === 1"
            >
                <span aria-hidden="true">«</span>
            </a>
        </li>
        <li
            class="page-item app-cursor"
            [class.active]="p === currentPage"
            *ngFor="let p of pageRange; trackBy: trackByIndex"
        >
            <a class="page-link" (click)="goTo(p)">{{ p }}</a>
        </li>
        <li class="page-item app-cursor" [class.disabled]="currentPage === lastPage">
            <a
                class="page-link"
                (click)="goTo(currentPage + 1)"
                aria-label="Další"
                [attr.aria-disabled]="currentPage === lastPage"
            >
                <span aria-hidden="true">»</span>
            </a>
        </li>
    </ul>
</nav>
