<section class="app-player-match-detail d-flex flex-column" [class.active]="visible" data-cy="player-match-stats">
    <div class="player-card work" *ngIf="player">
        <div class="img-section">
            <img
                height="140"
                class="img"
                loading="lazy"
                (error)="imageError($event, 'avatar')"
                [attr.src]="player.photo | responsive: 'participant':'medium'"
                [alt]="player.name"
            />

            <h1 class="headline pl-2">
                {{ name }}
                <img
                    height="16"
                    *ngIf="player.flag"
                    class="mr-1 align-self-center ng-star-inserted"
                    [src]="player.flag"
                />
            </h1>
        </div>
        <div class="player-card-desc">
            <div class="player-card-header">
                <div class="player-card-title">
                    <strong>{{ player.home }} - {{ player.away }}</strong>
                </div>
                <div class="player-card-menu ml-2">
                    <span class="close app-cursor" (click)="close()">&times;</span>
                </div>
            </div>
            <!-- .nodata class in case of no player data-->
            <div class="player-card-data nodata" [class.nodata]="!hasStats">
                <ng-container *ngFor="let val of stats; trackBy: trackByIndex">
                    <div class="d-flex justify-content-between player-card-data__item">
                        <ng-container *ngIf="val[0].startsWith('Line'); else data">
                            <hr class="hr" />
                        </ng-container>
                        <ng-template #data>
                            <div class="font-weight-bold">
                                {{ 'web.playerstats_tooltip_' + snakeCase(val[0]) | translate }}
                            </div>
                            <div>{{ val[1] | statsVal: val[0] }}</div>
                        </ng-template>
                    </div>
                </ng-container>

                <div *ngIf="!hasStats" class="d-flex justify-content-center align-items-center player-card-nodata">
                    <div>{{ 'web.no_player_match_data' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
</section>
