<div class="ng-star-inserted">
    <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
        <span class="font-weight-bold text-uppercase">
            <!-- <img
                *ngIf="flag"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                [src]="flag"
                alt="Real Madrid"
                title=""
                data-original-title="Real Madrid"
                class="ng-star-inserted"
            /> -->
            {{ name }}
        </span>
    </div>
    <div class="bg-white">
        <div id="collapse-drivers" class="collapse show">
            <div class="table-responsive">
                <table class="table table-sm stage-table">
                    <tbody>
                        <tr class="ng-star-inserted" *ngFor="let nav of $nav | async">
                            <td
                                class="align-middle"
                                *ngFor="let val of nav; trackBy: trackByIndex"
                                [attr.colspan]="nav.length === 1 ? 2 : 1"
                            >
                                <a class="text-reset" [routerLink]="['../', val.id]"
                                    ><img alt="" height="16" [src]="val.image" class="ng-star-inserted" />
                                    {{ val.name }}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
