/**
 *  Service for Rankings
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { RankingObject, RankingObjectServer, RankingSports } from '@interfaces/ranking.interface';

import { catchError, map, retry, shareReplay } from 'rxjs/operators';

import { filter, toPairs } from 'lodash-es';

import { CommonService } from '../shared/common.service';
import URL_CONFIG from '../config/url.config';


@Injectable({
    providedIn: 'root',
})
export class RankingService {
    public constructor(private http: HttpClient, private common: CommonService) {}

    public getRanking(sportName: RankingSports | string): Observable<RankingObject> {
        const url = (URL_CONFIG.api.rankings as Record<string, string>)[sportName] ?? null;

        if (!url) {
            return throwError('Sport ranking not defined');
        }

        const options = {};
        return this.http.get<RankingObjectServer>(url, options).pipe(

            map((data) => {
                const val = filter(toPairs(data), (v => v[1].length > 0)) as RankingObject;

                if (sportName === 'darts') {
                    const index = val.findIndex(v => v[0] === 'pdc_2_year_rolling_order_of_merit');
                    if (val.length > 0 && index && index > 0) {
                        const v = val.splice(index, 1)[0];
                        val.splice(0, 0, v!);
                    }

                    const index2 = val.findIndex(v => v[0] === 'european_tour');

                    if (val.length > 0 && index2 && index2 > 0) {
                        const v = val.splice(index2, 1)[0];
                        val.splice(2, 0, v!);
                    }
                }
                return val;
            }),

            catchError(this.common.errorCallback), // then handle the error
            retry(2),
            shareReplay(1),
        );
    }
}
