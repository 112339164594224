import { SportConfigToken } from '@config/sport.config';
import {
    Component, Inject, Input,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';


import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-side-menu-navigation',
    templateUrl: './side-menu-navigation.component.html',
})
export class SideMenuNavigationComponent extends BaseComponent {
    @Input()
    sportName: string;

    @Input()
    sportId: number;

    public compareModal = false;

    public constructor(@Inject(SportConfigToken) private sportConfig: Record<string, any>,
        public deviceService: DeviceDetectorService) {
        super();
    }


    public trackByFn(index: number, item: Record<string, any>): number {
        return item.id;
    }

    public get isComparition(): boolean {
        return !this.deviceService.isMobile() && this.sportName && this.sportId &&
        this.sportConfig.isComparitionForm(this.sportName);
    }

    public toggleCompareModal(): void {
        this.compareModal = !this.compareModal;
    }
}
