import { Component, OnInit } from '@angular/core';


import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import SPORT_CONFIG from 'src/app/config/sport.config';

import { VenuesForm } from '@shared/forms/venues.form';

import { CountryService } from '@services/country.service';

import { CountryInterface } from '@interfaces/country.interface';

import { Observable } from 'rxjs';

import { InfoService } from '@services/info.service';

import { VenueService } from '@services/venue.service';

import { debounceTime } from 'rxjs/operators';

import { VenueInterface } from '@interfaces/venue.interface';

import type { Datatable, DatatabeSort } from '@interfaces/datatable.interface';

import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

import { PageService } from '../../services/page.service';
import { BaseComponent } from '../base.component';

import { ClientService } from '../../services/client.service';
import { LangService } from '../../services/lang.service';


@Component({
    selector: 'app-venues',
    templateUrl: './venues.component.html',
    animations: [
        trigger('VenueItem', [

            transition(':enter', [
                // style({ opacity: 0 }),
                // animate('1000ms', style({ opacity: 1 })),
                query('.player-record', [
                    style({ opacity: 0 }),
                    stagger(30, [
                        animate('10ms cubic-bezier(0.35, 0, 0.25, 1)',
                            style({ opacity: 1 })),
                    ]),
                ]),
            ]),

        ]),
    ],
})
export class VenuesComponent extends BaseComponent implements OnInit {
    public data: Datatable<VenueInterface> | null = null;

    /**
     * number of sport aside menu
     */
    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    public countryMappedData: Observable<CountryInterface[]>;

    public defaultCountryId: number | null = null;

    public pageNumber: number= 0;

    public limit: number;

    public map = false;
    public mapCoordinates: string | null = null;

    private sortBy: DatatabeSort = [];

    public constructor(
        protected pageService: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public form: VenuesForm,
        private country: CountryService,
        private venue: VenueService,

        private router: ActivatedRoute,


    ) {
        super(pageService, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.countryMappedData = this.country.get();

        this.lang.getLang().then((): Promise<any> => this.createTitle());

        this.form.getGroup().valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.load();
        });

        this.load();
    }


    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_venues').subscribe((): void => {
            const Client = this.client.getVName();
            this.setTitle(
                {
                    title: `${this.translate.instant('web.title_venues')} | ${Client}`,
                    description: this.translate.instant('web.description_venues'),
                },
                true,
            );
        });
    }

    public toggleMap(mapCoordinates: string | null): void {
        this.map = !this.map;
        this.mapCoordinates = mapCoordinates;
    }

    public sort(by: string): void {
        let sort = this.sortBy.find(v => v.field === by);

        if (!sort) {
            sort = { field: by, direction: 'asc' };
            this.sortBy.push(sort);
        } else {
            sort.direction = sort.direction === 'asc' ? 'desc' : 'asc';
        }


        this.load();
    }

    /**
     * Toggle aside menu
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    public search(): void {
        this.sortBy.splice(0, this.sortBy.length);

        this.load();
    }


    public load(page: number = 0): void {
        this.reset();
        this.pageNumber = page;
        this.limit = this.form?.pageNum?.value;
        this.venue.get(page, this.form?.pageNum?.value, this.sortBy, this.form?.fulltext?.value,
            this.form?.country?.value).subscribe({ next: (val) => {
            this.data = val;
        },
        complete: () => {
            this.finished();
        },
        error: () => {
            this.error();
        } });
    }
}
