<div>
    <ng-container *ngIf="loaded; else: elseBlock">
        <ng-container *ngFor="let sport of dataAll | keyvalue; trackBy: trackByKey">
            <div class="table-sport mx-n3 mx-sm-0">
              <h6 class="table-sport__head bg-dark text-white" data-toggle="collapse" [attr.href]="'#collapse-' + sport.key" role="button" aria-expanded="true" aria-controls="collapse-tennis">
                      <!-- <img src="img/sports/tennis.png" alt="Tennis" width="20" class="mr-1"> -->

                  <span class="table-sport__head__text">    {{ 'sport.' + sport.key | translate }}</span>
              </h6>
              <div class="table-sport__content collapse show" id="collapse-{{sport.key}}">
                  <ng-container *ngFor="let category of sport.value | keyvalue | navSearch:'alpha': alphaFilter; trackBy: trackByKey">
                      <div class="table-sport__subhead d-flex align-items-center bg-light" data-toggle="collapse"  [attr.href]="'#collapse-' + sport.key + '-' + category.value[0].sub_tournament_id" role="button" aria-expanded="true"
                      ria-controls="collapse-tennis-1">
                          <img src="{{category.value[0].image}}" width="16" class="mr-2 rounded-circle" alt="{{ category.value[0].category_name }}  {{ category.value[0].sub_tournament_name }}">

                           <div class="table-sport__subhead__text mb-0 text-uppercase text-truncate">{{ category.value[0].category_name }} | {{ category.value[0].sub_tournament_name}}</div>
                          <app-favorite [tournamentId]="category.tournament_id" [type]="'tournament'" [sportId]="category.value[0].sport_id">
                            <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
                            <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
                          </app-favorite>
                          <a  [routerLink]="['/page/sport/event', sport.key + '-' + category.value[0].sport_id, category.value[0].tournament_id]" class="ml-auto small text-reset"><svg class="icon icon--12 mr-2"><use xlink:href="#icon-table"/></svg>
                          {{ 'web.detail' | translate }}</a>
                        <!-- <svg class="icon icon--12 icon--primary ml-2"><use href="#icon-star-fill"/></svg> -->
                      </div>


                      <div class="collapse show" id="collapse-{{sport.key}}-{{ category.value[0].sub_tournament_id }}">
                            <app-component-match   *ngFor="let match of category.value;trackBy: trackById" [dateFormat]="'date'"
                                [match]="match" [sportName]="match.sport_code_name" [sportId]="match.sport_id" [widgetView]="true" (onSelect)="loadMatch($event)">
                            </app-component-match>
                      </div>
                  </ng-container>
              </div>
            </div>
        </ng-container>
        <div *ngIf="isEmpty(dataAll)">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
              <div class="text-center pt-7">
                <svg class="icon icon--64 mb-4"><use xlink:href="#icon-empty"/></svg>
                <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                <p class="mb-6">{{ 'web.no_data_found_add_new' | translate }}</p>
              </div>
            </div>
        </div>
    </ng-container>
    <ng-template #elseBlock> <app-page-loader></app-page-loader> </ng-template>
</div>
