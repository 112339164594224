import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ComponentModule } from '../components/component.module';

import { PagesComponent } from './pages.component';

import { PagesRoutingModule } from './pages-routing.module';


import { PageAccountComponent } from './page-account/page-account.component';
import { DashboardComponent } from './page-account/dashboard/dashboard.component';

import { PageBlankComponent } from './page-blank/page-blank.component';
import { RegistrationConfirmComponent } from './page-blank/registration-confirm/registration-confirm.component';
import { DashboardFavoriteComponent } from './page-account/favorite/favorite.component';
// import { ParticipantCompareComponent } from './page-sport/participant-compare/participant-compare.component';

@NgModule({
    declarations: [
        PagesComponent,
        PageAccountComponent,
        PageBlankComponent,
        DashboardComponent,
        DashboardFavoriteComponent,
        // ParticipantCompareComponent,
        RegistrationConfirmComponent,
    ],
    imports: [CommonModule, SharedModule, PagesRoutingModule, ComponentModule],
})
export class PagesModule {}
