import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-placeholder-tournament',
    templateUrl: './placeholder-tournament.component.html',
    styleUrls: ['./placeholder-tournament.component.scss'],
})
export class PlaceholderTournamentComponent implements OnInit {
    @Input()
    public progress: boolean = true;

    public constructor() {}

    public ngOnInit(): void {}
}
