import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-player-compare',
    templateUrl: './player-compare.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetPlayerCompareComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

    @Input()
    participantId: string | number | null | undefined;

      #headlineTrans = 'web.opta_player_compare';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-player-compare') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_player_compare) {
              Opta.widgets.ref_widget_player_compare.destroy(true);
          }
      }

      getHtml(): string {
          return `
            <opta-widget widget="player_compare"   widget_id="ref_widget_player_compare"
            competition="${this.competitionId}" season="${this.seasonId}" team="${this.participantId}" 
            template="normal" 
            navigation="tabs_more" default_nav="1" show_selects="true" show_crests="false" load="false"
            show_images="false" competition_naming="full" team_naming="full" player_naming="full"
            show_logo="false"  title="${this.transHeadline}" show_title="true" breakpoints="400" sport="football">
            </opta-widget>  

 
          `;
      }
}
