import { ChangeDetectorRef, Component } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { AdsBase } from '../ads.base';


@Component({
    selector: 'app-ads[universal]',
    templateUrl: './universal.component.html',
    styleUrls: ['./universal.component.scss'],
})
export class AdsUniversalComponent extends AdsBase {
    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef) {
        super(deviceService, ref);
    }
}
