import { Component, OnInit } from '@angular/core';
import { StatsService } from 'src/app/services/stats.service';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';

declare const moment: any;

@Component({
    selector: 'app-actual-sport-chart',
    templateUrl: './actual-sport-chart.component.html',
})
export class ActualSportChartComponent implements OnInit {
    public title = 'Angular Charts';

    public view: any[] = [];

    // options for the chart
    public showXAxis = true;

    public showYAxis = true;

    public gradient = false;

    public showLegend = true;

    public showXAxisLabel = true;

    public xAxisLabel = '';

    public showYAxisLabel = true;

    public yAxisLabel = '';

    public timeline = true;

    public colorScheme = {
        domain: [
            '#9370DB',
            '#87CEFA',
            '#FA8072',
            '#FF7F50',
            '#90EE90',
            '#9370DB',
        ],
    };

    // pie
    public showLabels = true;

    public multi: any[] = [];

    public constructor(
        private stats: StatsService,
        private trans: TranslateService,
        private deviceService: DeviceDetectorService,
    ) {}

    public ngOnInit(): void {
        this.stats.actualSports().subscribe((val): void => {
            this.multi = [];

            this.trans
                .get('web.stats_sport_match_xaxis')
                .subscribe((val2): void => {
                    this.xAxisLabel = val2;
                });
            this.trans
                .get('web.stats_sport_match_yaxis')
                .subscribe((val2): void => {
                    this.yAxisLabel = val2;
                });

            val.forEach((data: Record<string, any>): void => {
                const normalize: Record<string, any> = { name: data.codeName, series: [] };

                data.data.forEach((data2: Record<string, any>): void => {
                    normalize.series = [
                        ...normalize.series,
                        {
                            name: moment(data2.day).format('D.M.YYYY'),
                            value: data2.num,
                        },
                    ];
                });

                this.multi = [...this.multi, normalize];

                if (
                    this.deviceService &&
                    (this.deviceService.isMobile() ||
                        this.deviceService.isTablet())
                ) {
                    this.multi = this.multi.slice(0, 3);
                    this.view.push($(window).width()! - 40);
                }
            });
        });
    }
}
