<!-- Check oflline network status-->
<div class="alert-network-offline" [class.alert-network-offline--active]="numNetworkErrors > 3">
    <i class="fas fa-wifi"></i> {{ 'web.page_is_offline' | translate }}
</div>

<router-outlet></router-outlet>

<a (click)="goTop()" class="cd-top text-replace js-cd-top" [class.cd-top--active]="isScroll">
    <i class="material-icons"> arrow_upward </i>
</a>

<app-push-notification *ngIf="!kioskMode && client.use('notification')" id="push-notification"></app-push-notification>
<app-banner-container *ngIf="!isDebug">
    <app-banner fixed #banners></app-banner>
</app-banner-container>

<!-- WAKE LOCK -->
<aside *ngIf="wakeLockSet" class="app-info-modal" role="alert">
    <div class="d-flex flex-column">
        <div>
            {{ 'web.wake_lock_info' | translate }}
        </div>
        <i class="fa fa-arrow-circle-down fa-2x app-info-modal__icon"></i>
    </div>
</aside>
<!-- END WAKE LOCK -->
