import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { PageService } from 'src/app/services/page.service';

@Injectable({
    providedIn: 'root',
})
export class TransitionGuard implements CanActivate {
    constructor(private user: UserService, private router: Router, private page: PageService) {}

    canActivate(

    ): Observable<boolean> | Promise<boolean> | boolean {
        this.page.pageReset();
        return true;
    }
}
