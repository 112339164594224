import { ChangeDetectionStrategy, Component, Inject, OnChanges, OnInit } from '@angular/core';

import { RankingService } from '@services/ranking.service';

import { SportConfigToken } from '@config/sport.config';

import { map, Observable } from 'rxjs';
import { RankingObject } from '@interfaces/ranking.interface';

import { BaseComponent } from '../../base.component';


@Component({
    selector: 'app-ranking',
    templateUrl: './ranking.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingComponent extends BaseComponent
    implements OnInit, OnChanges {
    public slice = 5;

    public $data: Observable<RankingObject>;

    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public ranking: RankingService) {
        super();
    }

    public ngOnInit(): void {
        this.$data = this.ranking.getRanking(this.sportName).pipe(map(this.map.bind(this)));
    }

    private map(data: RankingObject): RankingObject {
        if (this.sportConfig.isBadminton(this.sportName)) {
            const doubles = data.findIndex(v => v[0] === 'bwf_mixed_doubles_world_ranking');
            if (doubles) {
                data.splice(4, 0, data[doubles]!);
                data.splice(doubles + 1, 1);
            }

            const raceMenSingle = data.findIndex(v => v[0] === 'hsbc_men_singles_race_to_guangzhou_ranking');
            if (raceMenSingle) {
                data.splice(5, 0, data[raceMenSingle]!);
                data.splice(raceMenSingle + 1, 1);
            }
            const raceMenDouble = data.findIndex(v => v[0] === 'hsbc_men_doubles_race_to_guangzhou_ranking');
            if (raceMenSingle) {
                data.splice(7, 0, data[raceMenDouble]!);
                data.splice(raceMenDouble + 1, 1);
            }
        }

        return data;
    }

    public ngOnChanges(): void {
        this.$data = this.ranking.getRanking(this.sportName).pipe(map(this.map.bind(this)));
    }

    get isRankingEnable(): boolean {
        return this.sportConfig.rankings.table.includes(this.sportName);
    }

    get pointName(): string {
        if (this.sportConfig.isSnooker(this.sportName) || this.sportConfig.isDarts(this.sportName)) {
            return 'web.prize_money';
        }
        return 'web.player_points';
    }

    get isPrizeMoney(): boolean {
        return this.sportConfig.isSnooker(this.sportName) || this.sportConfig.isDarts(this.sportName);
    }

    get isPoints(): boolean {
        return !this.isPrizeMoney;
    }
}
