import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-season-team-stats',
    templateUrl: './season-team-stats.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetSeasonTeamstatsComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        participantId: string | number | null | undefined;

    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    #headlineTrans = 'web.opta_season_team_stats';
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        setTimeout(() => {
            const element = document.querySelector('#opta-season-team-stats') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_season_team_stats) {
            Opta.widgets.ref_widget_season_team_stats.destroy(true);
        }
    }

    getHtml(): string {
        return `
          <opta-widget widget="season_team_stats" competition="${this.competitionId}" 
          season="${this.seasonId}" team="${this.participantId}" template="normal" 
          navigation="tabs_more" widget_id="ref_widget_season_team_stats" 
           default_nav="1" show_general="true" show_distribution="true" show_attack="true"  load="false"
           show_defence="true" show_discipline="true" team_naming="full" show_logo="false" 
            title="${this.transHeadline}"show_title="true" breakpoints="400" sport="football"></opta-widget>
          `;
    }
}
