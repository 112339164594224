import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-goals-scored-received',
    templateUrl: './goals-scored-received.component.html',
})
export class GoalsScoredReceivedComponent implements OnInit {
    @Input()
    public data: Record<string, any>;

    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-useless-constructor
    public constructor() {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public ngOnInit(): void {
    }
}
