import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, HostListener } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppModalComponent {
    @Input()
    visible: boolean = false;

    @Output()
    visibleChange: EventEmitter<boolean> = new EventEmitter();

    @HostListener('window:keyup', ['$event'])
    private onKeyUp(event: KeyboardEvent): void {
        if (event.key === 'Esc' || event.key === 'Escape') {
            this.visibleChange.emit(false);
        }
    }

    public constructor() {}

    public close(): void {
        this.visibleChange.emit(false);
    }
}
