import { Component, OnInit, ViewChild } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { InfoService } from '@services/info.service';

import { VenueService } from '@services/venue.service';

import { VenueDetailInterface } from '@interfaces/venue.interface';

import { ClientService } from '@services/client.service';

import { SwiperOptions } from 'swiper';

import { SwiperComponent } from 'swiper/angular';

import $ from 'jquery';

import { PageService } from '../../services/page.service';
import { BaseComponent } from '../base.component';


@Component({
    selector: 'app-venue',
    templateUrl: './venue.component.html',

})
export class VenueComponent extends BaseComponent implements OnInit {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

    public data: VenueDetailInterface;

    public visible = false;

    public initialSlide = 0;


    public map = false;
    public mapCoordinates: string | null = null;

    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 50,
        navigation: true,
        preventClicks: true,
        preventClicksPropagation: true,
        pagination: { clickable: true },
        scrollbar: { draggable: true },
    };

    public index = 0;

    public constructor(
        protected pageService: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected info: InfoService,
        private venue: VenueService,
        private router: ActivatedRoute,

    ) {
        super(pageService, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.router.paramMap.subscribe((params: ParamMap) => {
            this.load(+params.get('id')!);
        });
    }


    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_venue').subscribe((): void => {
            this.setTitle(
                {
                    title: `${this.translate.instant('web.title_venue')} | ${this.titleVal}`,
                    description: this.translate.instant('web.description_venue'),
                },
                true,
            );
        });
    }

    get titleVal(): string {
        let ret = '';

        ret += `${this.data.cityName} `;

        if (this.data.complexName) {
            ret += `${this.data.complexName} | `;
        }


        return ret;
    }

    public toggleMap(mapCoordinates: string | null): void {
        this.map = !this.map;
        this.mapCoordinates = mapCoordinates;
    }

    public openGallery(index: number = 0): void {
        this.initialSlide = index;
        this.visible = true;
    }

    public slideInit(): void {
        $('.swiper-button-next, .swiper-button-prev').click((e) => {
            e.stopPropagation();
        });
    }


    public load(venueId: number): void {
        this.reset();
        this.venue.getByVenue(venueId).subscribe({ next: (data:VenueDetailInterface) => {
            this.data = data;
            this.createTitle();
        },
        complete: () => {
            this.finished();
        },
        error: () => {
            this.error();
        } });
    }
}
