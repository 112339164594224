import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-season-player-stats',
    templateUrl: './season-player-stats.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetSeasonPlayerStatsComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    participantId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_season_team_stats';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-season-player-stats') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_season_player_stats) {
              Opta.widgets.ref_widget_season_player_stats.destroy(true);
          }
      }

      getHtml(): string {
          return `

          <opta-widget widget="season_player_stats" widget_id="ref_widget_season_player_stats" 
          competition="${this.competitionId}" season="${this.seasonId}" team="${this.participantId}" 
          preselected_player="all" template="normal" navigation="tabs_more" default_nav="1"  load="false"
          show_split_stats="false" round_navigation="tabs" show_general="true" show_distribution="true"
           show_attack="true" show_defence="true" show_discipline="true" show_goalkeeping="true"
            team_naming="full" player_naming="full" show_logo="false"  title="${this.transHeadline}"
            show_title="true" breakpoints="400" sport="football"></opta-widget>

          `;
      }
}
