<ng-container *ngIf="banner">
    <div class="app-banner-mobile-body">
        <a
            *ngIf="banner?.url; else elseBlock"
            target="_blank"
            href="{{ banner?.url }}?{{ utm() }}"
            (click)="click()"
            [id]="bannerPositionId + ' | ' + banner?.url"
            class="app-banner-main text-center"
        >
            <img
                src="{{ banner?.path }}"
                alt="{{ banner?.altText }}"
                class="app-banner-mobile-body__image text-center"
                srcset="{{ banner?.small_image }} 600w,
                    {{ banner?.medium_image }} 1024w,
                    {{ banner?.large_image }} 1600w"
            />
        </a>
        <ng-template #elseBlock>
            <img
                *ngIf="!banner?.url"
                src="{{ banner?.path }}"
                alt="{{ banner?.altText }}"
                srcset="{{ banner?.small_image }} 600w,
                        {{ banner?.medium_image }} 1024w,
                        {{ banner?.large_image }} 1600w"
                class="app-banner-mobile-body__image"
            />
        </ng-template>
    </div>
</ng-container>
