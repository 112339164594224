import { Injectable } from '@angular/core';
import {
    CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { PageService } from 'src/app/services/page.service';

@Injectable({
    providedIn: 'root',
})
export class EnterGuard implements CanActivate {
    constructor(private user: UserService, private router: Router, private page: PageService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.user.getStatus().then(
            () => {
                if (this.user.isLoggedIn) {
                    return true;
                }
                return false;
            },
            () => {
                this.user.signOff().then(() => {}, () => {});
                const { url } = state;
                this.user.redirectUrl = url;

                this.page.pageLoaded();
                this.router.navigate(['/hp'], {
                    queryParams: { signin: 1, redirectUrl: url },
                });

                return false;
            },
        );
    }
}
