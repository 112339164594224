/**
 *  Animation banner bottom fixed
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { style, animate, transition, query, group } from '@angular/animations';

export const BANNER_FIXED_ANIMATION = [
    transition(':enter', [
        style({
            transform: 'translateY(100%)',
            opacity: 0,
        }),
        query('.bottom-fixed-banner-child', style({ transform: 'translateX(220%)' })),
        animate('1s 5s', style({ transform: 'translateY(0%)', opacity: 1 })),
        query('.bottom-fixed-banner-child', animate('1.2s 0.1s', style({ transform: 'translateX(0%)' }))),
    ]),
    transition(':leave', [
        group([
            query('.bottom-fixed-banner-child', animate('1s', style({ transform: 'translateX(220%)' }))),
            animate('0.8s 0.9s', style({ transform: 'translateY(100%)', opacity: 0 })),
        ]),
    ]),
];
