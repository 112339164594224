<ul class="nav nav-tabs nav-tabs--transparent nav-tabs--radios text-uppercase mx-n3" id="score-tab" role="tablist">
    <li class="nav-item" role="presentation">
        <a class="nav-link active" id="score-all-tab" data-toggle="tab" href="#score-all" role="tab" aria-controls="score-all" aria-selected="true">
            <div class="custom-control custom-radio custom-control-inline mr-0">
                <input type="radio" id="radio-score-all" name="radio-score" class="custom-control-input">
                <label class="custom-control-label" for="radio-score-all">{{ 'web.overall' | translate }}</label>
            </div>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="score-home-tab" data-toggle="tab" href="#score-home" role="tab" aria-controls="score-home" aria-selected="false">
            <div class="custom-control custom-radio custom-control-inline mr-0">
                <input type="radio" id="radio-score-home" name="radio-score" class="custom-control-input">
                <label class="custom-control-label" for="radio-score-home">{{ 'web.home' | translate }}</label>
            </div>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="score-away-tab" data-toggle="tab" href="#score-away" role="tab" aria-controls="score-away" aria-selected="false">
            <div class="custom-control custom-radio custom-control-inline mr-0">
                <input type="radio" id="radio-score-away" name="radio-score" class="custom-control-input">
                <label class="custom-control-label" for="radio-score-away">{{ 'web.away' | translate }}</label>
            </div>
        </a>
    </li>
</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active px-1 badges-score" id="score-all" role="tabpanel" aria-labelledby="score-all-tab">
        <div class="row small mb-1">
            <div class="col-4 offset-4 text-center pr-0">{{ 'web.1st_half' | translate }}</div>
            <div class="col-4 text-center pl-0">{{ 'web.2nd_half' | translate }}</div>
        </div>
        <div class="row mb-2">
            <div class="col-3 font-weight-bold">{{ 'web.scored' | translate }}</div>
            <div class="col-1 text-right"><span class="badge badge-light text-success badge--lg">{{ data.overall_gs_sum_goals }}</span></div>
            <div class="col-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-success badge-green-1" [style.background-color]="data.overall_gs_15_percentage | alphaColor:'success'">{{ data.overall_gs_15_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.overall_gs_30_percentage | alphaColor:'success'">{{ data.overall_gs_30_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.overall_gs_45_percentage | alphaColor:'success'">{{ data.overall_gs_45_goals }}</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-success badge-green-1" [style.background-color]="data.overall_gs_60_percentage | alphaColor:'success'">{{ data.overall_gs_60_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.overall_gs_75_percentage | alphaColor:'success'">{{ data.overall_gs_75_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.overall_gs_90_percentage | alphaColor:'success'">{{ data.overall_gs_90_goals }}</span>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3 font-weight-bold">{{ 'web.conceded' | translate }}</div>
            <div class="col-1 text-right"><span class="badge badge-light text-danger badge--lg">{{ data.overall_gc_sum_goals }}</span></div>
            <div class="col-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.overall_gc_15_percentage | alphaColor:'danger'">{{ data.overall_gc_15_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.overall_gc_30_percentage | alphaColor:'danger'">{{ data.overall_gc_30_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.overall_gc_45_percentage | alphaColor:'danger'">{{ data.overall_gc_45_goals }}</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.overall_gc_60_percentage | alphaColor:'danger'">{{ data.overall_gc_60_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.overall_gc_75_percentage | alphaColor:'danger'">{{ data.overall_gc_75_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.overall_gc_90_percentage | alphaColor:'danger'">{{ data.overall_gc_90_goals }}</span>
            </div>
        </div>
        <div class="row small text-center text-muted">
            <div class="col-4 offset-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-light text-muted">00′</span>
                <span class="badge badge-light text-muted">15′</span>
                <span class="badge badge-light text-muted">30′</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-light text-muted">60′</span>
                <span class="badge badge-light text-muted">75′</span>
                <span class="badge badge-light text-muted">90′</span>
            </div>
        </div>
    </div>
    <div class="tab-pane fade px-1 badges-score" id="score-home" role="tabpanel" aria-labelledby="score-home-tab">
        <div class="row small mb-1">
            <div class="col-4 offset-4 text-center pr-0">{{ 'web.1st_half' | translate }}</div>
            <div class="col-4 text-center pl-0">{{ 'web.2nd_half' | translate }}</div>
        </div>
        <div class="row mb-2">
            <div class="col-3 font-weight-bold">{{ 'web.scored' | translate }}</div>
            <div class="col-1 text-right"><span class="badge badge-light text-success badge--lg">{{ data.home_gs_sum_goals }}</span></div>
            <div class="col-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-success badge-green-1" [style.background-color]="data.home_gs_15_percentage | alphaColor:'success'">{{ data.home_gs_15_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.home_gs_30_percentage | alphaColor:'success'">{{ data.home_gs_30_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.home_gs_45_percentage | alphaColor:'success'">{{ data.home_gs_45_goals }}</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-success badge-green-1" [style.background-color]="data.home_gs_60_percentage | alphaColor:'success'">{{ data.home_gs_60_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.home_gs_75_percentage | alphaColor:'success'">{{ data.home_gs_75_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.home_gs_90_percentage | alphaColor:'success'">{{ data.home_gs_90_goals }}</span>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3 font-weight-bold">{{ 'web.conceded' | translate }}</div>
            <div class="col-1 text-right"><span class="badge badge-light text-danger badge--lg">{{ data.home_gc_sum_goals }}</span></div>
            <div class="col-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.home_gc_15_percentage | alphaColor:'danger'">{{ data.home_gc_15_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.home_gc_30_percentage | alphaColor:'danger'">{{ data.home_gc_30_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.home_gc_45_percentage | alphaColor:'danger'">{{ data.home_gc_45_goals }}</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.home_gc_60_percentage | alphaColor:'danger'">{{ data.home_gc_60_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.home_gc_75_percentage | alphaColor:'danger'">{{ data.home_gc_75_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.home_gc_90_percentage | alphaColor:'danger'">{{ data.home_gc_90_goals }}</span>
            </div>
        </div>
        <div class="row small text-center text-muted">
            <div class="col-4 offset-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-light text-muted">00′</span>
                <span class="badge badge-light text-muted">15′</span>
                <span class="badge badge-light text-muted">30′</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-light text-muted">60′</span>
                <span class="badge badge-light text-muted">75′</span>
                <span class="badge badge-light text-muted">90′</span>
            </div>
        </div>
    </div>
    <div class="tab-pane fade px-1 badges-score" id="score-away" role="tabpanel" aria-labelledby="score-away-tab">
        <div class="row small mb-1">
            <div class="col-4 offset-4 text-center pr-0">{{ 'web.1st_half' | translate }}</div>
            <div class="col-4 text-center pl-0">{{ 'web.2nd_half' | translate }}</div>
        </div>
        <div class="row mb-2">
            <div class="col-3 font-weight-bold">{{ 'web.scored' | translate }}</div>
            <div class="col-1 text-right"><span class="badge badge-light text-success badge--lg">{{ data.away_gs_sum_goals }}</span></div>
            <div class="col-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-success badge-green-1" [style.background-color]="data.away_gs_15_percentage | alphaColor:'success'">{{ data.away_gs_15_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.away_gs_30_percentage | alphaColor:'success'">{{ data.away_gs_30_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.away_gs_45_percentage | alphaColor:'success'">{{ data.away_gs_45_goals }}</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-success badge-green-1" [style.background-color]="data.away_gs_60_percentage | alphaColor:'success'">{{ data.away_gs_60_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.away_gs_75_percentage | alphaColor:'success'">{{ data.away_gs_75_goals }}</span>
                <span class="badge badge-success badge-green-1" [style.background-color]="data.away_gs_90_percentage | alphaColor:'success'">{{ data.away_gs_90_goals }}</span>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3 font-weight-bold">{{ 'web.conceded' | translate }}</div>
            <div class="col-1 text-right"><span class="badge badge-light text-danger badge--lg">{{ data.away_gc_sum_goals }}</span></div>
            <div class="col-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.away_gc_15_percentage | alphaColor:'danger'">{{ data.away_gc_15_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.away_gc_30_percentage | alphaColor:'danger'">{{ data.away_gc_30_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.away_gc_45_percentage | alphaColor:'danger'">{{ data.away_gc_45_goals }}</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.away_gc_60_percentage | alphaColor:'danger'">{{ data.away_gc_60_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.away_gc_75_percentage | alphaColor:'danger'">{{ data.away_gc_75_goals }}</span>
                <span class="badge badge-danger badge-red-1" [style.background-color]="data.away_gc_90_percentage | alphaColor:'danger'">{{ data.away_gc_90_goals }}</span>
            </div>
        </div>
        <div class="row small text-center text-muted">
            <div class="col-4 offset-4 text-center pr-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-light text-muted">00′</span>
                <span class="badge badge-light text-muted">15′</span>
                <span class="badge badge-light text-muted">30′</span>
            </div>
            <div class="col-4 text-center pl-0 d-flex justify-content-center align-items-center">
                <span class="badge badge-light text-muted">60′</span>
                <span class="badge badge-light text-muted">75′</span>
                <span class="badge badge-light text-muted">90′</span>
            </div>
        </div>
    </div>
</div>
<div class="text-muted mt-3">
    {{ 'web.matches_count' | translate }}: {{ data.overall_matches }}
</div>
