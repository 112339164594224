/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Service for Sports
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import URL_CONFIG from '@config/url.config';

import { CommonService } from '../shared/common.service';
import { SportInterface } from '../interfaces/sport.interface';

import { LangService } from './lang.service';

@Injectable({
    providedIn: 'root',
})
export class StatsService {
    private _data: { [prop: string]: SportInterface[] };

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
    ) {}

    /**
     * Get sport matches for next days
     * @param  {number} days
     * @return  {Observable<any>}
     */
    public actualSports(days: any = 3): Observable<any> {
        const url = URL_CONFIG.api.stats.sportMatches;
        const params = new HttpParams().set('days', days as string);
        const options = params !== null ? { params } : {};
        return this.http.get<any>(url, options).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    public get data(): SportInterface[] {
        return this._data.today;
    }
}
