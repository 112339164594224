/**
 *  Score formatter
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */
import { PlayerTransfers } from '@interfaces/player-transfers.interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchTransfer',
})
export class SearchTransferPipe implements PipeTransform {
    public transform(value?: PlayerTransfers[], fulltext: string = ''): any {
        if (!value) {
            return value;
        }

        fulltext = fulltext.trim().toLowerCase();
        return value.filter(val => val.player_name?.toLowerCase().includes(fulltext) ||
        val.to_participant_name?.toLowerCase().includes(fulltext) ||
            val.from_participant_name?.toLowerCase().includes(fulltext));
    }
}
