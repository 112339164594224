<app-banner-container>
    <app-banner mobile-layer #banners></app-banner>
</app-banner-container>
<div class="container bg-light position-relative app-match-detail-page">
    <app-match-detail
        [id]="matchId"
        (onLoad)="loadedData($event)"
        [fullScreen]="true"
        [tabVisible]="tabVisible"
        class="app-match-detail-page-default"
    ></app-match-detail>
</div>
