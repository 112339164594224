import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-placeholder-match',
    templateUrl: './placeholder-match.component.html',
    styleUrls: ['./placeholder-match.component.scss'],


})
export class PlaceholderMatchComponent implements OnInit {
    @Input()
    num: number = 1;

    @Input()
    withHeader: boolean = false;

    public nums: any[];

    public constructor() {}

    public ngOnInit(): void {
        this.nums = Array(this.num);
    }
}
