import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

@Component({
    selector: 'app-datatable-paging',
    templateUrl: './paging.component.html',
})
export class PagingComponent implements OnChanges {
    @Input()
    public limit: number;

    @Input()
    public count: number | null;

    @Input()
    public page: number;

    @Output()
    public changepage = new EventEmitter<number>();

    public pageRange: number[] = [];

    public constructor(

    ) {}


    public ngOnChanges(): void {
        let startPage = this.currentPage - 2;
        this.pageRange = [];
        while (this.pageRange.length < 5) {
            if (startPage < 1) {
                startPage += 1;
                // eslint-disable-next-line no-continue
                continue;
            }

            this.pageRange.push(startPage);
            if (startPage === this.lastPage) { break; }
            startPage += 1;
        }
    }

    public goTo(page: number): void {
        if (page === this.currentPage) {
            return;
        }

        this.changepage.emit(page - 1);
    }

    public trackByIndex(index: number): number {
        return index;
    }

    get lastPage(): number {
        if (!this.count) {
            return 1;
        }
        return Math.ceil(this.count / this.limit) ?? 1;
    }

    get currentPage(): number {
        return this.page + 1;
    }
}
