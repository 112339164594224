/**
 *  Venues form
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */

import { Injectable } from '@angular/core';
import {
    FormControl,
    FormGroup,
    AbstractControl,
} from '@angular/forms';

import { BaseForm } from './base.form';

@Injectable({
    providedIn: 'root',
})
export class VenuesForm extends BaseForm {
    public constructor() {
        super();
        this.init();
    }

    /**
     * Initialize form Controls
     * @return {void}
     */
    protected init(): void {
        super.init();
        this.formGroup = new FormGroup(
            {
                country: new FormControl(0),
                fulltext: new FormControl(''),
                pageNum: new FormControl(50),

            },

        );
    }

    public get country(): AbstractControl | null {
        return this.formGroup.get('country');
    }

    public get fulltext(): AbstractControl | null {
        return this.formGroup.get('fulltext');
    }

    public get pageNum(): AbstractControl | null {
        return this.formGroup.get('pageNum');
    }
}
