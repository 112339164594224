import { NavigationService } from '@services/navigation.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LangService } from '@services/lang.service';
import { Observable } from 'rxjs';
import { NaviLevel2Interface } from '@interfaces/navigation.interface';
import { chunk, filter } from 'lodash-es';
import { map } from 'rxjs/operators';
import { BaseComponent } from '@components/base.component';

@Component({
    selector: 'app-tournament-link',
    templateUrl: './tournament-links.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentLinksComponent extends BaseComponent implements OnInit {
    @Input()
    public sportId: number;

    @Input()
    public categoryId: number;

    @Input()
    public tournamentId: number;

    @Input()
    public flag: string;

    @Input()
    public name: string;

    public $nav: Observable<[NaviLevel2Interface, NaviLevel2Interface?][]>;


    constructor(private nav: NavigationService, public lang: LangService) { super(); }

    public ngOnInit(): void {
        this.$nav = this.nav.nav2Call(this.sportId, this.categoryId, 'all', null,
            false, this.lang.getLangSnapshot() as string).pipe(
            map((val: NaviLevel2Interface[]) => {
                const filtered = filter(val, val2 => +val2.id !== this.tournamentId);
                return chunk(filtered, 2) as [NaviLevel2Interface, NaviLevel2Interface?][];
            },
            ));
    }
}
