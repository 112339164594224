<div class="micro-modal">
    <span (click)="close()" class="micro-modal__close app-cursor">×</span>
    <div class="d-flex justify-content-center">
        <div class="team-name mt-4" data-cy="team-name">
            <div class="img-team img-team--md" style="height: 2rem">
                <a
                    [routerLink]="[
                        '/page/sport/participant',
                        data?.match?.sport_code_name + '-' + data?.match?.sport_id,
                        data.match.participants[0]?.id
                    ]"
                    class="text-reset app-match-detail-logo-anchor"
                    ><img
                        (error)="imageError($event)"
                        alt="Kastrioti"
                        class="img-fluid app-cursor app-match-detail-participant-logo micro-modal__img"
                        decoding="async"
                        height="48"
                        loading="lazy"
                        src="{{ data.match.participants[0]?.image | responsive: 'participant':'small' }}"
                        width="48"
                /></a>
            </div>
            <div class="text-center">
                <a
                    [routerLink]="[
                        '/page/sport/participant',
                        data?.match?.sport_code_name + '-' + data?.match?.sport_id,
                        data.match.participants[0]?.id
                    ]"
                    class="text-reset d-block"
                    ><span class="sticky-match-detail-no-show">{{ data.match.participants[0]?.name }}</span></a
                >
            </div>
        </div>
        <div class="d-flex flex-column px-4 app-match-detail">
            <div class="text-muted small app-sticky-detail-date">
                {{ data.match?.start_date | livDate : 'localDateTimeShort' }}
            </div>

            <div class="h2 font-weight-bolder my-0">
                <div>
                    <div class="d-flex text-center justify-content-center">
                        <div class="text-center micro-modal__score">
                            <span
                                [class.app-state-color-inplay]="data.match?.scoreHomeChange"
                                [class.blink-6x]="data.match?.scoreHomeChange"
                            >
                                <span>{{ data.match.score.home_team }}</span>
                            </span>
                            <span>:</span>
                            <span
                                [class.app-state-color-inplay]="data.match?.scoreAwayChange"
                                [class.blink-6x]="data.match?.scoreAwayChange"
                            >
                                <span>{{ data.match.score.away_team }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                [class.app-state-color-inplay]="data.match?.statusChange"
                [class.blink-6x]="data.match?.statusChange"
                class="text-muted micro-modal__state text-center"
            >
                {{ 'status.' + data.match?.sport_code_name + '.' + data.match?.code_state | translate }}
            </div>
        </div>
        <div class="team-name mt-4" data-cy="team-name">
            <div class="img-team img-team--md" style="height: 2rem">
                <a
                    [routerLink]="[
                        '/page/sport/participant',
                        data?.match?.sport_code_name + '-' + data?.match?.sport_id,
                        data.match.participants[1]?.id
                    ]"
                    class="text-reset app-match-detail-logo-anchor"
                    ><img
                        alt="Egnatia"
                        class="img-fluid app-cursor app-match-detail-participant-logo micro-modal__img"
                        decoding="async"
                        height="48"
                        loading="lazy"
                        src="{{ data.match.participants[1]?.image | responsive: 'participant':'small' }}"
                        width="48"
                /></a>
            </div>
            <div class="text-center">
                <a
                    [routerLink]="[
                        '/page/sport/participant',
                        data?.match?.sport_code_name + '-' + data?.match?.sport_id,
                        data.match.participants[1]?.id
                    ]"
                    class="text-reset d-block"
                    ><span class="sticky-match-detail-no-show">{{ data.match.participants[1]?.name }}</span></a
                >
            </div>
        </div>
    </div>
</div>
