/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { SeasonStats } from '@interfaces/participant.interface';
import { Component, Input } from '@angular/core';
import { fromPairs, cloneDeep, omitBy, omit } from 'lodash-es';
import { BaseComponent } from '@components/base.component';
import { OmitStatsKeys, STATSGROUP, STATSGROUPSDATA } from '@interfaces/stats.interface';


@Component({
    selector: 'app-participant-season-stats',
    templateUrl: './season-stats.component.html',
})
export class ParticipanseasonstatsComponent extends BaseComponent {
    @Input()
    public stats: SeasonStats;

    // This keys will not be included in front end stats
    #omitKeys = ['tournamentName',
        'tournamentYear', 'opta_competition', 'opta_season', 'opta_team'];

    public constructor() {
        super();
    }

    get statsData(): [keyof Omit<SeasonStats, OmitStatsKeys>, string][] {
        const sort = Object.keys(omit(this.stats, this.#omitKeys))
            .sort().map(key => [key, this.stats[key]]);
        return sort as [keyof Omit<SeasonStats, OmitStatsKeys>, string][];
    }

    get statsVal(): Record<string, any> {
        const groups = cloneDeep(STATSGROUPSDATA);
        const allKeys: string[] = [];
        for (const key in groups) {
            const val = Object.keys(groups[key]);
            allKeys.push(...val);
            groups[key] = { ...groups[key], ...fromPairs((this.statsData.filter(v => val.includes(v[0] as any)))) };
            groups[key] = omitBy(groups[key], value => !value);
            if (Object.keys(groups[key]).length === 0) {
                delete groups[key];
            }
        }

        const notFound = this.statsData.filter(v => !allKeys.includes(v[0] as any));

        groups[STATSGROUP.INIT] = { ...groups[STATSGROUP.INIT], ...fromPairs(notFound) };
        return groups;
    }
}
