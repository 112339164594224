<div class="container bg-light position-relative mb-5">
    <div class="row bg-white">
        <div class="col-12 bg-white p-md-5">
            <form class="smallx" [formGroup]="form.getGroup()">
                <div class="row">
                    <div class="col-md">
                        <h6 class="text-uppercase mb-3 mt-1 font-weight-bold">{{ 'web.venues' | translate }}</h6>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label text-right" for="input1">{{
                                'web.fulltext_venue' | translate
                            }}</label>
                            <div class="col-sm-8">
                                <input
                                    formControlName="fulltext"
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="input1"
                                    placeholder="{{ 'web.fulltext_venue' | translate }}"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md">
                        <h6 class="text-uppercase mb-3 font-weight-bold">&nbsp;</h6>
                        <div class="form-group row">
                            <label for="input5" class="col-sm-4 col-form-label text-right">{{
                                'web.country' | translate
                            }}</label>
                            <select
                                class="custom-select border-light2 mb-3 w-50"
                                formControlName="country"
                                data-cy="selectCountry"
                            >
                                <option value="0">{{ 'web.all_countries' | translate }}</option>
                                <option
                                    [value]="country.id"
                                    *ngFor="let country of countryMappedData | async; trackBy: trackByIndex"
                                >
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col col-sm-2">
                        <button
                            type="submit"
                            data-cy="btn-venue"
                            [disabled]="!form.getGroup().valid"
                            (click)="search()"
                            class="btn btn-primary btn-block"
                        >
                            {{ 'web.search' | translate }}
                        </button>
                    </div>
                </div>
                <div class="row justify-content-end mb-1 mt-4">
                    <div class="col-3 text-right">
                        <label for="input6" class="col-form-label text-right">{{
                            'web.num_items_per_page' | translate
                        }}</label
                        >&nbsp;
                        <select
                            class="custom-select custom-select--inline border-light2"
                            id="input6"
                            formControlName="pageNum"
                        >
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                </div>
            </form>

            <div *ngIf="loadedError" class="pb-6">
                <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                    <div class="text-center pt-7">
                        <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-soccer'" /></svg>
                        <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                        <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="loaded && !loadedError; else elseBlock">
                <!-- TABLE START -->

                <div class="table-responsive">
                    <div class="match-info__table small pb-2 text-center" [@VenueItem]>
                        <div class="match-info__table__row player-record bg-light">
                            <div
                                class="match-info__table__cell text-left font-weight-bold app-cursor"
                                (click)="sort('countryName')"
                            >
                                {{ 'web.country' | translate }} <i class="fas fa-sort"></i>
                            </div>
                            <div class="match-info__table__cell text-left font-weight-bold" (click)="sort('cityName')">
                                {{ 'web.city' | translate }} <i class="fas fa-sort"></i>
                            </div>

                            <div class="match-info__table__cell text-left font-weight-bold">
                                {{ 'web.complex_name' | translate }}
                            </div>
                            <div class="match-info__table__cell text-left font-weight-bold app-cursor text-center">
                                {{ 'web.stadium_capacity' | translate }}
                            </div>
                            <div class="match-info__table__cell text-left font-weight-bold text-center">
                                {{ 'web.map' | translate }}
                            </div>
                            <div class="match-info__table__cell text-left font-weight-bold text-center">&nbsp;</div>
                        </div>

                        <div
                            class="match-info__table__row app-data-row-data player-record ng-star-inserted"
                            data-cy="venueItem"
                            *ngFor="let venue of data!.data; trackBy: trackById"
                        >
                            <div class="match-info__table__cell text-left border-top" data-cy="countryName">
                                <img
                                    *ngIf="venue.countryIso"
                                    [alt]="venue.countryIso3"
                                    class="ml-2 rounded-circle"
                                    width="20"
                                    height="20"
                                    loading="lazy"
                                    decoding="async"
                                    src="/img/flags/1x1/{{ venue.countryIso | lowercase }}.svg"
                                />
                                {{ venue.countryName }}
                            </div>
                            <div class="match-info__table__cell text-left border-top ng-star-inserted">
                                {{ venue.cityName }}
                            </div>

                            <div class="match-info__table__cell text-left font-weight-bold border-top">
                                <a class="text-reset" [routerLink]="['./', venue.id]">{{ venue.complexName }} </a>
                            </div>
                            <div class="match-info__table__cell text-left font-weight-bold border-top text-center">
                                <ng-container *ngIf="venue.capacity && venue.capacity > 100; else elseCapacity">
                                    {{ venue.capacity | appnumber }}
                                </ng-container>
                                <ng-template #elseCapacity>-</ng-template>
                            </div>
                            <div class="match-info__table__cell text-left font-weight-bold border-top text-center">
                                <ng-container *ngIf="venue.mapCoordinates; else noMap">
                                    <a class="link ext-link app-cursor" (click)="toggleMap(venue.mapCoordinates)"
                                        >{{ 'web.map' | translate }} &nbsp;</a
                                    >
                                </ng-container>
                                <ng-template #noMap>-</ng-template>
                            </div>
                            <div class="match-info__table__cell text-left font-weight-bold border-top text-right">
                                <ng-container *ngIf="venue.totalImage">
                                    <i
                                        class="far fa-image"
                                        title="{{ 'web.include_images' | translate }}"
                                        aria-label="{{ 'web.include_images' | translate }}"
                                    ></i>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100" *ngIf="!data?.count">
                    <div class="text-center font-weight-bold border-top">
                        {{ 'web.no_data' | translate }}
                    </div>
                </div>
                <google-modal [mapCoordinates]="mapCoordinates" [(visible)]="map"></google-modal>
                <app-datatable-paging
                    [page]="pageNumber"
                    (changepage)="load($event)"
                    [limit]="limit"
                    [count]="data.count ? data.count : null"
                ></app-datatable-paging>
            </ng-container>
            <ng-template #elseBlock>
                <div class="row" *ngIf="!loadedError">
                    <div class="col bg-white pl-0 pr-0">
                        <app-placeholder-table></app-placeholder-table>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <aside class="nav-sports d-none d-xl-block" *ngIf="client.use('sportQuickNav')">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation type="aside" [limit]="asideLimit"></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web. less' | translate }}</span>
            </a>
        </nav>
    </aside>
    <aside class="nav-sports nav-sports--extra d-none" [class.d-xl-block]="client.use('sportQuickNav')">
        <nav class="nav flex-column nav-side-menu bg-light">
            <app-side-menu-navigation></app-side-menu-navigation>
        </nav>
    </aside>
</div>
