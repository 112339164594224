/* eslint-disable no-console */
import { Component, NgZone, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslationService } from 'src/app/services/translation.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ClientService } from '@services/client.service';

import { StorageService } from '@services/storage.service';


import { PageService } from '@services/page.service';

import { PageState } from '../store/states/page.state';
import { UserService } from '../services/user.service';

import { DisplayModeInterface, MediaMode } from '@/interfaces/mode.interface';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styles: [],
})
export class PagesComponent implements OnInit {
    @Select(PageState.numFailedRequests) public numFailedRequests$: Observable<number>;

    @Select(PageState.getMedia) public mediaOb$: Observable<DisplayModeInterface>;

    @Select(PageState.isDebug) public isDebug$: Observable<boolean>;

    public numNetworkErrors: number = 0;

    public kioskMode: boolean = false;

    public isDebug = false;

    // TODO: wake lock info modal disabled enable in future
    public wakeLockSet = false;

    public constructor(private user: UserService, private ngZone: NgZone, public client: ClientService,
        private storage: StorageService, private page: PageService) {}

    public async ngOnInit(): Promise<any> {
        TranslationService.isLoaded = false;
        this.observerNetworkstatus();

        this.mediaOb$.subscribe((media): void => {
            if (media && media.displayMode && media.displayMode === MediaMode.KIOSK) {
                this.kioskMode = true;
            }
        });

        this.isDebug$.subscribe((debug: boolean): void => {
            this.isDebug = debug;
        });

        let user;
        /*eslint-disable */
        try {
            user = await this.user.getStatus();

        } catch (e: unknown) {
        }
        /* eslint-enable */

        return user;
    }

    public goTop(): void {
        this.ngZone.runOutsideAngular((): void => {
            $('html, body').scrollTop(0);
        });
    }

    public get isScroll(): boolean {
        return window.pageYOffset > 200;
    }


    /**
     * Checking network status and make actions
     */
    private observerNetworkstatus(): void {
        // this.networkStatus$.subscribe((status) =>
        //     console.log("Network status", status)
        // );
        this.numFailedRequests$.subscribe((num): void => {
            this.numNetworkErrors = num;
        });
    }
}
