/**
 *  Rename sport nav  pipe
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';
import SPORT_CONFIG from '@config/sport.config';

@Pipe({
    name: 'renamesportnav',
})
export class RenameSportNavPipe implements PipeTransform {
    public transform(value: any, sport: string | null = null): any {
        if (!value || !sport) {
            return value;
        }

        const isFinished = value.includes('finished_matches');
        const isFuture = value.includes('future_matches');

        let exists = false;

        if (isFinished) {
            // @ts-ignore
            exists = SPORT_CONFIG.mainNav?.trans?.finished[sport] ?? false;
        }

        if (isFuture) {
            // @ts-ignore
            exists = SPORT_CONFIG.mainNav?.trans?.future[sport] ?? false;
            if (exists) {
                return exists;
            }
        }

        if (exists) {
            return exists;
        }

        return value;
    }
}
