import { Component } from '@angular/core';
import { AccountMixin } from 'src/app/services/mixins/account.mixin';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InfoService } from 'src/app/services/info.service';
import { Observable } from 'rxjs';

import { Select } from '@ngxs/store';
import { UserState } from 'src/app/store/states/user.state';


import { BaseComponent } from '../../base.component';


@Component({
    selector: 'app-dashboard-favorite',
    templateUrl: './favorite.component.html',
    styleUrls: ['../../../../css/pooled/style.css'],
})
export class DashboardFavoriteComponent extends AccountMixin(BaseComponent) {
    @Select(UserState.lastSign)
    public lastSign$: Observable<any | null>;

    public actualTime: any;


    public annual: boolean = false;

    public constructor(
        protected page: PageService,

        protected title?: Title,
        protected meta?: Meta,
        protected route?: Router,
        protected info?: InfoService,
    ) {
        super(page, title, meta, route, info);
    }
}
