<div class="bg-white p-1 p-md-4">
    <h3 class="text-center font-weight-bold">
        <span [innerHTML]="'web.user_dashboard_headline' | translate | markdown" class="emph-text-dashboard"></span>
    </h3>

    <div>
        <div>
            <div [class.active]="stickyVal4.checked" class="custom-control custom-switch my-2">
                <input
                    #stickyVal4
                    (change)="onAnnualChange(stickyVal4.checked)"
                    [checked]="annual"
                    class="custom-control-input"
                    id="switch8"
                    type="checkbox"
                />
                <label class="custom-control-label text-less-emph2 app-word-nowrap" data-cy="switch8" for="switch8">
                    <i class="fa fa-calendar"></i>
                    &nbsp;{{ 'web.user_dashboard_annual' | translate | transinit }}</label
                >
            </div>
        </div>
    </div>

    <div class="mt-4 row">
        <div class="col-md-6 col-xl-3 mb-4">
            <div class="user-dashboard-item --active d-flex flex-column app-h-100 justify-content-between">
                <div class="justify-content-center text-center">
                    <span class="--title">{{ 'web.tracker' | translate }}</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="--title-small">{{ 'web.tracker_text' | translate }}</span>
                </div>
                <div class="justify-content-center text-center py-6">
                    <span class="--amount">0 $</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="">{{ 'web.tracker_text_2' | translate }}</span>
                </div>
                <div class="justify-content-center text-center" *ngIf="tracker">
                    <span class="">{{ 'web.valid_to' | translate }} {{ tracker | livDate : 'dateShort' }}</span>
                </div>
                <div class="justify-content-center text-center pt-3">
                    <button class="--button">
                        <i class="fas fa-check-circle"></i> {{ 'web.active_subscription' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 mb-4">
            <div class="user-dashboard-item --active d-flex flex-column app-h-100 justify-content-between">
                <div class="justify-content-center text-center">
                    <span class="--title">{{ 'web.premium_stats' | translate }}</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="--title-small">{{ 'web.premium_stats_text' | translate }}</span>
                </div>
                <div class="justify-content-center text-center py-6">
                    <span class="--amount">0 $</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="">{{ 'web.premium_stats_text_2' | translate }}</span>
                </div>
                <div class="justify-content-center text-center" *ngIf="premium">
                    <span class="">{{ 'web.valid_to' | translate }} {{ premium | livDate : 'dateShort' }}</span>
                </div>
                <div class="justify-content-center text-center pt-3">
                    <button class="--button">
                        <i class="fas fa-check-circle"></i> {{ 'web.active_subscription' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 mb-4">
            <div class="user-dashboard-item --active d-flex flex-column app-h-100 justify-content-between">
                <div class="justify-content-center text-center">
                    <span class="--title">{{ 'web.stream_title' | translate }}</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="--title-small">{{ 'web.stream_text' | translate }}</span>
                </div>
                <div class="justify-content-center text-center py-6">
                    <span class="--amount">0 $</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="">{{ 'web.stream_text_2' | translate }}</span>
                </div>
                <div class="justify-content-center text-center" *ngIf="stream">
                    <span class="">{{ 'web.valid_to' | translate }} {{ stream | livDate : 'dateShort' }}</span>
                </div>
                <div class="justify-content-center text-center pt-3">
                    <button class="--button">
                        <i class="fas fa-check-circle"></i> {{ 'web.active_subscription' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 mb-4">
            <div class="user-dashboard-item --active d-flex flex-column app-h-100 justify-content-between">
                <div class="justify-content-center text-center">
                    <span class="--title">{{ 'web.betting_widgets_title' | translate }}</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="--title-small">{{ 'web.betting_widgets_text' | translate }}</span>
                </div>
                <div class="justify-content-center text-center py-6">
                    <span class="--amount">0 $</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="">{{ 'web.betting_widgets_text_2' | translate }}</span>
                </div>
                <div class="justify-content-center text-center" *ngIf="widget">
                    <span class="">{{ 'web.valid_to' | translate }} {{ widget | livDate : 'dateShort' }}</span>
                </div>
                <div class="justify-content-center text-center pt-3">
                    <button class="--button">
                        <i class="fas fa-check-circle"></i> {{ 'web.active_subscription' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 mb-4">
            <div class="user-dashboard-item --active d-flex flex-column app-h-100 justify-content-between">
                <div class="justify-content-center text-center">
                    <span class="--title">{{ 'web.stats_title' | translate }}</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="--title-small">{{ 'web.stats_text' | translate }}</span>
                </div>
                <div class="justify-content-center text-center py-6">
                    <span class="--amount">0 $</span>
                </div>
                <div class="justify-content-center text-center">
                    <span class="">{{ 'web.stats_text_2' | translate }}</span>
                </div>
                <div class="justify-content-center text-center" *ngIf="stats">
                    <span class="">{{ 'web.valid_to' | translate }} {{ stats | livDate : 'dateShort' }}</span>
                </div>
                <div class="justify-content-center text-center pt-3">
                    <button class="--button">
                        <i class="fas fa-check-circle"></i> {{ 'web.active_subscription' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="clearfix"></div>
    </div>

    <div
        class="user-dashboard-text my-6 text-center emph-text-dashboard"
        [innerHTML]="'web.dashboard-promo-text' | translate | markdown"
    ></div>

    <div>
        <strong>{{ 'web.last_sign_in' | translate }}</strong>
        <span *ngIf="lastSign$ | async as time">
            {{ time | livDate : 'dateShort' }}
        </span>
    </div>
</div>
