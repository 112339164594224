/**
 *  Normalize stat value
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { SportConfigToken } from '@config/sport.config';

@Pipe({
    name: 'statsVal',
    pure: true,
})
export class StatsValPipe implements PipeTransform {
    constructor(@Inject(SportConfigToken) private sportConfig: Record<string, any>,

    ) {

    }

    public transform(value: any, key: string): string {
        if (!value) {
            return value;
        }

        if (this.sportConfig.statistics.percentage.includes(key)) {
            return `${value}%`;
        }
        if (this.sportConfig.statistics.measureWeight.includes(key)) {
            return `${value} kg`;
        }
        if (this.sportConfig.statistics.measureHeight.includes(key)) {
            return `${value} cm`;
        }
        if (this.sportConfig.statistics.yesNo.includes(key)) {
            // eslint-disable-next-line eqeqeq
            if (value == 1) {
                return '✔';
            }
            return '✖';
        }

        return value;
    }
}
