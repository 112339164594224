<a
    *ngIf="isComparition"
    class="nav-link venue-link compare-arrows"
    [class.active]="compareModal"
    matTooltip="{{ 'web.participant_comapre_head' | translate }}"
    matTooltipPosition="right"
    matTooltipHideDelay="500"
    data-cy="btn-compare-participant"
    [attr.data-sport-name]="sportName"
    id="btn-compare-participant"
    routerLinkActive="active"
    (click)="toggleCompareModal()"
    href="javascript:void(0)"
>
    <mat-icon class="v-mid" fontIcon="compare_arrows"></mat-icon>
</a>
<a
    class="nav-link venue-link"
    matTooltip="{{ 'web.venues' | translate }}"
    matTooltipPosition="right"
    matTooltipHideDelay="500"
    data-cy="venue-item"
    routerLinkActive="active"
    aria-label="Venues"
    [routerLink]="['/page/sport/venues']"
>
    <i class="fas fa-ring"></i>
</a>

<div class="side-compare-nav" [class.visible]="compareModal">
    <app-participant-comparition
        *ngIf="isComparition"
        [sportId]="sportId"
        [sportName]="sportName"
    ></app-participant-comparition>
</div>
