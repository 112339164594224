import { Component, OnInit } from '@angular/core';
import { AccountMixin } from 'src/app/services/mixins/account.mixin';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InfoService } from 'src/app/services/info.service';
import { interval, Observable } from 'rxjs';

import { Select } from '@ngxs/store';
import { UserState } from 'src/app/store/states/user.state';


import { BaseComponent } from '../../base.component';

declare const moment: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['../../../../css/pooled/style.css'],
})
export class DashboardComponent extends AccountMixin(BaseComponent)
    implements OnInit {
    @Select(UserState.lastSign)
    public lastSign$: Observable<any | null>;

    @Select(UserState.data) public userData$: Observable<Record<string, any>>;

    public actualTime: any;


    public annual: boolean = false;

    public userData: Record<string, any>;

    public constructor(
        protected page: PageService,

        protected title?: Title,
        protected meta?: Meta,
        protected route?: Router,
        protected info?: InfoService,

    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        this.actualTime = moment();
        super.ngOnInit();

        interval(1000).subscribe((): void => {
            this.actualTime.add(1, 's');
        });

        this.userData$.subscribe(((val) => {
            this.userData = val;
        }));
    }

    get tracker(): string | false {
        if (this.userData?.permisions) {
            const data = this.userData.permisions.find((v: Record<string, any>) => v.permission === 'tracker');

            if (!data) {
                return false;
            }

            return data.pivot.valid_to;
        }

        return false;
    }

    get premium(): string | false {
        if (this.userData?.permisions) {
            const data = this.userData.permisions.find((v: Record<string, any>) => v.permission === 'premium_stats');

            if (!data) {
                return false;
            }

            return data.pivot.valid_to;
        }

        return false;
    }

    get stream(): string | false {
        if (this.userData?.permisions) {
            const data = this.userData.permisions.find((v: Record<string, any>) => v.permission === 'stream');

            if (!data) {
                return false;
            }

            return data.pivot.valid_to;
        }

        return false;
    }

    get widget(): string | false {
        if (this.userData?.permisions) {
            const data = this.userData.permisions.find((v: Record<string, any>) => v.permission === 'betting_widgets');

            if (!data) {
                return false;
            }

            return data.pivot.valid_to;
        }

        return false;
    }

    get stats(): string | false {
        if (this.userData?.permisions) {
            const data = this.userData.permisions.find((v: Record<string, any>) => v.permission === 'stats');

            if (!data) {
                return false;
            }

            return data.pivot.valid_to;
        }

        return false;
    }

    public time(): any {
        return moment(this.actualTime);
    }

    public onAnnualChange(mode: boolean): void {
        this.annual = mode;
    }
}
