<div class="bg-white p-1 p-md-4">
    <div class="row mt-6">
        <div class="col-12 col-xl-6 d-none">
            <h1 class="h2 text-uppercase mb-4 mt-4">{{ 'web.dashboard_statistics' | translate }}</h1>
            <app-actual-sport-chart></app-actual-sport-chart>
        </div>
        <div class="col-12 col-xl-12 mt-4 mt-xl-0">
            <div class="app-card">
                <h1 class="h2 text-uppercase mb-4 mt-4">{{ 'web.my_matches' | translate }}</h1>
                <app-user-my-matches></app-user-my-matches>
            </div>
        </div>
    </div>
</div>
