import { PageService } from '@services/page.service';
import { Component, Input } from '@angular/core';

import { MatchData } from '@interfaces/match-data.interface';

import { BaseComponent } from '@components/base.component';
import { MatchChange } from '@interfaces/my-matches.interface';


@Component({
    selector: 'app-micro-modal',
    templateUrl: './micro-modal.component.html',
})
export class MicroModalComponent extends BaseComponent {
    @Input() data: {match: MatchData & MatchChange};

    public constructor(
        private page: PageService) {
        super();
    }

    public close(): void {
        this.page.onSnackBarMyMatchesClose.emit(true);
    }
}
