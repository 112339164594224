<div class="table-responsive">
    <form class="smallx" [formGroup]="form.getGroup()">
        <div class="form-group row justify-content-start mx-0">
            <div class="col-12 col-md-10">
                <input
                    formControlName="fulltext"
                    type="text"
                    class="form-control --placeholder"
                    id="input1"
                    placeholder="{{ 'web.fulltext_transfer' | translate }}"
                />
            </div>

            <div class="d-none d-md-block col-md-2">
                <button
                    type="submit"
                    data-cy="btn-venue"
                    [disabled]="!form.getGroup().valid"
                    (click)="search()"
                    class="btn btn-primary btn-block"
                >
                    {{ 'web.search' | translate }}
                </button>
            </div>
        </div>
    </form>

    <div class="match-info__table small pb-2 text-center">
        <div class="match-info__table__row player-record bg-dark text-white">
            <div class="match-info__table__cell text-left font-weight-bold">
                {{ 'web.date' | translate | transinit }}
            </div>
            <div *ngIf="showName" class="match-info__table__cell text-left font-weight-bold">
                {{ 'web.player_name' | translate | transinit }}
            </div>
            <div class="match-info__table__cell text-left font-weight-bold">
                {{ 'web.role_type' | translate | transinit }}
            </div>
            <div class="match-info__table__cell text-left font-weight-bold">
                {{ 'web.transfer_from' | translate | transinit }}
            </div>
            <div class="match-info__table__cell text-left font-weight-bold">
                {{ 'web.transfer_to' | translate | transinit }}
            </div>
        </div>

        <div
            *ngFor="
                let playerTransfer of playerTransfers | searchTransfer : searchVal | slice : 0 : 400;
                trackBy: trackByIndex
            "
            class="match-info__table__row app-data-row-data player-record"
        >
            <div class="match-info__table__cell text-left font-weight-bold border-top">
                {{ playerTransfer.start | livDate : 'localDate' }}
            </div>
            <div *ngIf="showName" class="match-info__table__cell text-left font-weight-bold border-top">
                <a
                    [routerLink]="['/page/sport/player', sportName + '-' + sportId, playerTransfer.player_id]"
                    class="text-reset"
                >
                    <img
                        *ngIf="playerTransfer.iso"
                        src="/img/flags/1x1/{{ playerTransfer.iso | lowercase }}.svg"
                        alt=""
                        height="16"
                    />
                    {{ playerTransfer.player_name }}
                </a>
            </div>
            <div class="match-info__table__cell text-left font-weight-bold border-top">
                {{ playerTransfer.role_type | translate | transinit }}
            </div>
            <div class="match-info__table__cell text-left font-weight-bold border-top">
                <img
                    *ngIf="playerTransfer.from_participant_logo !== null"
                    src="{{ playerTransfer.from_participant_logo }}"
                    height="16"
                />
                <a
                    [routerLink]="[
                        '/page/sport/participant/',
                        sportName + '-' + sportId,
                        playerTransfer.from_participant_id
                    ]"
                    class="text-reset"
                >
                    {{ playerTransfer.from_participant_name }}
                </a>
            </div>
            <div class="match-info__table__cell text-left font-weight-bold border-top">
                <img
                    *ngIf="playerTransfer.to_participant_logo !== null"
                    src="{{ playerTransfer.to_participant_logo }}"
                    height="16"
                />
                <a
                    [routerLink]="[
                        '/page/sport/participant/',
                        sportName + '-' + sportId,
                        playerTransfer.to_participant_id ?? playerTransfer.participant_id
                    ]"
                    class="text-reset"
                >
                    {{ playerTransfer.to_participant_name }}
                </a>
            </div>
        </div>
    </div>
</div>
