/**
 *  Statistics interface
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */

export enum STATSGROUP {
    INIT = 'init',
    ATTACK = 'attack',
    DEFENSE = 'defense',
    CARDS = 'cards',
    GENERAL = 'general',
    BATTTING = 'batting',
    BOWLING = 'bowling',
    FIELDING = 'fielding'
}

export type OmitStatsKeys = 'tournamentName' | 'tournamentYear' | 'opta_competition';


export const STATSGROUPSDATA: Record<string, any> = {
    [STATSGROUP.INIT]: {
        matches_played: null,
        games_played: null,
        matches_won: null,
        matches_lost: null,
        minutes_played: null,
        overtime_losses: null,
        average_ball_possession: null,
        points: null,
        goals: null,
        goals_allowed: null,
        goals_scored: null,
        goals_scored_first_half: null,
        goals_scored_second_half: null,
        goals_conceded: null,
        goals_conceded_first_half: null,
        goals_conceded_second_half: null,
        goals_by_foot: null,
        goals_by_head: null,
        goals_by_penalty: null,
        own_goals: null,
        field_goals: null,
        assists: null,
        first_assists: null,
        second_assists: null,
        seven_m_goals: null,

        clean_sheets: null,
    },

    [STATSGROUP.BATTTING]: {
        batting_highest_score_rank: null,
        batting_highest_score_total: null,
        batting_top_average_average: null,
        batting_top_average_rank: null,
        batting_top_fours_rank: null,
        batting_top_fours_total: null,
        batting_top_runs_rank: null,
        batting_top_runs_total: null,
        batting_top_sixes_rank: null,
        batting_top_sixes_total: null,
        batting_top_strike_rate_rank: null,
        batting_top_strike_rate_rate: null,
        batting_top_fifties_total: null,
        batting_top_fifties_rank: null,


    },
    [STATSGROUP.BOWLING]: {
        bowling_top_bowling_average_average: null,
        bowling_top_bowling_average_rank: null,
        bowling_top_dot_balls_rank: null,
        bowling_top_dot_balls_total: null,
        bowling_top_economy_rank: null,
        bowling_top_economy_rate: null,
        bowling_top_maidens_rank: null,
        bowling_top_maidens_total: null,
        bowling_top_wickets_rank: null,
        bowling_top_wickets_total: null,


    },
    [STATSGROUP.FIELDING]: {
        fielding_top_catches_rank: null,
        fielding_top_catches_total: null,

    },
    [STATSGROUP.ATTACK]: {
        shots_total: null,
        shots_on_target: null,
        shots_off_target: null,
        shots_on_post: null,
        shots_on_bar: null,
        shots_faced: null,
        shots: null,
        shooting_percentage: null,
        chances_created: null,
    },
    [STATSGROUP.DEFENSE]: {
        shots_blocked: null,
        saves: null,
        save_percentage: null,
        blocks: null,
        clearances: null,
        tackles_total: null,
        tackles_successful: null,
    },
    [STATSGROUP.CARDS]: {
        cards_given: null,
        red_cards: null,
        yellow_red_cards: null,
        yellow_cards: null,
    },
    [STATSGROUP.GENERAL]: {
        plus_minus: null,
        free_kicks: null,
        offsides: null,
        corner_kicks: null,
        penalties: null,
        number_of_penalties: null,
        penalty_minutes: null,
        penalties_missed: null,
        technical_fouls: null,
        shutouts: null,
        steals: null,
        interceptions: null,
        suspensions: null,
        passes_total: null,
        passes_successful: null,
        passes_unsuccessful: null,
        long_passes_total: null,
        long_passes_successful: null,
        long_passes_unsuccessful: null,
        crosses_successful: null,
        crosses_total: null,


        substituted_in: null,
        substituted_out: null,
        dribbles_completed: null,
        loss_of_possession: null,

    },
};
