import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'alphaColor',
})
export class AlphaColorPipe implements PipeTransform {
    public transform(value: any, args?: any): any {
        // args = success vs danger

        const alpha = (value / 60) + 0.2;

        if (args === 'danger') {
            return `rgba(249, 90, 125, ${alpha})`;
        }

        return `rgba(60, 180, 18, ${alpha})`;
    }
}
