import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-expected-goals-season',
    templateUrl: './expected-goals-season.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetExpectedGoalsSeasonComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    @Input()
        participantId: string | number | null | undefined;

    #headlineTrans = 'web.opta_expected_goals_season';
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        setTimeout(() => {
            const element = document.querySelector('#opta-expected-goals-season') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_excpected_goals_season) {
            Opta.widgets.ref_widget_excpected_goals_season.destroy(true);
        }
    }

    getHtml(): string {
        return `
<opta-widget widget="expected_goals_season" load="false" widget_id="ref_widget_excpected_goals_season" 
competition="${this.competitionId}" season="${this.seasonId}" team="${this.participantId}"  template="normal" 
show_date_picker="false" show_competition_name="false" show_player_list="true" player_list_ordering="none" 
competition_naming="brief" overview_stats="total_player_xg,total_shots,shots_on_target,goals" 
show_patterns_of_play="true" 
show_shot_summary="true" shot_summary_order_by="time_descending" 
shot_summary_columns="date,match,minute,xg" date_format="DD/MM/YYYY" show_key="false"
 show_info_tooltips="true" show_tooltips="true" show_timecontrols="true" team_naming="full"
  player_naming="short_name" show_logo="false" show_title="true" title="${this.transHeadline}"
    breakpoints="550, 700" sport="football"></opta-widget>

  

            `;
    }
}
