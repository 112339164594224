<div class="container bg-light position-relative">
    <div class="row">
        <!-- Col Left -->
        <div class="col-lg-8 bg-white pl-0 pr-0 pr-lg-6">
            <ng-container *ngIf="data; else elseBlock">
                <div class="pl-5 pt-5 pr-5 pr-md-3 pb-3">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="media">
                                <div *ngIf="data.photo" class="img-circle img-circle--100 mr-3 rounded-circle mb-1">
                                    <img [src]="cdn + data.photo" [attr.alt]="data.name" height="100" />
                                </div>
                                <div
                                    *ngIf="!data.photo && data.participant_logo"
                                    class="img-circle img-circle--100 mr-3 rounded-circle mb-1"
                                >
                                    <img [src]="cdn + data.participant_logo" [attr.alt]="data.name" height="100" />
                                </div>
                                <div class="media-body">
                                    <h1 class="mb-2" data-cy="player-name">{{ data.name }}</h1>
                                    <div *ngIf="data.country_name" class="mb-2 h5 d-flex">
                                        {{ data.country_name }}
                                        <div class="img-circle img-circle--20 ml-2 rounded-circle">
                                            <img
                                                src="/img/flags/1x1/{{ data.iso | lowercase }}.svg"
                                                alt="Flag of {{ data.country_name }}"
                                                height="20"
                                            />
                                        </div>
                                    </div>
                                    <div class="font-weight-bold app-white text-body mb-1">
                                        {{
                                            'web.position_' + sportName + '_' + (data.position | lowercase) | translate
                                        }}
                                        <ng-container *ngIf="data.participant_name"
                                            >({{ data.participant_name }})
                                        </ng-container>
                                    </div>
                                    <div *ngIf="data.participants">
                                        <a
                                            *ngFor="let p of data.participants; trackBy: trackByIndex"
                                            class="crumb-inline crumb-inline__link"
                                            [routerLink]="['/page/sport/participant/', sportName + '-' + sportId, p.id]"
                                        >
                                            <img
                                                src="{{ cdn + p.image | responsive : 'participant' : 'small' }}"
                                                alt="{{ p.name }}"
                                                loading="lazy"
                                                width="20"
                                                height="20"
                                                class="img-cover"
                                            />

                                            {{ p.name }}</a
                                        >
                                    </div>
                                    <div *ngIf="!deviceService.isMobile() && data">
                                        <span *ngIf="data.place_of_birth">
                                            <span class="text-gray">{{ 'web.place_of_birth' | translate }}:</span>
                                            {{ data.place_of_birth }}
                                        </span>
                                        <span *ngIf="data.date_of_birth">
                                            <ng-container *ngIf="data.place_of_birth">, </ng-container>
                                            <span class="text-gray">{{ 'web.date_of_birth' | translate }}:</span>
                                            {{ data.date_of_birth | livDate : 'date' }}
                                        </span>
                                        <span *ngIf="data.height">
                                            , <span class="text-gray">{{ 'web.height' | translate }}:</span>
                                            {{ data.height }} cm
                                        </span>
                                        <span *ngIf="data.weight">
                                            , <span class="text-gray">{{ 'web.weight' | translate }}:</span>
                                            {{ data.weight }} kg
                                        </span>
                                        <span *ngIf="data.preferred_foot">
                                            ,
                                            <span class="text-gray" *ngIf="!isHockey(sportName)"
                                                >{{ 'web.preferred_foot' | translate }}:</span
                                            >
                                            <span class="text-gray" *ngIf="isHockey(sportName)"
                                                >{{ 'web.hockey_stick_holding' | translate }}:</span
                                            >
                                            {{ 'web.' + data.preferred_foot | lowercase | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav-wrapper">
                    <ul class="nav nav-tabs nav-tabs--shadows font-weight-bold pt-3" id="tabs-left" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active px-3 px-md-5"
                                [class.active]="activeTab == 1"
                                id="tab1"
                                data-toggle="tab"
                                href="#tab1-content"
                                (click)="activeTabFce(1)"
                                role="tab"
                                aria-controls="tab1-content"
                                aria-selected="true"
                                >{{ 'web.overview' | translate | transinit }}</a
                            >
                        </li>
                        <li *ngIf="deviceService.isMobile()" class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                [class.active]="activeTab == 2"
                                id="tab2"
                                data-toggle="tab"
                                href="#tab2-content"
                                role="tab"
                                (click)="activeTabFce(2)"
                                aria-controls="tab2-content"
                                aria-selected="false"
                                >{{ 'web.information' | translate | transinit }}</a
                            >
                        </li>
                        <li *ngIf="deviceService.isMobile() && data.stats.length > 0" class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                [class.active]="activeTab == 3"
                                id="tab3"
                                data-toggle="tab"
                                href="#tab3-content"
                                role="tab"
                                (click)="activeTabFce(3)"
                                aria-controls="tab3-content"
                                aria-selected="false"
                                >{{ 'web.statistics' | translate | transinit }}</a
                            >
                        </li>
                        <li *ngIf="data.players_transfers && data.players_transfers.length > 0" class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                [class.active]="activeTab == 4"
                                id="tab4"
                                data-toggle="tab"
                                href="#tab1-content"
                                (click)="activeTabFce(4)"
                                role="tab"
                                aria-controls="tab4-content"
                                aria-selected="false"
                                >{{ 'web.player-transfers' | translate | transinit }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="tab-content bg-white px-0 px-lg-3 py-3 has-nomenclature--inside position-relative">
                    <div
                        class="tab-pane active"
                        [class.active]="activeTab == 1"
                        id="tab1-content"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <!--Live matches-->
                        <ng-container *ngIf="dataMatch?.live && dataMatch!.live.length > 0">
                            <h6
                                class="table-sport__head bg-dark app-text-dark-tournament bg-primary--light"
                                data-toggle="collapse"
                                data-target="#collapse-1-live"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text app-text-dark-tournament">{{
                                    'web.live_matches' | translate
                                }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-live">
                                <app-component-match
                                    *ngFor="let match of dataMatch?.live; trackBy: trackById"
                                    page="'participant'"
                                    [match]="match"
                                    [sportName]="sportName"
                                    [sportId]="sportId"
                                    (onSelect)="loadMatch($event)"
                                ></app-component-match>
                            </div>
                        </ng-container>
                        <!--End Live matches-->
                        <!--Latest matches-->
                        <ng-container *ngIf="dataMatch?.finished && dataMatch!.finished.length > 0">
                            <h6
                                class="table-sport__head bg-dark text-white"
                                data-toggle="collapse"
                                data-target="#collapse-1-latest"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text">{{ 'web.latest_matches' | translate }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-latest">
                                <app-component-match
                                    *ngFor="let match of dataMatch?.finished | slice : 0 : 20; trackBy: trackById"
                                    [dateFormat]="'localDate'"
                                    page="'participant'"
                                    data-cy="player-latest"
                                    [match]="match"
                                    [matchActions]="
                                        data.match_actions && data.match_actions[match.id]
                                            ? data.match_actions[match.id]
                                            : null
                                    "
                                    [playerStatistics]="
                                        data.match_statistics && data.match_statistics[match.id]
                                            ? data.match_statistics[match.id]
                                            : null
                                    "
                                    [substitutions]="
                                        data.substitutions &&
                                        data.substitutions[match.id] &&
                                        data.substitutions[match.id].length > 0
                                            ? data.substitutions[match.id]
                                            : null
                                    "
                                    [sportName]="sportName"
                                    [sportId]="sportId"
                                    [participantId]="data.participant_id"
                                    (onSelect)="loadMatch($event)"
                                ></app-component-match>
                            </div>
                        </ng-container>
                        <!--End latest matches-->
                        <!--Not started matches-->
                        <ng-container *ngIf="dataMatch?.not_started && dataMatch!.not_started.length > 0">
                            <h6
                                class="table-sport__head bg-dark text-white"
                                data-toggle="collapse"
                                data-target="#collapse-1-not_started"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text">{{ 'web.not_started_matches' | translate }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-not_started">
                                <app-component-match
                                    *ngFor="let match of dataMatch?.not_started | slice : 0 : 20; trackBy: trackById"
                                    [dateFormat]="'localDateTimeShort'"
                                    page="'participant'"
                                    data-cy="player-not_started"
                                    [match]="match"
                                    [sportName]="sportName"
                                    [sportId]="sportId"
                                    (onSelect)="loadMatch($event)"
                                >
                                </app-component-match>
                            </div>
                        </ng-container>
                        <!--End notstarted matches-->

                        <div
                            *ngIf="
                                dataMatch?.live?.length == 0 &&
                                dataMatch?.finished?.length == 0 &&
                                dataMatch?.not_started?.length == 0
                            "
                            class="text-center"
                        >
                            <strong>{{ 'web.no_data_found' | translate }}</strong>
                        </div>
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 2"
                        id="tab2-content"
                        role="tabpanel"
                        aria-labelledby="tab2"
                    >
                        <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
                            <span class="font-weight-bold text-uppercase"> &nbsp; </span>
                        </div>
                        <table class="table table-sm stage-table">
                            <tr *ngIf="data.place_of_birth">
                                <td>{{ 'web.place_of_birth' | translate }}:</td>
                                <td>{{ data.place_of_birth }}</td>
                            </tr>
                            <tr *ngIf="data.date_of_birth">
                                <td>{{ 'web.date_of_birth' | translate }}:</td>
                                <td>{{ data.date_of_birth | livDate : 'date' }}</td>
                            </tr>
                            <tr *ngIf="data.height">
                                <td>{{ 'web.height' | translate }}:</td>
                                <td>{{ data.height }} cm</td>
                            </tr>
                            <tr *ngIf="data.weight">
                                <td>{{ 'web.weight' | translate }}:</td>
                                <td>{{ data.weight }} kg</td>
                            </tr>
                            <tr *ngIf="data.preferred_foot">
                                <td *ngIf="!isHockey(sportName)">{{ 'web.preferred_foot' | translate }}:</td>
                                <td *ngIf="isHockey(sportName)">{{ 'web.hockey_stick_holding' | translate }}</td>
                                <td>{{ 'web.' + data.preferred_foot | lowercase | translate }}</td>
                            </tr>
                        </table>
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 3"
                        id="tab3-content"
                        role="tabpanel"
                        aria-labelledby="tab3"
                    >
                        <div class="my-2 p-1">
                            <select class="custom-select border-light2" [formControl]="seasonControl">
                                <option
                                    *ngFor="let season of data.seasons; trackBy: trackByFnSeason"
                                    [value]="season.season_id"
                                >
                                    {{ season.season_name }}
                                </option>
                            </select>
                        </div>

                        <div *ngFor="let statsRow of data.stats; trackBy: trackByIndex">
                            <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
                                <span class="font-weight-bold text-uppercase">
                                    {{ statsRow.subtournament_name || statsRow.tournament_name }}
                                    {{ statsRow.season_startYear }}
                                    <ng-container *ngIf="statsRow.season_startYear !== statsRow.season_endYear">
                                        / {{ statsRow.season_endYear }}
                                    </ng-container>
                                    <small *ngIf="data.stats.length > 1">({{ statsRow.participant_name }})</small>
                                </span>
                            </div>
                            <div class="bg-white">
                                <div class="collapse show" id="collapse-drivers">
                                    <div class="table-responsive">
                                        <table class="table table-sm stage-table">
                                            <tbody>
                                                <ng-container
                                                    *ngFor="
                                                        let group of statsRow.data | uni : statsVal | keys;
                                                        trackBy: trackByIndex
                                                    "
                                                >
                                                    <tr *ngIf="group.key !== 'init'" class="bg-light2">
                                                        <td class="small align-middle text-center" colspan="2">
                                                            {{ 'web.stats_' + group.key | translate }}
                                                        </td>
                                                    </tr>

                                                    <ng-container
                                                        *ngFor="let attr of group.value | keys; trackBy: trackByIndex"
                                                    >
                                                        <tr *ngIf="attr[1] !== null">
                                                            <td class="text-nowrap align-middle">
                                                                {{ 'web.playerstats_tooltip_' + attr.key | translate }}
                                                            </td>
                                                            <td
                                                                [class.font-weight-bold-extend]="
                                                                    attr.key === 'goals_scored'
                                                                "
                                                                class="text-nowrap align-middle"
                                                            >
                                                                {{ attr.value == 0 ? '-' : attr.value }}
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mb-md-3 bg-white">

                                <div class="collapse show" id="collapse-drivers">
                                    <div class="table-responsive">
                                        <table class="table table-sm stage-table">
                                            <tbody>
                                                <ng-container *ngFor="let attr of statsRow.data; trackBy: trackByIndex">
                                                    <tr *ngIf="attr[1] !== null">
                                                        <td class="text-nowrap align-middle">
                                                            {{ 'web.playerstats_tooltip_' + attr[0] | translate }}
                                                        </td>
                                                        <td class="text-nowrap align-middle">{{ attr[1] }}</td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 4"
                        id="tab4-content"
                        role="tabpanel"
                        aria-labelledby="tab4"
                    >
                        <app-player-transfers
                            [playerTransfers]="data.players_transfers"
                            [sportId]="sportId"
                            [sportName]="sportName"
                            [showName]="false"
                        ></app-player-transfers>
                    </div>

                    <div *ngIf="client.use('seoCard') && data?.player_info" class="card shadow border-0 mt-5">
                        <div class="app-info-card card-body p-0 p-md-5">
                            <div class="media">
                                <svg class="icon icon--32 mr-3">
                                    <use href="#icon-bulp-2" />
                                </svg>
                                <div class="media-body mt-2">
                                    <h6 class="font-weight-bold text-uppercase">{{ data?.name }}</h6>
                                    <div [innerHTML]="data?.player_info | markdown"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #elseBlock>
                <app-placeholder-tournament [progress]="false"></app-placeholder-tournament>
            </ng-template>
        </div>
        <!-- / Col Left -->
        <!-- Col Right -->
        <div class="col-lg-4 p-3 d-none d-lg-block">
            <div [class.sticky-parent]="stickyDetail">
                <ng-container
                    *ngIf="
                        data &&
                        data.data.not_started &&
                        data.data.not_started.length > 0 &&
                        data.data.not_started[0].participants.length === 2
                    "
                >
                    <div class="m-3">
                        <h4 class="font-weight-bold text-uppercase mb-3">
                            <img
                                *ngIf="data.tournament_logo"
                                [src]="data.tournament_logo"
                                [attr.alt]="data.sub_tournament_name"
                                height="24"
                                class="mr-2"
                            />
                            {{ 'web.following_event' | translate }}
                        </h4>
                        <div class="row mb-4 bg-white align-items-center">
                            <div class="col-5">
                                <div class="text-center p-3 h-100">
                                    <div class="img-team img-team--lg img-team--home">
                                        <!-- <svg class="icon icon--12">
                                            <use href="#icon-heart"></use>
                                        </svg> -->
                                        <img
                                            [src]="data.data.not_started[0]?.participants?.[0]?.image"
                                            [alt]="data.data.not_started[0]?.participants?.[0]?.name"
                                            class="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <h5 class="mb-0">
                                        <a
                                            [routerLink]="[
                                                '/page/sport/participant',
                                                sportName + '-' + sportId,
                                                data.data.not_started[0].participants[0].id
                                            ]"
                                            class="text-reset"
                                        >
                                            {{ data.data.not_started[0].participants[0].name }}
                                        </a>
                                    </h5>
                                    <div class="text-muted">
                                        #{{ data.data.not_started[0].participants[0].table_position }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 text-center">
                                <div class="h1 text-light2">vs.</div>
                            </div>
                            <div class="col-5">
                                <div class="text-center p-3 h-100">
                                    <div class="img-team img-team--lg img-team--away">
                                        <img
                                            [src]="data.data.not_started[0].participants[1].image"
                                            [alt]="data.data.not_started[0].participants[1].name"
                                            class="img-fluid rounded-circle"
                                        />
                                        <!-- <svg class="icon icon--12">
                                            <use href="#icon-heart"></use>
                                        </svg> -->
                                    </div>
                                    <h5 class="mb-0">
                                        <a
                                            [routerLink]="[
                                                '/page/sport/participant',
                                                sportName + '-' + sportId,
                                                data.data.not_started[0].participants[1].id
                                            ]"
                                            class="text-reset"
                                        >
                                            {{ data.data.not_started[0].participants[1].name }}
                                        </a>
                                    </h5>
                                    <div class="text-muted">
                                        #{{ data.data.not_started[0].participants[1].table_position }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="matchId" class="col-right__inner" data-cy="right-column-match">
                    <app-match-detail
                        class="sticky-parent__match"
                        data-cy="player-match-detail"
                        [class.app-match-detail--shadow]="stickyDetail"
                        [style.max-height]="viewPortHeightMatchDetail"
                        [id]="matchId"
                        [matchState]="matchState"
                        [tracker]="tracker"
                        [stream]="stream"
                    ></app-match-detail>
                </div>
                <ng-container *ngIf="data">
                    <!--Tournament name +  filters-->
                    <div class="">
                        <div class="d-flex align-items-end">
                            <div class="flex-grow-1 ml-1 mb-2" *ngIf="data.seasons.length > 1">
                                <ng-container *ngIf="!isMobile; else mobile">
                                    <select class="custom-select border-light2" [formControl]="seasonControl">
                                        <option
                                            *ngFor="let season of data.seasons; trackBy: trackByFnSeason"
                                            [value]="season.season_id"
                                        >
                                            {{ season.season_name }}
                                        </option>
                                    </select>
                                </ng-container>
                                <ng-template #mobile>
                                    <select class="custom-select border-light2" [formControl]="seasonControl">
                                        <option
                                            *ngFor="let season of data.seasons; trackBy: trackByFnSeason"
                                            [value]="season.season_id"
                                        >
                                            {{ season.season_name }}
                                        </option>
                                    </select>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let statsRow of data.stats; trackBy: trackByIndex">
                        <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
                            <span class="font-weight-bold text-uppercase">
                                <img
                                    *ngIf="statsRow.participant_logo"
                                    [src]="cdn + statsRow.participant_logo"
                                    [attr.alt]="statsRow.participant_name"
                                    height="20"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    [attr.title]="statsRow.participant_name"
                                />
                                {{ statsRow.subtournament_name || statsRow.tournament_name }}
                                {{ statsRow.season_startYear }}
                                <ng-container *ngIf="statsRow.season_startYear !== statsRow.season_endYear">
                                    / {{ statsRow.season_endYear }}
                                </ng-container>
                                <small *ngIf="data.stats.length > 1">({{ statsRow.participant_name }})</small>
                            </span>
                        </div>
                        <div class="bg-white">
                            <!--<div class="mb-3 px-2 pb-4 bg-white">-->
                            <div class="collapse show" id="collapse-drivers">
                                <div class="table-responsive">
                                    <table class="table table-sm stage-table">
                                        <tbody>
                                            <ng-container
                                                *ngFor="
                                                    let group of statsRow.data | uni : statsVal | keys;
                                                    trackBy: trackByIndex
                                                "
                                            >
                                                <tr *ngIf="group.key !== 'init'" class="bg-light2">
                                                    <td class="small align-middle text-center" colspan="2">
                                                        {{ 'web.stats_' + group.key | translate }}
                                                    </td>
                                                </tr>

                                                <ng-container
                                                    *ngFor="let attr of group.value | keys; trackBy: trackByIndex"
                                                >
                                                    <tr *ngIf="attr[1] !== null">
                                                        <td class="text-nowrap align-middle">
                                                            {{ 'web.playerstats_tooltip_' + attr.key | translate }}
                                                        </td>
                                                        <td
                                                            [class.font-weight-bold-extend]="
                                                                attr.key === 'goals_scored'
                                                            "
                                                            class="text-nowrap align-middle"
                                                        >
                                                            {{ attr.value == 0 ? '-' : attr.value }}
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- / Col Right -->
    </div>
</div>
