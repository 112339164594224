import { Component, OnInit, OnDestroy } from '@angular/core';
import { FavoriteService } from 'src/app/services/favorite.service';
import { MyMatchesService } from 'src/app/services/my-matches.service';
import { MyMatchesInterface } from 'src/app/interfaces/my-matches.interface';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';

import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-user-my-matches',
    templateUrl: './my-matches.component.html',
})
export class MyMatchesComponent extends BaseComponent
    implements OnInit, OnDestroy {
    private subscriber: Subscription;

    public data: MyMatchesInterface;

    public dataAll = {};

    public dataLive = {};

    public dataFinished = {};

    public dataNotStarted = {};

    public constructor(
        public matches: MyMatchesService,
        public favorite: FavoriteService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.load();
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
    }

    public getData(type: string = 'all'): any[] | Record<string, any> {
        if (type !== 'all' && _.has(this.data, type)) {
            // @ts-ignore
            return this.normalize(this.data[type]);
        }

        return this.normalize([
            ...this.data.live,
            ...this.data.finished,
            ...this.data.not_started!,
        ]);
    }

    /**
     * Check if object is empty
     * @param  {object} data
     * @return {boolean}
     */
    public isEmpty(data: Record<string, any>): boolean {
        return _.isEmpty(data);
    }

    /**
     * Normalize data to sub levels
     * @return {object}
     */
    private normalize(data: any[]): Record<string, any> {
        const normalizeData: Record<string, any> = {};
        const grouped = _.groupBy(data, (val): string => val.sport_code_name);

        _.each(grouped, (val): void => {
            normalizeData[val[0].sport_code_name] = _.groupBy(
                val,
                'sub_tournament_id',
            );
        });

        return normalizeData;
    }

    private async load(): Promise<any> {
        try {
            const subscribe = await this.matches.get(
                this.favorite.getMatchFavorites(),
            );
            this.subscriber = subscribe.subscribe((val): void => {
                this.data = val;

                this.dataAll = this.getData('all');
                this.dataLive = this.getData('live');
                this.dataFinished = this.getData('finished');
                this.dataNotStarted = this.getData('not_started');
                this.finished();
            });
        } catch (e) {
            this.error();
        }
    }
}
