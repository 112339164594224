/* eslint-disable no-async-promise-executor */
import { Injectable } from '@angular/core';
import { MatchData, MatchState } from '@interfaces/match-data.interface';
import type { MatchChange, MyMatchesNtfInterface } from '@interfaces/my-matches.interface';
import { Subject } from 'rxjs';
import { toPairs } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class MyMatchesNotificationService implements MyMatchesNtfInterface {
    public matchSubject = new Subject<MatchData & MatchChange>();
    public matchSubject$ = this.matchSubject.asObservable();

    private matches: MatchData[] = [];
    public constructor() {
    }

    /**
     * Return true if match has some change and was added to queue
     * and falseif not
     */
    public add(match:MatchData): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                const change = await this.process(match);
                const hasSomeChange = toPairs(change).some(([, val]) => val);


                await this.addToQueue(hasSomeChange, match, change);

                resolve(hasSomeChange);
            } catch (e) {
                reject(e);
            }
        });
    }

    private process(match:MatchData): Promise<MatchChange> {
        return new Promise((resolve, reject) => {
            try {
                const statusChange = this.detectStatusChange(match);
                const scoreHomeChange = this.detectScoreChange(match, 'home');
                const scoreAwayChange = this.detectScoreChange(match, 'away');
                resolve({ statusChange, scoreHomeChange, scoreAwayChange });
            } catch (e) {
                reject(e);
            }
        });
    }

    private detectScoreChange(match:MatchData, type: 'home' | 'away'): boolean {
        const found = this.getMatch(match.id);

        if (found && type === 'home' &&
        match.score.home_team !== found.score.home_team) {
            return true;
        }

        if (found && type === 'away' &&
        match.score.away_team !== found.score.away_team) {
            return true;
        }


        return false;
    }

    private detectStatusChange(match:MatchData): boolean {
        const found = this.getMatch(match.id);
        // const isLive = this.isLive(match);


        if (found && match.code_state !== found.code_state) {
            return true;
        }

        return false;
    }

    private addToQueue(hasSomeChange: boolean, match:MatchData, change: MatchChange): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                const index = this.matches.findIndex(val => match.id === val.id);

                if (index !== -1 && index) {
                    this.matches.splice(index, 1);
                }

                this.matches.push(match);

                if (hasSomeChange) {
                    this.matchSubject.next({ ...match, ...change });
                }
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     *  Get match from existing matches

     */
    private getMatch(id: number): MatchData | undefined {
        // @ts-ignore
        return this.matches.findLast(m => m.id === id);
    }

    private isLive(match:MatchData): boolean {
        return match.match_state === MatchState.INPLAY ||
            match.match_state === MatchState.INPLAYPAUSE;
    }

    private isBefore(match:MatchData): boolean {
        return match.match_state === MatchState.BEFORE;
    }
}
