import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';

import { LangService } from '../../../services/lang.service';
import { ClientService } from '../../../services/client.service';
import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { InfoService } from '../../../services/info.service';

@Component({
    selector: 'app-match',
    templateUrl: './match.component.html',
})
export class MatchPageComponent extends BaseComponent implements OnInit {
    public matchId: number | null = null;

    private data: Record<string, any>;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public location: Location,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.router.paramMap.subscribe((params): void => {
            this.matchId = +params.get('id')!;
            $('html, body').animate({ scrollTop: 0 }, 0);
            this.start();
        });
    }

    public loadedData(data: Record<string, any>): void {
        this.data = data;
        this.lang.getLang().then((): Promise<any> => this.createTitle());
        this.finished(true);
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_match').subscribe((): void => {
            const participants: string[] = [];

            this.data.participants.forEach((val: Record<string, any>): void => {
                if (val.name_short !== null) {
                    participants.push(val.name_short);
                } else {
                    participants.push(val.name);
                }
            });

            const names = participants.join(' - ');
            const trans = this.translate.instant('web.title_match');
            const sport = this.translate.instant(`sport.${this.data.sport_code_name}`);
            const desc = this.translate.instant('web.description_match');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} - ${names} ${trans} | ${client}`,
                    description: `${names} - LIVE - ${sport} ${desc}`,
                },
                true,
            );
        });
    }
}
