import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { banner } from '@services/decorators/class.decorator';

import { WindowRefService } from '@shared/window-ref';

import { BannerBase } from '../banner.base';

@banner({
    position: 4,
})
@Component({
    selector: 'app-banner[mobile-body]',
    templateUrl: './mobile-body.component.html',
    styleUrls: ['./mobile-body.component.scss'],
    animations: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class BannerMobileBodyComponent extends BannerBase {
    public bannerPositionId = 4;
    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef,
        protected win: WindowRefService) {
        super(deviceService, ref, win);
    }


    /**
     * Dismiss banner
     */
    public close(): void {
        this.banner = null;
    }
}
