<div class="container bg-light position-relative">
    <div class="row bg-white p-0">
        <div class="col-lg-8 bg-white p-0" [ngClass]="parentClass">
            <div class="tab-content bg-white p-3 has-nomenclature position-relative">
                <div
                    class="tab-pane active"
                    [attr.id]="'tab' + type + '-content'"
                    role="tabpanel"
                    [attr.aria-labelledby]="'tab' + type"
                >
                    <div class="table-sport mx-n3 mx-sm-0">
                        <div *ngIf="loaded; else elseBlock" class="table-sport__content">
                            <ng-container *ngIf="loadedError; else loadBlock">
                                <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                    <div class="text-center pt-7">
                                        <svg class="icon icon--64 mb-4">
                                            <use [attr.xlink:href]="'#icon-' + sportName" />
                                        </svg>
                                        <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                        <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #loadBlock>
                                <ng-container *ngIf="!noCategory()">




                                    <ng-container
                                        *ngFor="
                                                let cg of data
                                                    | navSearch : 'alpha' : alphaFilter : sportId : sportName
                                                    | slice : 0 : slice2;
                                                trackBy: trackByFn;
                                                index as i
                                            ">
                                        <div class="table-sport__subhead d-flex align-items-center py-0 bg-light">
                                            <img
                                                *ngIf="isMobile && cg?.tournament_country_iso && sportName === 'tennis'; else adminIcon"
                                                src="{{ cdn + '/img/flags/1x1/' + (cg?.tournament_country_iso | lowercase) + '.svg' }}"
                                                width="20"
                                                class="mr-2 rounded-circle"
                                                alt="{{ cg?.tournament_country_iso }}"
                                            />
                                            <ng-template #adminIcon>
                                                <img
                                                    src="{{ cdn + cg?.image }}"
                                                    width="20"
                                                    height="20"
                                                    loading="lazy"
                                                    class="mr-2 rounded-circle"
                                                    alt="{{ cg?.category_name }}  {{ cg?.sub_tournament_name }}"
                                                />
                                            </ng-template>

                                            <div class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1">
                                                <ng-container *ngIf="isMobile">
                                                    <ng-container *ngIf="sportName !== 'tennis' ; else tennis"
                                                    ><span>{{ cg.sub_tournament_name }}</span>
                                                        <div class="text-muted mt-1 mt-sm-0">{{ cg.category_name }}</div>
                                                    </ng-container>
                                                    <ng-template #tennis>
                                                        <span *ngIf="!cg?.sub_tournament_city">{{ cg.sub_tournament_name }}</span>
                                                        <ng-container *ngIf="cg?.sub_tournament_city">{{ cg.sub_tournament_city }}</ng-container>
                                                        <ng-container
                                                            *ngIf="cg?.tournament_country || cg?.tournament_country_iso3 || cg?.tournament_country_iso"
                                                        >
                                                            | {{ cg.tournament_country || cg.tournament_country_iso3 || cg.tournament_country_iso }}
                                                        </ng-container>
                                                        <div class="text-muted mt-1 mt-sm-0">
                                                            {{
                                                                cg.category_name
                                                            }}
                                                            <ng-container *ngIf="cg?.singles_doubles">
                                                                <ng-container *ngIf="cg?.singles_doubles === 1 && !isMobile">{{
                                                                        'web.singles' | translate
                                                                    }}
                                                                </ng-container>
                                                                <ng-container *ngIf="cg?.singles_doubles === 2">
                                                                    - {{ 'web.doubles' | translate }}
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="cg?.sub_tournament_qualifying === 1">
                                                                - {{ 'web.qualifying' | translate }}
                                                            </ng-container>
                                                            <ng-container *ngIf="cg?.sub_tournament_ground">
                                                                - {{ cg.sub_tournament_ground | translate | lowercase }}
                                                            </ng-container>
                                                        </div>
                                                    </ng-template>
                                                </ng-container>

                                                <ng-container *ngIf="!isMobile">
                                                    <span class="d-none d-sm-inline">{{ cg.category_name }} | </span>
                                                    <span class="d-sm-none" *ngIf="!isMobile">{{ cg.category_name_short }} | </span>
                                                    <ng-container *ngIf="sportName !== 'tennis'; else tennis">
                                                        <span> {{ cg.sub_tournament_name }}</span>
                                                    </ng-container>
                                                    <ng-template #tennis>
                                                        <span *ngIf="!cg?.sub_tournament_city"> {{ cg.sub_tournament_name }}</span>
                                                        <span>
                                                            <ng-container *ngIf="cg?.sub_tournament_city">{{ cg.sub_tournament_city }}</ng-container>

                                                            <ng-container *ngIf="cg?.tournament_country_iso3 || cg?.tournament_country_iso">
                                                                ({{ cg.tournament_country_iso3 || cg.tournament_country_iso }})</ng-container></span>
                                                        <ng-container *ngIf="cg?.sub_tournament_qualifying === 1">
                                                            - <span>{{ 'web.qualifying' | translate }}</span></ng-container
                                                        >
                                                        <ng-container *ngIf="cg?.sub_tournament_ground">
                                                            - <span>{{ cg.sub_tournament_ground! | translate | lowercase }}</span></ng-container
                                                        >
                                                    </ng-template>
                                                </ng-container>
                                            </div>


                                            <a
                                                [routerLink]="['/page/sport/event', sportName + '-' + sportId, cg?.tournament_id]"
                                                class="small text-reset d-none d-sm-inline app-category-detail-link"
                                            >
                                                <svg class="icon icon--12 mr-2 d-none dm-md-inline app-icon-table">
                                                    <use xlink:href="#icon-table" />
                                                </svg>
                                                {{ 'web.detail' | translate | transinit }}</a
                                            >

                                            <a
                                                class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link app-mob-category-link"
                                                [routerLink]="['/page/sport/event', sportName + '-' + sportId, cg?.tournament_id]"
                                            >
                                                <svg class="icon icon--14 icon--gray mb-1"><use xlink:href="#icon-info"></use></svg>
                                                {{ 'web.detail' | translate | transinit }}
                                            </a>
                                        </div>


                                    </ng-container>





                                    <ng-container *ngIf="!loadedError && data.length == 0">
                                        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="text-center pt-7">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                                <p class="mb-6">{{ 'web.no_data_found' | translate }}</p>
                                            </div>
                                        </div>
                                    </ng-container>


                                </ng-container>

                            </ng-template>
                        </div>
                        <ng-template #elseBlock>
                            <app-placeholder-match [num]="6" [withHeader]="true"></app-placeholder-match>
                        </ng-template>
                    </div>


                </div>
            </div>
        </div>
        <div class="col-lg-4 pt-3 d-none d-lg-block" [ngClass]="matchClass">
            <div [innerHTML]="categoryInfo | markdown"></div>
        </div>
    </div>

    <aside class="nav-sports d-none" [class.d-xl-block]="client.use('sportQuickNav')">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation
                data-cy="aside-sport"
                type="aside"
                [limit]="asideLimit"
                [sportId]="sportId"
            ></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset app-cursor"
                data-cy="aside-sport-toggle"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
            </a>
        </nav>
    </aside>
    <aside class="nav-sports nav-sports--extra d-none" [class.d-xl-block]="client.use('sportQuickNav')">
        <nav class="nav flex-column nav-side-menu bg-light">
            <app-side-menu-navigation [sportId]="sportId" [sportName]="sportName"></app-side-menu-navigation>
        </nav>
    </aside>
</div>
<ng-container *ngIf="isMobile">
    <app-ads-container position="mobile_layer" [platforms]="['mobile']">
        <app-ads mobile-layer #ads></app-ads>
    </app-ads-container>
</ng-container>
