/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Service for user
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */

import { EventEmitter, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { VenueDetailInterface, VenueInterface } from '@interfaces/venue.interface';

import type { Datatable, DatatabeSort } from '@interfaces/datatable.interface';

import { CommonService } from '@shared/common.service';

import URL_CONFIG from '../config/url.config';
import SPORT_CONFIG from '../config/sport.config';
import { CountryInterface } from '../interfaces/country.interface';

import { UtilsService } from './utils.service';


@Injectable({
    providedIn: 'root',
})
export class VenueService {
    public onLoad = new EventEmitter<boolean>();

    public isLoaded = false;

    public countries: CountryInterface[] | null = null;


    public constructor(
        private http: HttpClient,
        private common: CommonService,

    ) {
    }


    public get(page:number = 0, limit: number = SPORT_CONFIG.venues.defaultLimit, sortBy:DatatabeSort,
        q: string = '', country?:string,
    ):
        Observable<Datatable<VenueInterface>> {
        const url = URL_CONFIG.api.venues;

        const options = { params: new HttpParams()
            .set('p', page.toString())
            .set('limit', limit.toString())
            .set('sort', JSON.stringify(sortBy))
            .set('q', q.toString())
            .set('country', country ?? '0'),
        };
        return this.http.get<Datatable<VenueInterface>>(url, options).pipe(
            catchError(this.common.errorCallback),
        );
    }

    public getByVenue(venueId: number):
    Observable<VenueDetailInterface> {
        const url = UtilsService.replace(
            'venueId',
            venueId as any,
            URL_CONFIG.api.venue,
        );

        const options = { };

        return this.http.get<VenueDetailInterface>(url, options).pipe(
            catchError(this.common.errorCallback),
        );
    }
}
