import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/store/states/user.state';
import { Observable } from 'rxjs';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user.service';


@Component({
    selector: 'app-page-account',
    templateUrl: './page-account.component.html',
})
export class PageAccountComponent {
    @Select(UserState.data) public user$: Observable<UserInterface>;

    public constructor(public user: UserService) {}

    public signOff(): void {
        this.user.signOffProcces();
    }
}
