/**
 *  VPlayer transfer form
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2022 livescore
 */

import { Injectable } from '@angular/core';
import {
    FormControl,
    FormGroup,
    AbstractControl,
} from '@angular/forms';

import { BaseForm } from './base.form';

@Injectable({
    providedIn: 'root',
})
export class PlayerTransferForm extends BaseForm {
    public constructor() {
        super();
        this.init();
    }

    /**
     * Initialize form Controls
     * @return {void}
     */
    protected init(): void {
        super.init();
        this.formGroup = new FormGroup(
            {

                fulltext: new FormControl(''),


            },

        );
    }


    public get fulltext(): AbstractControl | null {
        return this.formGroup.get('fulltext');
    }
}
