import { Component, Input, OnInit } from '@angular/core';
import { PlayerTransfers } from '@interfaces/player-transfers.interface';
import { BaseComponent } from '@components/base.component';
import { PlayerTransferForm } from '@shared/forms/player-trasfer.form';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-player-transfers',
    templateUrl: './player-transfers.component.html',
})
export class PlayerTransfersComponent extends BaseComponent implements OnInit {
    @Input()
    public playerTransfers?: PlayerTransfers[];

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Input()
    public showName = true;

    public searchVal: string = ''

    public constructor(public form: PlayerTransferForm) {
        super();
    }

    public ngOnInit(): void {
        this.form.fulltext?.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
            this.searchVal = val;
        });
    }

    public search(): void {
        this.searchVal = this.form?.fulltext?.value ?? '';
    }
}
