<div class="m-3 tennis-tournament-info">
    <h4 class="font-weight-bold text-uppercase mb-3">{{ 'web.tournament_info' | translate }}</h4>

    <div *ngIf="tennisTournamentInfo?.surface" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_groundtype' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ 'web.ground-type_' + tennisTournamentInfo?.surface | translate }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.numberOfSets" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_numberofsets' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.numberOfSets }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.prize_money" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_totalprizemoney' | translate }}</strong>
        </div>
        <div class="col-6 text-right">
            {{ tennisTournamentInfo?.prize_money | currency : tennisTournamentInfo?.prize_currency }}
        </div>
    </div>
    <div *ngIf="tennisTournamentInfo?.startDate" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_startdate' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.startDate | date : 'd. M. y' }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.endDate" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_enddate' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.endDate | date : 'd. M. y' }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.continent" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_continent' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.continent! | translate }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.complex" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.complex' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.complex }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.numberOfCompetitors" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_numberofcompetitors' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.numberOfCompetitors }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.number_of_competitors" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_numberofcompetitors' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.number_of_competitors }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.number_of_qualified_competitors" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_number_of_qualified_competitors' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.number_of_qualified_competitors }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.number_of_scheduled_matches" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tennis_number_of_scheduled_matches' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.number_of_scheduled_matches }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.winner_last_season" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.winner_last_season' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ tennisTournamentInfo?.winner_last_season }}</div>
    </div>
    <div *ngIf="tennisTournamentInfo?.level" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.tournament_level' | translate }}</strong>
        </div>
        <div class="col-6 text-right">{{ 'web.tournament_level_' + tennisTournamentInfo?.level | translate }}</div>
    </div>
    <div *ngIf="country" class="row py-1 small">
        <div class="col-6">
            <strong>{{ 'web.country' | translate }}</strong>
        </div>
        <div class="col-6 text-right">
            <img
                width="16"
                height="16"
                loading="lazy"
                class="mr-1 img-fluid--wauto"
                [src]="'/img/flags/4x3/' + country + '.svg'"
                [attr.alt]="iso"
            />
        </div>
    </div>
</div>
