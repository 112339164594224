<main role="main" class="bg-3">
    <div class="container position-relative">
        <div class="text-center mb-0 py-7">
            <img src="/img/ball-smiley.png" width="266" class="mb-4 mt-md-7" />
            <h1 class="text-white">{{ 'web.registration_thank_u' | translate }}</h1>
            <p class="w-md-50 ml-auto mr-auto mb-md-5 text-white">{{ 'web.registration_thank_u_text' | translate }}</p>
            <a routerLink="/hp" class="btn btn-outline-light text-uppercase pt-2 px-4 mb-md-7">{{
                'web.go_home' | translate
            }}</a>
        </div>
    </div>
</main>
