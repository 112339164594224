<div class="table-sport__subhead table-sport__subhead--category d-flex align-items-center"  [ngClass]="extraCss" (click)="open(group)" data-toggle="collapse"
[attr.href]="'#collapse-cgg' + group?.category_id"
role="button" [attr.aria-expanded]="group?.isopen"  [class.collapsed]="!group?.isopen" [attr.aria-controls]="'#collapse-cgg'+ group?.category_id">
    <img src="{{group?.image}}" width="16" class="mr-2 rounded-circle" alt="{{ group?.category_name }}">
    <div class="table-sport__subhead__text mb-0 text-uppercase text-truncate">
        <span class="d-none d-sm-inline">{{ group?.category_name}}</span><span class="d-sm-none">{{ group?.category_name_short }}</span> </div>
</div>

<div class="collapse" [class.show]="group?.isopen" [attr.id]="'collapse-cgg' + group?.category_id" >
    <ng-content></ng-content>
</div>
