/**
 *  Service for participants
 *  @author Livescore <score-stats.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CommonService } from '../shared/common.service';

import { PlayerInterface } from '../interfaces/player.interface';

import URL_CONFIG from '../config/url.config';

import { BaseService } from './base.service';
import { LangService } from './lang.service';
import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root',
})
export class PlayerService extends BaseService {
    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
    ) {
        super();
    }

    /**
     * Get participant detail
     * @param {number} playerId
     * @return {Promise<Observable<ParticipantInterface>>}
     */
    public async get(
        playerId: any,
        seasonId: any,
        subId: any,
        all?:boolean,
    ): Promise<Observable<PlayerInterface> | Error> {
        const url = UtilsService.replace(
            'id',
            playerId as string,
            URL_CONFIG.api.getPlayer,
        );
        if (subId === 0) {
            subId = '';
        }
        return this.lang.getLang().then(
            (iso): Observable<PlayerInterface> => {
                const params = new HttpParams()
                    .set('lang', iso)
                    .set('id', playerId)
                    .set('limit', '20')
                    .set('all', all ? '1' : '0')
                    .set('seasonId', seasonId === null ? '' : seasonId)
                    .set('sid', subId);
                const options = params ? { params } : {};
                return this.http.get<PlayerInterface>(url, options).pipe(
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap(this.common.networkOnline()),
                );
            },
            (er): Promise<Error> => Promise.reject(new Error(er)),
        );
    }
}
