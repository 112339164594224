<ng-container *ngFor="let val of nums">
    <div *ngIf="withHeader" class="header"></div>
    <div class="box-placeholder d-flex">
        <div class="first excerpt px-1 pt-1 pb-1 align-self-center flex-grow-1">
            <div class="category text line"></div>
        </div>

        <div class="excerpt px-1 pl-1 pt-1 pb-1 align-self-center flex-grow-1">
            <div class="category text line"></div>
        </div>
    </div>

    <!-- <div class="content-wrapper">
        <div class="placeholder-new my-1" [class.my-2]="withHeader" [class.placeholder-new-header]="withHeader">
            <div class="animated-background-new"></div>
        </div>
    </div> -->
</ng-container>
