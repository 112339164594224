<ng-container *ngIf="banner && !wasClosed">
    <div class="bottom-fixed-banner" [@bannerFixed]>
        <div class="bottom-fixed-banner-child">
            <a (click)="close()" class="bottom-fixed-banner-close app-cursor">{{
                'web.close' | translate | transinit
            }}</a>
            <a
                *ngIf="banner.url; else elseBlock"
                target="_blank"
                href="{{ banner.url }}?{{ utm() }}"
                (click)="click()"
                [id]="bannerPositionId + ' | ' + banner.url"
                class="app-banner-main"
            >
                <img
                    src="{{ banner.path }}"
                    class="banner"
                    alt="{{ banner.altText }}"
                    class="bottom-fixed-banner__image"
                    srcset="{{ banner.small_image }} 600w,
                    {{ banner.medium_image }} 1024w,
                    {{ banner.large_image }} 1600w"
                />
            </a>
            <ng-template #elseBlock>
                <img
                    *ngIf="!banner.url"
                    src="{{ banner.path }}"
                    class="banner"
                    alt="{{ banner.altText }}"
                    srcset="{{ banner.small_image }} 600w,
                        {{ banner.medium_image }} 1024w,
                        {{ banner.large_image }} 1600w"
                    class="bottom-fixed-banner__image"
                />
            </ng-template>
        </div>
    </div>
</ng-container>
