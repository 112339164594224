<div class="container bg-light position-relative mb-5">
    <div class="row bg-white">
        <ng-container *ngIf="loaded && !loadedError; else elseBlock">
            <div class="pl-5 pt-5 pr-5 pr-md-3 pb-3 app-participant-detail__info ng-star-inserted">
                <div class="row">
                    <div class="col-12">
                        <div class="media">
                            <div class="mr-3">
                                <i class="fas fa-ring fa-4x"></i>
                            </div>
                            <div class="media-body">
                                <div class="d-flex icon-wrappe r justify-content-start flex-wrap">
                                    <h1 data-cy="participant-name" class="mb-2 mr-3">{{ data.complexName }}</h1>

                                    <div class="break-md"></div>
                                </div>
                                <div class="mb-2 h5 d-flex ng-star-inserted">
                                    <span *ngIf="data.complexName" class="font-weight-bold mr-1 ng-star-inserted">
                                    </span
                                    ><span *ngIf="data.countryName">({{ data.countryName }})</span>
                                </div>
                                <div class="mb-2 h5 d-flex ng-star-inserted">
                                    <span *ngIf="data.venues" class="font-weight-bold mr-1 ng-star-inserted">
                                        {{ data.venues }}
                                    </span>
                                </div>

                                <!-- <div>
                                    <a
                                        class="crumb-inline crumb-inline__link ng-star-inserted"
                                        href="/page/sport/event/soccer-5/196"
                                        >Bundesliga</a
                                    ><a
                                        class="crumb-inline crumb-inline__link ng-star-inserted"
                                        href="/page/sport/event/soccer-5/280"
                                        >DFB Pokal</a
                                    ><a
                                        class="crumb-inline crumb-inline__link ng-star-inserted"
                                        href="/page/sport/event/soccer-5/29"
                                        >Friendly Games</a
                                    ><a
                                        class="crumb-inline crumb-inline__link ng-star-inserted"
                                        href="/page/sport/event/soccer-5/198"
                                        >2nd Bundesliga</a
                                    >
                                </div> -->
                                <div class="ng-star-inserted">
                                    <span class="ng-star-inserted">
                                        <ng-container *ngIf="data.capacity">
                                            <span class="text-gray">{{ 'web.capacity' | translate }}:</span>
                                            {{ data.capacity }}
                                        </ng-container>
                                        <ng-container *ngIf="data.mapCoordinates">
                                            <a class="link ext-link app-cursor" (click)="toggleMap(data.mapCoordinates)"
                                                >{{ 'web.map' | translate }} &nbsp;</a
                                            >
                                        </ng-container>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <google-modal [mapCoordinates]="mapCoordinates" [(visible)]="map"></google-modal>
            </div>
            <div *ngIf="loadedError" class="col-12 bg-white p-5">
                <div class="pb-6">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <i class="fas fa-ring fa-4x"></i>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <section class="col-12 bg-white p-5">
                <p>
                    {{ 'web.complex_' + data.complex_id | translate }}
                </p>
            </section>
            <div class="col-12" *ngIf="data.totalImage && data.totalImage > 0">
                <aside>
                    <H2>{{ 'web.photography' | translate }}</H2>
                    <hr />
                    <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
                        <div
                            *ngFor="let image of data.images; trackBy: trackByIndex; index as i"
                            class="venue-image"
                            (click)="openGallery(i)"
                        >
                            <i class="fas fa-eye fa-3x"></i>
                            <img
                                src="/assets/complexes/images/{{ image }}.png"
                                [attr.alt]="data.complexName"
                                class="venue-image__img"
                                loading="lazy"
                                decoding="async"
                                width="300"
                                height="210"
                                srcset="
                                    /assets/complexes/images/responsive/{{ image }}-small.png   300w,
                                    /assets/complexes/images/responsive/{{ image }}-medium.png  800w,
                                    /assets/complexes/images/responsive/{{ image }}-large.png  1200w
                                "
                                sizes="(max-width: 576px) 280px, calc(100vw - 2rem)"
                            />
                        </div>
                    </div>
                </aside>
            </div>
        </ng-container>
        <ng-template #elseBlock>
            <div class="col-12 bg-white pl-0 pr-0" *ngIf="!loadedError">
                <app-placeholder-table></app-placeholder-table>
            </div>
        </ng-template>
    </div>
</div>

<!-- The Modal -->
<app-modal [(visible)]="visible" *ngIf="visible">
    <div class="content">
        <swiper
            #swiper
            (afterInit)="slideInit()"
            [config]="config"
            [initialSlide]="initialSlide"
            [navigation]="true"
            class="venue-slide"
        >
            <ng-template swiperSlide *ngFor="let image of data.images; trackBy: trackByIndex">
                <img
                    src="/assets/complexes/images/{{ image }}.png"
                    alt="Alt text"
                    class="venue-image__img"
                    loading="lazy"
                    decoding="async"
                    width="100%"
                    srcset="
                        /assets/complexes/images/responsive/{{ image }}-small.png   300w,
                        /assets/complexes/images/responsive/{{ image }}-medium.png  800w,
                        /assets/complexes/images/responsive/{{ image }}-large.png  1200w
                    "
                    sizes="(min-width: 1600px) 1500px, (min-width: 992px) and (max-width: 1599px) 85vw, calc(100vw - 2rem)"
                />
            </ng-template>
        </swiper>
    </div>
</app-modal>
