/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FavoriteService } from 'src/app/services/favorite.service';
import { switchMap, tap, mergeMap } from 'rxjs/operators';
import { of, Observable, Subscription, timer } from 'rxjs';
import { MyMatchesService } from 'src/app/services/my-matches.service';
import { isNil, each, has, isEmpty, groupBy } from 'lodash-es';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import SPORT_CONFIG from 'src/app/config/sport.config';
import { MatchData, MatchState } from 'src/app/interfaces/match-data.interface';
import { UpdateMarkets } from 'src/app/store/actions/bets.action';
import { Store } from '@ngxs/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from '@services/user.service';


import { InfoService } from '@services/info.service';

import { PartnerService } from '@services/partner.service';

import { MyMatchesInterface } from '@interfaces/my-matches.interface';

import { ClientService } from '@services/client.service';

import { LangService } from '@services/lang.service';

import { ViewportScroller } from '@angular/common';

import $ from '../../shared/jquery';
import { PageService } from '../../services/page.service';
import { BaseComponent } from '../base.component';
import { environment } from '../../../environments/environment';

declare const moment: any;


type SubTabs = 'all' | 'odds';

@Component({
    selector: 'app-my-matches',
    templateUrl: './my-matches.component.html',
})
export class MyMatchesComponent extends BaseComponent implements OnInit, OnDestroy {
    private subscriberReload: Subscription;

    private subscriberTab: Subscription;

    private subscriber: Subscription;

    public data: MyMatchesInterface;

    public matchId: number | null = null;

    public tracker: boolean = false;
    public stream: boolean = false;

    public subTab: SubTabs;

    public alphaFilter: { star: boolean; alpha: string[] };

    public parentClass: Record<string, boolean> = { 'col-lg-12': true, 'col-lg-8': false };

    public matchClass: Record<string, boolean> = {
        'col-lg-4': true,
        'd-none': true,
        'd-lg-block': false,
    };

    public dataAll = {};

    public dataLive = {};

    public dataFinished = {};

    public dataNotStarted = {};

    public alphaAvailLetters: string[] = [];

    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    private first: boolean = true;

    public matchState: MatchState;

    public constructor(
        protected page: PageService,
        public matches: MyMatchesService,
        public favorite: FavoriteService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        private store: Store,
        private router: ActivatedRoute,
        private deviceService: DeviceDetectorService,
        private user: UserService,
        private ngZone: NgZone,
        public partner: PartnerService,
        private viewportScroller: ViewportScroller,
    ) {
        super(page, title, meta, route, info);
    }

    public async ngOnInit(): Promise<any> {
        super.ngOnInit();

        this.first = true;

        await this.checkOtherParams();
        await this.checkPartnerIds();

        this.subscriberTab = this.tabSubject.subscribe((val): void => {
            if (val === 'visible') {
                this.load();
            }
        });

        this.reset();
        this.data = { live: [], finished: [], all: [] };
        const NotStarted = 'not_started';
        this.data[NotStarted] = [];
        this.subscriberReload = timer(10, environment.myMatchesReloadTime)
            .pipe(switchMap((val): Observable<any> => of(val)))
            .subscribe((): void => {
                this.load();
            });

        this.ngZone.runOutsideAngular((): void => {
            setTimeout((): void => {
                $('#my-matchesTab li:first-child a').tab('show');
            }, 100);
        });

        this.favorite.onToggleMatch.subscribe((): void => {
            this.load();
        });

        this.lang.getLang().then((): Promise<any> => this.createTitle());

        this.store.dispatch(new UpdateMarkets());
        this.viewportScroller.scrollToPosition([0, 0]);
    }

    public ngOnDestroy(): void {
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }
        if (this.subscriberTab instanceof Subscription) {
            this.subscriberTab.unsubscribe();
        }
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
    }

    private checkOtherParams(): Promise<boolean> {
        return new Promise((resolve) => {
            this.router.queryParamMap.subscribe(async (params: ParamMap) => {
                if (params.has('clear')) {
                    await this.emptyMyMatches();
                }
                resolve(true);
            });
        });
    }

    /**
     * Check if Betradar ids are in url and add them to mymatches
     * Or check if other partner type id
     * Check brid  from betradar ids
     * Check id and source
     */
    private checkPartnerIds(): Promise<boolean> {
        return new Promise((resolve): void => {
            this.router.paramMap
                .pipe(
                    mergeMap((param) => {
                        this.loaded = false;
                        if (param.has('brid')) {
                            const Brid = param.get('brid') as string;
                            return this.matches.brToId(Brid);
                        }
                        if (param.has('id') && param.has('source')) {
                            const ids = param.get('id') as string;
                            const source = param.get('source') as string;
                            return this.matches.sourceIdToId(ids, source);
                        }
                        return of(null);
                    }),
                )
                .subscribe(
                    (ids: number[] | null): void => {
                        if (!isNil(ids)) {
                            each(ids, (val): void => {
                                this.favorite.toggleMatchFavorite(val, true);
                            });
                            resolve(true);
                            this.load();
                        } else {
                            resolve(true);
                        }
                    },
                    () => {
                        resolve(true);
                    },
                );
        });
    }

    public dateFormat(match: MatchData): string {
        const Time = moment.utc(match.start_date).local();
        const Date = this.isMobile ? 'localDateTimeShort' : 'localDateShort';
        return Time.isSame(moment(), 'day') ? 'localTime' : Date;
    }

    public dateFormat2(match: MatchData): string {
        const Time = moment.utc(match.start_date).local();
        const Date = this.isMobile ? 'localDateShort' : 'localDateShort';
        return Time.isSame(moment(), 'day') ? 'localTime' : Date;
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public activeSubTab(tab: SubTabs): void {
        this.subTab = tab;
    }

    public get isActiveOddsTab(): boolean {
        return this.subTab === 'odds';
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_mymatches').subscribe((): void => {
            const Client = this.client.getVName();
            this.setTitle(
                {
                    title: `${this.translate.instant('web.title_mymatches')} | ${Client}`,
                    description: this.translate.instant('web.description_mymatches'),
                },
                true,
            );
        });
    }

    /**
     * Check if in respnse ate no data
     * @return [description]
     */
    public noData(): boolean {
        return (
            this.loaded &&
            this.data.live.length === 0 &&
            this.data.all.length === 0 &&
            this.data.finished.length === 0 &&
            this.data.not_started!.length === 0
        );
    }

    public getData(type: string = 'all'): any[] | Record<string, any> {
        if (type !== 'all' && has(this.data, type)) {
            // @ts-ignore
            return this.normalize(this.data[type]);
        }

        return this.normalize([...this.data.live, ...this.data.all, ...this.data.not_started!, ...this.data.finished]);
    }

    /**
     * Empty match favorites
     */
    public emptyMyMatches(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.favorite.emptyMatchFavorites().then(async (): Promise<void> => {
                this.user.setMyMatches().subscribe((): void => {});
                await this.load();
                resolve();
            });
        });
    }

    /**
     * Check if object is empty
     * @param  {object} data
     * @return {boolean}
     */
    public isEmpty(data: Record<string, any>): boolean {
        return isEmpty(data);
    }

    /**
     * Event fired when some match is selected/clicked
     * @event ParticipantComponent#loadMatch
     * @param  match
     * @return {void}
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public loadMatch(match: any): void {
        if (this.matchId != null && this.matchId === match.id) {
            return;
        }

        this.matchState = match.match_state;
        this.toggleDetail();
        this.matchId = match.id;
        this.tracker = false;
        if (has(match, 'tracker')) {
            this.tracker = match.tracker as boolean;
        }
        if (has(match, 'stream')) {
            this.stream = match.stream as boolean;
        }
    }

    private toggleDetail(active: boolean = true): void {
        this.parentClass['col-lg-8'] = active;
        this.parentClass['col-lg-12'] = !active;
        this.matchClass['d-lg-block'] = active;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Toggle aside menu
     * @return {void}
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    public filtrAlpha(data: any): Promise<string[]> {
        return new Promise((resolve): void => {
            const Ret: string[] = [];

            data.finished.forEach((v: Record<string, any>): void => {
                Ret.push(v.category_name.charAt(0).toLowerCase());
            });
            data.live.forEach((v: Record<string, any>): void => {
                Ret.push(v.category_name.charAt(0).toLowerCase());
            });
            data.not_started.forEach((v: Record<string, any>): void => {
                Ret.push(v.category_name.charAt(0).toLowerCase());
            });

            resolve(Ret);
        });
    }

    /**
     * Open default match on first load
     * @return {void}
     */
    private openDefault(): void {
        if (this.deviceService.isMobile()) {
            return;
        }
        const Data: any = [...this.data.live, ...this.data.not_started!, ...this.data.finished];
        if (Data.length > 0) {
            Data[0].tracker = null;
            this.loadMatch(Data[0]);
        }
    }

    protected async load(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.tabVisible) {
                this.matches.get(this.favorite.getMatchFavorites()).then((subscribe) => {
                    this.subscriber = subscribe.pipe(
                        tap((val): void => {
                            const Data = [...val.live, ...val.not_started!, ...val.finished, ...val.all];
                            const ServerIds = Data.map((item): number => item.id);
                            const Storage = this.favorite.getMatchFavorites();
                            if (ServerIds.length !== Storage.length) {
                                this.favorite.saveM(ServerIds);
                            }
                        }),
                    ).subscribe((val): void => {
                        this.data = val;
                        this.filtrAlpha(val).then((data): void => {
                            this.alphaAvailLetters = data;
                        });
                        this.dataAll = this.getData('all');
                        this.dataLive = this.getData('live');
                        this.dataFinished = this.getData('finished');
                        this.dataNotStarted = this.getData('not_started');
                        this.finished();


                        if (this.isEmpty(this.dataAll)) {
                            this.toggleDetail(false);
                        }

                        if (this.first) {
                            this.openDefault();
                        }

                        this.first = false;
                        resolve(true);
                    }, () => {
                        if (this.first) {
                            this.error();
                        }
                        reject();
                    });
                }).catch(() => {
                    if (this.first) {
                        this.error();
                    }
                    reject();
                });
            } else {
                resolve(true);
            }
        });
    }

    /**
     * Normalize data to sub levels
     * @return {object}
     */
    private normalize(data: any[]): Record<string, any> {
        const NormalizeData = {};
        const Grouped = groupBy(data, (val): string => val.sport_code_name);

        each(Grouped, (val): void => {
            // @ts-ignore
            NormalizeData[val[0].sport_code_name] = groupBy(val, 'sub_tournament_id');
        });

        return NormalizeData;
    }
}
