<app-header [fullMenu]="false"></app-header>

<main role="main">
    <div class="container p-2 p-md-4 bg-light position-relative">
        <div class="row">
            <div class="col-md-10 order-2">
                <router-outlet></router-outlet>
            </div>
            <div class="col-md-2 order-1 col--user">
                <div class="text-center mb-5" *ngIf="user$ | async as user">
                    <div class="avatar">
                        <img src="https://www.gravatar.com/avatar/{{ user.gravatar }}" class="rounded-circle" />
                    </div>
                    <div class="font-weight-bold pt-2 text-uppercase h6 icon-wrapper">
                        <span>{{ user.email }}</span>
                    </div>
                    <p>
                        <a (click)="signOff()" class="nav-link text-uppercase"
                            ><svg class="icon icon--20 mr-3"><use href="#icon-logout"></use></svg
                            >{{ 'web.sign_out' | translate }}</a
                        >
                    </p>
                </div>
                <nav class="nav flex-column nav--user d-none d-md-flex">
                    <a routerLink="/page/account/dashboard" class="nav-link text-uppercase"
                        ><svg class="icon icon--20 mr-3"><use href="#icon-user"></use></svg
                        >{{ 'web.dashboard' | translate }}</a
                    >
                    <a routerLink="/" class="nav-link text-uppercase"
                        ><svg class="icon icon--20 mr-3"><use href="#icon-soccer"></use></svg
                        >{{ 'web.homepage' | translate }}</a
                    >
                    <a routerLink="/page/account/favorite" class="nav-link text-uppercase"
                        ><svg class="icon icon--20 mr-3"><use href="#icon-heart"></use></svg
                        >{{ 'web.favorite' | translate }}</a
                    >
                    <a (click)="signOff()" class="nav-link text-uppercase app-cursor"
                        ><svg class="icon icon--20 mr-3"><use href="#icon-logout"></use></svg
                        >{{ 'web.sign_out' | translate }}</a
                    >
                    <!-- <a href="#" class="nav-link text-uppercase"><svg class="icon icon--20 mr-3"><use href="#icon-gear"></use></svg>Nastavení účtu</a>
                  <a href="#" class="nav-link text-uppercase"><svg class="icon icon--20 mr-3"><use href="#icon-money"></use></svg>Moje tipy</a>
                  <a class="nav-link text-uppercase" data-toggle="collapse" data-target="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"><svg class="icon icon--20 mr-3"><use href="#icon-settings"></use></svg>Další nastavení</a>
                  <div class="collapse nav--user--collapse" id="collapseExample">
                    <a class="nav-link" href="#">Výchozí sport po spuštění</a>
                    <a class="nav-link" href="#">Upravit pořadí sportů v menu</a>
                    <a class="nav-link" href="#">Notifikace</a>
                    <a class="nav-link" href="#">Jazyk</a>
                  </div>
                  <a href="#" class="nav-link text-uppercase"><svg class="icon icon--20 mr-3"><use href="#icon-logout"></use></svg>Odhlášení</a> -->
                </nav>
            </div>
        </div>
    </div>
</main>

<app-footer></app-footer>
