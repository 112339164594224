import { Component, Input } from '@angular/core';

import { TennisTournamentInfoInterface } from '../../interfaces/tennis-tournament-info.interface';

@Component({
    selector: 'app-tennis-tournament-info',
    templateUrl: './tennis-tournament-info.component.html',
})
export class TennisTournamentInfoComponent {
    @Input()
    public tennisTournamentInfo: TennisTournamentInfoInterface | undefined;

    @Input()
    iso?: string;

    public constructor() {}


    get country(): false | string {
        if (this.iso) {
            let { iso } = this;
            if (iso.length > 2) {
                iso = iso.substring(0, 2);
            }

            return iso.toLowerCase();
        }

        return false;
    }
}
