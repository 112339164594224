/* eslint-disable */
import { Component, OnInit } from "@angular/core";
import { ClientService } from "src/app/services/client.service";
import { LangService } from "src/app/services/lang.service";
import KEYS_CONFIG from "src/app/config/keys";
import APP_CONFIG from "src/app/config/app.config";

import { UserService } from "src/app/services/user.service";
import { StorageService } from "src/app/services/storage.service";
import { take } from "rxjs/operators";
import { interval } from "rxjs";


@Component({
    selector: "app-push-notification",
    templateUrl: "./push-notification.component.html",
    styleUrls: ["./notify.scss"],
})
export class PushNotificationComponent implements OnInit {
    public active: boolean = false;

    public loaded: boolean = false;

    public showYes: boolean = true;

    public showNo: boolean = true;

    public showError: boolean = true;

    public constructor(
        public client: ClientService,
        private lang: LangService,
        private user: UserService,
        private storage: StorageService,
    ) {}

    public ngOnInit(): void {

        // console.log("Notification", Notification.permission);

        interval(5000)
            .pipe(take(1))
            .subscribe((): void => {
                this.ntfStart();
              
            });
     
        this.user.onNtf.subscribe((type): void => {
            if (
                type === "active" &&
                !Notification.permission.includes("denied")
            ) {
                this.active = true;
            }
        });
        this.lang.onLangLoaded.subscribe((): any => {
            this.loaded = true;

        });

        
    }

    public ntfStart() {

        let ntf = this.storage.get<string>(APP_CONFIG.swNTFkey) || null;
        if (
            ntf !== "no" &&
            "Notification" in window &&
            navigator.serviceWorker
        ) {
            navigator.serviceWorker.ready.then((reg): void => {
                if (Notification.permission === "granted") {
                    this.subscribeUser();
                } else if (
                    Notification.permission.includes("blocked") ||
                    Notification.permission.includes("denied")
                ) {
                    /* the user has previously denied push. Can't reprompt. */
                } else {
                    setTimeout((): void => {
                        this.active = true;
                    }, 1000);
                }
            });
        }
    }

    public yes(): void {
        this.active = false;
        this.storage.set(APP_CONFIG.swNTFkey, "yes");
        this.requestNotification();
    }

    public no(): void {
        this.active = false;
        this.showNo = false;
        this.storage.set(APP_CONFIG.swNTFkey, "no");
        setTimeout((): void => {
            this.showNo = true;
        }, 3000);
    }

    public ntfError(): void {
        this.showError = false;
        setTimeout((): void => {
            this.showError = true;
        }, 6000);
    }

    private requestNotification(): void {
        Notification.requestPermission((status): void => {
            //console.log("Notification permission status:", status);
            if (Notification.permission === "granted") {
                this.subscribeUser();
            }
        });
    }

    private async subscribeUser(): Promise<any> {
        if ("serviceWorker" in navigator) {
            const token = await this.user.createUUID();

            navigator.serviceWorker.ready.then((reg): void => {
                reg.pushManager
                    .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: this.urlBase64ToUint8Array(
                            KEYS_CONFIG.vapid.public
                        ),
                    })
                    .then((sub): void => {
                        //console.log("Endpoint URL: ", sub);
                        const subEncoded = JSON.parse(JSON.stringify(sub));
                        this.user.vapid(subEncoded).subscribe(() => {
                            this.user.setMyMatches().subscribe((): void => {
                                //console.log("User MyMatches updated !!");
                                //this.displayNotification();
                            });
                            //console.log("Endpoint and user updated !!");
                        });
                    })
                    .catch((e): void => {
                        this.ntfError();
                        if (Notification.permission === "denied") {
                            // console.warn(
                            //     "Permission for notifications was denied"
                            // );
                        } else {
                            //console.log("Unable to subscribe to push", e);
                        }
                    });
            });
        }
    }

    private urlBase64ToUint8Array(base64String: string): any {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, "+")
            .replace(/_/g, "/");

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; i += 1) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    public displayNotification(): void {
        if (Notification.permission === "granted") {
            navigator.serviceWorker.getRegistration().then((reg): void => {
                const options = {
                    body: "Here is a notification body!",
                    icon: "/img/client/24live/icons/apple-touch-icon.png",
                    vibrate: [100, 50, 100],
                    data: {
                        dateOfArrival: Date.now(),
                        primaryKey: 1,
                    },
                };
                reg!.showNotification("Hello world!", options);
            });
        }
    }
}
