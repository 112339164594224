import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CategoryInterface } from 'src/app/interfaces/category.interface';
import { StorageService } from 'src/app/services/storage.service';
import * as _ from 'underscore';

@Component({
    selector: 'app-category-group',
    templateUrl: './category-group.component.html',
    styleUrls: ['./category-group.component.scss'],
})
export class CategoryGroupComponent implements OnInit {
    @Input()
    public group: CategoryInterface;

    @Output()
    public groupChange: EventEmitter<CategoryInterface> = new EventEmitter();

    public extraCss: string[] = [];

    public constructor(private storage: StorageService) {}

    public ngOnInit(): void {
        if (this.group.gender === 1) {
            this.extraCss.push('tennis-group');
            this.extraCss.push('tennis-group-man');
        } else if (this.group.gender === 2) {
            this.extraCss.push('tennis-group');
            this.extraCss.push('tennis-group-ladies');
        } else {
            this.extraCss.push('tennis-group');
            this.extraCss.push('tennis-group-uni');
        }
    }

    /**
     * Toggle open state
     * @param  cg  - category
     * @return {void}
     */
    public open(group: CategoryInterface): void {
        window.requestAnimationFrame((): void => {
            group.isopen = !group.isopen;

            let saved = this.storage.get<number[]>('APP_GROUP_MATCHES');

            if (!_.isArray(saved)) {
                saved = [];
            }
            const index = saved.findIndex(
                (val): boolean => val === group.category_id,
            );

            if (group.isopen && index === -1) {
                saved.push(group.category_id);
            } else if (!group.isopen && index !== -1) {
                saved.splice(index, 1);
            }

            this.storage.set('APP_GROUP_MATCHES', saved);

            this.groupChange.emit(group);
        });
    }
}
