import { ChangeDetectorRef, Component } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { AdsService } from '@services/ads.service';

import { AdsBase } from '../ads.base';


@Component({
    selector: 'app-ads[mobile-layer]',
    templateUrl: './mobile-layer.component.html',
    styleUrls: ['./mobile-layer.component.scss'],
})
export class AdsMobileLayerComponent extends AdsBase {
    public wasClosed = false;

    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef) {
        super(deviceService, ref);
    }

    /**
     * Dismiss banner
     */
    public close(): void {
        this.ads = null;
        this.wasClosed = true;
        AdsService.mobileLayerClosed = true;
    }

    get isClosed(): boolean {
        return this.wasClosed || AdsService.mobileLayerClosed;
    }
}
