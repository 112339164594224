<div class="d-flex participant-stats participant-info flex-wrap mt-md-3" *ngIf="participant">
    <div class="d-flex flex-column participant-info__box">
        <div><i class="fas fa-users fa-2x"></i></div>
        <div>{{ totalPlayers }} {{ 'web.total_players' | translate }}</div>
    </div>
    <div class="d-flex flex-column participant-info__box">
        <div><i class="fas fa-universal-access fa-2x"></i></div>
        <div>{{ avgAge }} {{ 'web.avg_age' | translate }}</div>
    </div>
    <div class="d-flex flex-column participant-info__box">
        <div class="d-flex align-items-end">
            <div class="d-flex w-100 participant-info__box__item">
                <div
                    class="--no-min-width --bar --bar--win --bar--small text-center"
                    [style.width.%]="foreignPlayersPrct"
                >
                    &nbsp;
                </div>
                <div
                    class="--no-min-width --bar --bar--draw --bar--small text-center"
                    [style.width.%]="foreignPlayersRestPrct"
                >
                    &nbsp;
                </div>
            </div>
        </div>
        <div>{{ foreignPlayers }} {{ 'web.foreign_players' | translate }}</div>
    </div>
    <div class="d-flex flex-column participant-info__box">
        <div class="d-flex align-items-end">
            <div class="d-flex w-100 participant-info__box__item">
                <div
                    class="--no-min-width --bar --bar--win --bar--small text-center"
                    [style.width.%]="nationalPlayersPrct"
                >
                    &nbsp;
                </div>
                <div
                    class="--no-min-width --bar --bar--draw --bar--small text-center"
                    [style.width.%]="nationalPlayersRestPrct"
                >
                    &nbsp;
                </div>
            </div>
        </div>
        <div>{{ nationalPlayers }} {{ 'web.national_players' | translate }}</div>
    </div>

    <div *ngIf="isTransferInfo" class="d-flex flex-column participant-info__box2 --in">
        <div class="participant-info__box2__ico">
            <a
                class=""
                matTooltip="{{ 'web.participant_player_in' | translate }}"
                matTooltipPosition="right"
                matTooltipHideDelay="500"
                data-cy="participant-info-in"
                aria-label="Participant players in"
            >
                <i class="fas fa-arrow-down"></i>
            </a>
        </div>
        <div
            class="participant-info__box2__name"
            *ngFor="let player of participant.transfers_to | slice : 0 : 4; trackBy: trackById"
        >
            <a [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.player_id]" class="text-reset">
                {{ player.player_name }}
            </a>
        </div>
    </div>
    <div *ngIf="isTransferInfo" class="d-flex flex-column participant-info__box2 --out">
        <div class="participant-info__box2__ico">
            <a
                class=""
                matTooltip="{{ 'web.participant_player_out' | translate }}"
                matTooltipPosition="right"
                matTooltipHideDelay="500"
                data-cy="participant-info-in"
                aria-label="Participant players out"
            >
                <i class="fas fa-arrow-up"></i>
            </a>

            <div
                class="participant-info__box2__name"
                *ngFor="let player of participant.transfers_from | slice : 0 : 4; trackBy: trackById"
            >
                <a
                    [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.player_id]"
                    class="text-reset"
                >
                    {{ player.player_name }}
                </a>
            </div>
        </div>
    </div>
</div>
