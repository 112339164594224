/* eslint-disable camelcase */
import { ParticipantInterface } from '@interfaces/participant.interface';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-participant-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParticipantInfoComponent {
    @Input()
    public participant: ParticipantInterface | null;

    @Input()
    sportId: number;


    @Input()
    sportName: string;

    public constructor(private translate: TranslateService) {
        this.translate.get('web.participant_form_win').subscribe((): void => {

        });
    }


    get totalPlayers(): number {
        return this.participant?.players?.filter(p => p.role !== 'manager').length ?? 0;
    }

    get avgAge(): number | null {
        const total: number = this.participant?.players?.filter(p => p.role !== 'manager')
        .reduce((accumulator: any, currentValue: any): any => {
            if (!currentValue.age) {
                return +accumulator;
            }
            return +accumulator + +currentValue.age;
        }, 0);

        if (!total) {
            return null;
        }

        return Math.floor(total / this.totalPlayers ?? 0);
    }

    get foreignPlayers(): number {
        return this.participant?.international_players ?? 0;
    }

    get nationalPlayers(): number {
        return this.participant?.national_team_players ?? 0;
    }

    get isTransferInfo(): boolean {
        return !!(this.participant?.transfers_from && this.participant.transfers_to &&
            (this.participant?.transfers_from.length > 0 || this.participant.transfers_to.length > 0));
    }

    get foreignPlayersPrct():number {
        return Math.floor(this.foreignPlayers / this.totalPlayers * 100);
    }

    get foreignPlayersRestPrct():number {
        return 100 - this.foreignPlayersPrct;
    }

    get nationalPlayersPrct():number {
        return Math.floor(this.nationalPlayers / this.totalPlayers * 100);
    }

    get nationalPlayersRestPrct():number {
        return 100 - this.nationalPlayersPrct;
    }

    public trackById(_index: any, item: any): number {
        return item.id;
    }
}
