<div class="container bg-light position-relative">
    <div class="row bg-white">
        <div class="col-lg-8 bg-white p-0" [ngClass]="parentClass">
            <div class="nav-wrapper app-mob-nav-wrapper" *ngIf="!isEmpty(dataAll)">
                <ul
                    class="nav nav-tabs nav-tabs--shadows nav-justified--mobile font-weight-bold"
                    id="tabs-left"
                    role="tablist"
                >
                    <li class="nav-item">
                        <a
                            class="nav-link px-3 px-md-4 active"
                            id="tab1"
                            data-toggle="tab"
                            (click)="activeSubTab('all')"
                            href="#my-matchesoverview"
                            role="tab"
                            aria-controls="my-matchesoverview"
                            aria-selected="true"
                            >{{ 'web.overview' | translate | transinit }}</a
                        >
                    </li>
                    <li class="nav-item" *ngIf="isMobile && partner.getCount() > 0">
                        <a
                            class="nav-link px-3 px-md-4"
                            id="tab6"
                            data-toggle="tab"
                            href="#my-matchesoverview"
                            (click)="activeSubTab('odds')"
                            role="tab"
                            aria-controls="#my-odds"
                            aria-selected="true"
                            >{{ 'web.tab_odds' | translate | transinit }}</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link px-3 px-md-4 app-live-tab"
                            id="tab2"
                            data-toggle="tab"
                            (click)="activeSubTab('all')"
                            href="#my-matcheslive"
                            role="tab"
                            aria-controls="my-matcheslive"
                            aria-selected="false"
                            >{{ 'web.live_matches' | translate | transinit }}</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link px-3 px-md-4"
                            id="tab3"
                            data-toggle="tab"
                            (click)="activeSubTab('all')"
                            href="#my-matchesresults"
                            role="tab"
                            aria-controls="my-matchesresults"
                            aria-selected="false"
                            >{{ 'web.finished_matches' | translate | transinit }}</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link px-3 px-md-4"
                            id="tab4"
                            data-toggle="tab"
                            (click)="activeSubTab('all')"
                            href="#my-matchesfuture"
                            role="tab"
                            aria-controls="my-matchesfuture"
                            aria-selected="false"
                            >{{ 'web.future_matches' | translate | transinit }}</a
                        >
                    </li>

                    <li class="nav-item ml-auto app-mob-calendar" *ngIf="!isMobile">
                        <a
                            class="nav-link bg-white px-3"
                            id="tab5"
                            (click)="emptyMyMatches()"
                            data-toggle="tab"
                            data-cy="my-matches-trash"
                            href="#tab5-content"
                            role="tab"
                            aria-controls="tab5-content"
                            aria-selected="false"
                        >
                            <svg class="icon icon--16 mr-2"><use xlink:href="#icon-trash" /></svg>
                            <span class="d-none d-sm-inline">{{ 'web.empty_all' | translate | transinit }}</span>
                            <span class="d-sm-none">{{ 'web.empty_all_short' | translate | transinit }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="d-md-none dropdown-calendar" *ngIf="isMobile && !isEmpty(dataAll)">
                <a class="nav-link bg-white px-3 text-capitalize text-center" (click)="emptyMyMatches()">
                    <svg class="icon icon--16 mr-2"><use xlink:href="#icon-trash" /></svg>
                    <span class="d-none d-sm-inline">{{ 'web.empty_all' | translate | transinit }}</span>
                    <span class="d-sm-none">{{ 'web.empty_all_short' | translate | transinit }}</span>
                </a>
            </div>
            <div
                *ngIf="loaded; else elseBlock"
                class="tab-content bg-white px-3 pb-3 pt-sm-3 has-nomenclature position-relative"
            >
                <ng-container *ngIf="loadedError; else loadBlock">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use xlink:href="#icon-empty" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                        </div>
                    </div>
                </ng-container>
                <ng-template #loadBlock>
                    <div class="tab-pane active" id="my-matchesoverview" role="tabpanel" aria-labelledby="tab1">
                        <ng-container *ngFor="let sport of dataAll | keyvalue | sortBySport; trackBy: trackByKey">
                            <div class="table-sport mx-n3 mx-sm-0">
                                <h6
                                    class="table-sport__head bg-dark text-white"
                                    data-toggle="collapse"
                                    [attr.href]="'#collapse-' + sport.key"
                                    role="button"
                                    aria-expanded="true"
                                    aria-controls="collapse-tennis"
                                >
                                    <!-- <img src="img/sports/tennis.png" alt="Tennis" width="20" class="mr-1"> -->

                                    <span class="table-sport__head__text"> {{ 'sport.' + sport.key | translate }}</span>
                                </h6>
                                <div class="table-sport__content collapse show" id="collapse-{{ sport.key }}">
                                    <ng-container
                                        *ngFor="
                                            let category of sport.value | keyvalue | navSearch : 'alpha' : alphaFilter;
                                            trackBy: trackByKey
                                        "
                                    >
                                        <div
                                            class="table-sport__subhead d-flex align-items-center bg-light py-0"
                                            data-toggle="collapse"
                                            [attr.href]="
                                                '#collapse-' + sport.key + '-' + category.value[0].sub_tournament_id
                                            "
                                            role="button"
                                            aria-expanded="true"
                                            ria-controls="collapse-tennis-1"
                                        >
                                            <img
                                                *ngIf="
                                                    isMobile &&
                                                        category.value[0].tournament_country_iso &&
                                                        sport.key === 'tennis';
                                                    else adminIcon
                                                "
                                                src="{{
                                                    cdn +
                                                        '/img/flags/1x1/' +
                                                        (category.value[0].tournament_country_iso | lowercase) +
                                                        '.svg'
                                                }}"
                                                width="20"
                                                class="mr-2 rounded-circle"
                                                alt="{{ category.value[0].tournament_country_iso }}"
                                            />
                                            <ng-template #adminIcon>
                                                <img
                                                    src="{{ cdn + category.value[0].image }}"
                                                    width="16"
                                                    class="mr-2 rounded-circle"
                                                    alt=""
                                                />
                                            </ng-template>

                                            <div
                                                class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1"
                                            >
                                                <ng-container *ngIf="isMobile">
                                                    <ng-container
                                                        *ngIf="
                                                            sport.key !== 'tennis' ||
                                                                !category.value[0].sub_tournament_city;
                                                            else tennis
                                                        "
                                                    >
                                                        {{ category.value[0].sub_tournament_name }}
                                                        <div class="text-muted mt-1">
                                                            {{ category.value[0].category_name }}
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #tennis>
                                                        {{ category.value[0].sub_tournament_city }} |
                                                        {{
                                                            category.value[0].tournament_country ||
                                                                category.value[0].tournament_country_iso3 ||
                                                                category.value[0].tournament_country_iso
                                                        }}
                                                        <div class="text-muted mt-1">
                                                            {{ category.value[0].category_name }} -
                                                            <ng-container *ngIf="category.value[0].singles_doubles"
                                                                ><ng-container
                                                                    *ngIf="category.value[0].singles_doubles === 1"
                                                                    >{{ 'web.singles' | translate }}</ng-container
                                                                ><ng-container
                                                                    *ngIf="category.value[0].singles_doubles === 2"
                                                                    >{{ 'web.doubles' | translate }}</ng-container
                                                                ></ng-container
                                                            ><ng-container
                                                                *ngIf="
                                                                    category.value[0].sub_tournament_qualifying === 1
                                                                "
                                                                >{{ 'web.qualifying' | translate }}</ng-container
                                                            ><ng-container
                                                                *ngIf="category.value[0].sub_tournament_ground"
                                                                >,
                                                                {{
                                                                    category.value[0].sub_tournament_ground
                                                                        | translate
                                                                        | lowercase
                                                                }}</ng-container
                                                            >
                                                        </div>
                                                    </ng-template>
                                                </ng-container>

                                                <ng-container *ngIf="!isMobile">
                                                    <span
                                                        class="d-none d-sm-inline"
                                                        *ngIf="!isMobile || category.value[0].showCategory"
                                                        >{{ category.value[0].category_name }} |
                                                    </span>
                                                    <span
                                                        class="d-sm-none"
                                                        *ngIf="!isMobile || category.value[0].showCategory"
                                                        >{{ category.value[0].category_name_short }} |
                                                    </span>
                                                    <ng-container
                                                        *ngIf="
                                                            sport.key !== 'tennis' ||
                                                                !category.value[0].sub_tournament_city;
                                                            else tennis
                                                        "
                                                    >
                                                        {{ category.value[0].sub_tournament_name }}
                                                    </ng-container>
                                                    <ng-template #tennis>
                                                        {{ category.value[0].sub_tournament_city }} ({{
                                                            category.value[0].tournament_country_iso3 ||
                                                                category.value[0].tournament_country_iso
                                                        }})<ng-container
                                                            *ngIf="category.value[0].sub_tournament_qualifying === 1"
                                                        >
                                                            {{ 'web.qualifying' | translate }}</ng-container
                                                        >,
                                                        {{
                                                            category.value[0].sub_tournament_ground
                                                                | translate
                                                                | lowercase
                                                        }}
                                                    </ng-template>
                                                </ng-container>
                                            </div>

                                            <a
                                                *ngIf="isMobile"
                                                class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                            >
                                                <svg class="icon icon--14 icon--gray mb-1">
                                                    <use xlink:href="#icon-info"></use>
                                                </svg>
                                                {{ 'web.detail' | translate | transinit }}
                                            </a>

                                            <a
                                                *ngIf="!isMobile"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                                class="ml-auto small text-reset"
                                                ><svg class="icon icon--12 mr-2"><use xlink:href="#icon-table" /></svg>
                                                {{ 'web.detail' | translate }}</a
                                            >
                                            <!-- <svg class="icon icon--12 icon--primary ml-2"><use href="#icon-star-fill"/></svg> -->
                                        </div>

                                        <div
                                            class="collapse show"
                                            id="collapse-{{ sport.key }}-{{ category.value[0].sub_tournament_id }}"
                                        >
                                            <app-component-match
                                                *ngFor="let match of category.value; trackBy: trackById"
                                                [dateFormat]="dateFormat(match)"
                                                [dateFormat2]="dateFormat2(match)"
                                                [attr.data-cyid]="match.id"
                                                [match]="match"
                                                [showFavorite]="true"
                                                [displayOdds]="isActiveOddsTab"
                                                [sportName]="match.sport_code_name"
                                                [sportId]="match.sport_id"
                                                (onSelect)="loadMatch($event)"
                                            >
                                            </app-component-match>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="isEmpty(dataAll)">
                            <div class="row mt-6">
                                <!-- <div class="col-12 order-md-2 text-center">
                                    <h3 class="font-weight-bold">{{ 'web.no_match_found' | translate }}</h3>
                                    <p class="mb-6">{{ 'web.we_are_sorry' | translate }}</p>
                                </div> -->
                                <div
                                    class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-12 offset-md-0 order-md-1 text-center text-md-left"
                                >
                                    <div class="row mb-6 align-items-center">
                                        <div class="col-md-3 offset-md-3 col-lg-2 offset-lg-4">
                                            <img
                                                src="/img/24live_search.png"
                                                alt=""
                                                class="img-fluid add-to-favorite-img"
                                            />
                                        </div>
                                        <div class="col-md-4 col-xl-3">
                                            <h2 class="h4 mt-5 font-weight-bold">
                                                {{ 'web.no_data_found_add_new' | translate }}
                                            </h2>
                                            <p>{{ 'web.no_data_found_add_new2' | translate }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-alpha-filter
                            *ngIf="!isEmpty(dataAll)"
                            class="d-none d-lg-block"
                            [availLetters]="alphaAvailLetters"
                            (onDataChange)="alphaFilter = $event"
                        ></app-alpha-filter>
                    </div>
                    <div class="tab-pane" id="my-matcheslive" role="tabpanel" aria-labelledby="tab2">
                        <ng-container *ngFor="let sport of dataLive | keyvalue | sortBySport; trackBy: trackByKey">
                            <div class="table-sport mx-n3 mx-sm-0">
                                <h6
                                    class="table-sport__head bg-dark text-white"
                                    data-toggle="collapse"
                                    [attr.href]="'#collapse-' + sport.key"
                                    role="button"
                                    aria-expanded="true"
                                    aria-controls="collapse-tennis"
                                >
                                    <!-- <img src="img/sports/tennis.png" alt="Tennis" width="20" class="mr-1"> -->

                                    <span class="table-sport__head__text"> {{ 'sport.' + sport.key | translate }}</span>
                                </h6>
                                <div class="table-sport__content collapse show" id="collapse-{{ sport.key }}">
                                    <ng-container *ngFor="let category of sport.value | keyvalue; trackBy: trackByKey">
                                        <div
                                            class="table-sport__subhead d-flex align-items-center bg-light py-2"
                                            data-toggle="collapse"
                                            [attr.href]="
                                                '#collapse-' + sport.key + '-' + category.value[0].sub_tournament_id
                                            "
                                            role="button"
                                            aria-expanded="true"
                                            ria-controls="collapse-tennis-1"
                                        >
                                            <img
                                                src="{{ category.value[0].image }}"
                                                width="16"
                                                class="mr-2 rounded-circle"
                                                alt="{{ category.value[0].category_name }}  {{
                                                    category.value[0].sub_tournament_name
                                                }}"
                                            />

                                            <div
                                                class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1"
                                            >
                                                <span *ngIf="!isMobile || category.value[0].showCategory"
                                                    >{{ category.value[0].category_name }} |</span
                                                >
                                                {{ category.value[0].sub_tournament_name }}
                                            </div>

                                            <a
                                                *ngIf="isMobile"
                                                class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                            >
                                                <svg class="icon icon--14 icon--gray mb-1">
                                                    <use xlink:href="#icon-info"></use>
                                                </svg>
                                                {{ 'web.detail' | translate | transinit }}
                                            </a>

                                            <a
                                                *ngIf="!isMobile"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                                class="ml-auto small text-reset"
                                                ><svg class="icon icon--12 mr-2"><use xlink:href="#icon-table" /></svg>
                                                {{ 'web.detail' | translate }}</a
                                            >
                                            <!-- <svg class="icon icon--12 icon--primary ml-2"><use href="#icon-star-fill"/></svg> -->
                                        </div>
                                        <div
                                            class="collapse show"
                                            id="collapse-{{ sport.key }}-{{ category.value[0].sub_tournament_id }}"
                                        >
                                            <app-component-match
                                                *ngFor="let match of category.value; trackBy: trackById"
                                                [dateFormat]="dateFormat(match)"
                                                [dateFormat2]="dateFormat2(match)"
                                                [match]="match"
                                                [showFavorite]="true"
                                                [displayOdds]="isActiveOddsTab"
                                                [sportName]="match.sport_code_name"
                                                [sportId]="match.sport_id"
                                                (onSelect)="loadMatch($event)"
                                            >
                                            </app-component-match>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="isEmpty(dataLive)">
                            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                <div class="text-center pt-7">
                                    <svg class="icon icon--64 mb-4"><use xlink:href="#icon-empty" /></svg>
                                    <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                    <p class="mb-6">{{ 'web.no_live_data_found' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="my-matchesresults" role="tabpanel" aria-labelledby="tab3">
                        <ng-container *ngFor="let sport of dataFinished | keyvalue | sortBySport; trackBy: trackByKey">
                            <div class="table-sport mx-n3 mx-sm-0">
                                <h6
                                    class="table-sport__head bg-dark text-white"
                                    data-toggle="collapse"
                                    [attr.href]="'#collapse-' + sport.key"
                                    role="button"
                                    aria-expanded="true"
                                    aria-controls="collapse-tennis"
                                >
                                    <!-- <img src="img/sports/tennis.png" alt="Tennis" width="20" class="mr-1"> -->

                                    <span class="table-sport__head__text"> {{ 'sport.' + sport.key | translate }}</span>
                                </h6>
                                <div class="table-sport__content collapse show" id="collapse-{{ sport.key }}">
                                    <ng-container *ngFor="let category of sport.value | keyvalue; trackBy: trackByKey">
                                        <div
                                            class="table-sport__subhead d-flex align-items-center bg-light py-2"
                                            data-toggle="collapse"
                                            [attr.href]="
                                                '#collapse-' + sport.key + '-' + category.value[0].sub_tournament_id
                                            "
                                            role="button"
                                            aria-expanded="true"
                                            ria-controls="collapse-tennis-1"
                                        >
                                            <img
                                                src="{{ category.value[0].image }}"
                                                width="16"
                                                class="mr-2 rounded-circle"
                                                alt="{{ category.value[0].category_name }}  {{
                                                    category.value[0].sub_tournament_name
                                                }}"
                                            />

                                            <div
                                                class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1"
                                            >
                                                <span *ngIf="!isMobile || category.value[0].showCategory"
                                                    >{{ category.value[0].category_name }} |</span
                                                >
                                                {{ category.value[0].sub_tournament_name }}
                                            </div>

                                            <a
                                                *ngIf="isMobile"
                                                class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                            >
                                                <svg class="icon icon--14 icon--gray mb-1">
                                                    <use xlink:href="#icon-info"></use>
                                                </svg>
                                                {{ 'web.detail' | translate | transinit }}
                                            </a>

                                            <a
                                                *ngIf="!isMobile"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                                class="ml-auto small text-reset"
                                                ><svg class="icon icon--12 mr-2"><use xlink:href="#icon-table" /></svg>
                                                {{ 'web.detail' | translate }}</a
                                            >
                                            <!-- <svg class="icon icon--12 icon--primary ml-2"><use href="#icon-star-fill"/></svg> -->
                                        </div>
                                        <div
                                            class="collapse show"
                                            id="collapse-{{ sport.key }}-{{ category.value[0].sub_tournament_id }}"
                                        >
                                            <app-component-match
                                                *ngFor="let match of category.value; trackBy: trackById"
                                                [dateFormat]="dateFormat(match)"
                                                [dateFormat2]="dateFormat2(match)"
                                                [match]="match"
                                                [displayOdds]="isActiveOddsTab"
                                                [showFavorite]="true"
                                                [sportName]="match.sport_code_name"
                                                [sportId]="match.sport_id"
                                                (onSelect)="loadMatch($event)"
                                            >
                                            </app-component-match>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="isEmpty(dataFinished)">
                            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                <div class="text-center pt-7">
                                    <svg class="icon icon--64 mb-4"><use xlink:href="#icon-empty" /></svg>
                                    <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                    <p class="mb-6">{{ 'web.no_data_found_add_new' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="my-matchesfuture" role="tabpanel" aria-labelledby="tab4">
                        <ng-container
                            *ngFor="let sport of dataNotStarted | keyvalue | sortBySport; trackBy: trackByKey"
                        >
                            <div class="table-sport mx-n3 mx-sm-0">
                                <h6
                                    class="table-sport__head bg-dark text-white"
                                    data-toggle="collapse"
                                    [attr.href]="'#collapse-' + sport.key"
                                    role="button"
                                    aria-expanded="true"
                                    aria-controls="collapse-tennis"
                                >
                                    <!-- <img src="img/sports/tennis.png" alt="Tennis" width="20" class="mr-1"> -->

                                    <span class="table-sport__head__text"> {{ 'sport.' + sport.key | translate }}</span>
                                </h6>
                                <div class="table-sport__content collapse show" id="collapse-{{ sport.key }}">
                                    <ng-container *ngFor="let category of sport.value | keyvalue; trackBy: trackByKey">
                                        <div
                                            class="table-sport__subhead d-flex align-items-center bg-light py-2"
                                            data-toggle="collapse"
                                            [attr.href]="
                                                '#collapse-' + sport.key + '-' + category.value[0].sub_tournament_id
                                            "
                                            role="button"
                                            aria-expanded="true"
                                            ria-controls="collapse-tennis-1"
                                        >
                                            <img
                                                src="{{ category.value[0].image }}"
                                                width="16"
                                                class="mr-2 rounded-circle"
                                                alt="{{ category.value[0].category_name }}  {{
                                                    category.value[0].sub_tournament_name
                                                }}"
                                            />

                                            <div
                                                class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1"
                                            >
                                                <span *ngIf="!isMobile || category.value[0].showCategory"
                                                    >{{ category.value[0].category_name }} |</span
                                                >
                                                {{ category.value[0].sub_tournament_name }}
                                            </div>

                                            <a
                                                *ngIf="isMobile"
                                                class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                            >
                                                <svg class="icon icon--14 icon--gray mb-1">
                                                    <use xlink:href="#icon-info"></use>
                                                </svg>
                                                {{ 'web.detail' | translate | transinit }}
                                            </a>

                                            <a
                                                *ngIf="!isMobile"
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    category.value[0].sport_code_name +
                                                        '-' +
                                                        category.value[0].sport_id,
                                                    category.value[0].tournament_id
                                                ]"
                                                class="ml-auto small text-reset"
                                                ><svg class="icon icon--12 mr-2"><use xlink:href="#icon-table" /></svg>
                                                {{ 'web.detail' | translate }}</a
                                            >
                                            <!-- <svg class="icon icon--12 icon--primary ml-2"><use href="#icon-star-fill"/></svg> -->
                                        </div>
                                        <div
                                            class="collapse show"
                                            id="collapse-{{ sport.key }}-{{ category.value[0].sub_tournament_id }}"
                                        >
                                            <app-component-match
                                                *ngFor="let match of category.value; trackBy: trackById"
                                                [dateFormat]="dateFormat(match)"
                                                [dateFormat2]="dateFormat2(match)"
                                                [match]="match"
                                                [displayOdds]="isActiveOddsTab"
                                                [showFavorite]="true"
                                                [sportName]="match.sport_code_name"
                                                [sportId]="match.sport_id"
                                                (onSelect)="loadMatch($event)"
                                            >
                                            </app-component-match>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="isEmpty(dataNotStarted)">
                            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                <div class="text-center pt-7">
                                    <svg class="icon icon--64 mb-4"><use xlink:href="#icon-empty" /></svg>
                                    <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                    <p class="mb-6">{{ 'web.no_data_found_add_new' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <ng-template #elseBlock> <app-page-loader></app-page-loader> </ng-template>
        </div>
        <div class="col-lg-4 d-none d-lg-block" [ngClass]="matchClass">
            <app-switchers (onStickyChange)="onStickyChange($event)"></app-switchers>
            <div [class.sticky-parent]="stickyDetail" class="col-right__inner bg-white" data-cy="right-column-match">
                <app-match-detail
                    class="sticky-parent__match"
                    [style.max-height]="viewPortHeightMatchDetail"
                    [id]="matchId"
                    [tracker]="tracker"
                    [matchState]="matchState"
                    [stream]="stream"
                ></app-match-detail>
            </div>
        </div>
    </div>

    <aside class="nav-sports d-none d-xl-block" *ngIf="client.use('sportQuickNav')">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation type="aside" [limit]="asideLimit"></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web. less' | translate }}</span>
            </a>
        </nav>
    </aside>
</div>
