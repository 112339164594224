/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, OnInit, Output, EventEmitter, Input, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';


import { last } from 'lodash-es';

import { LangService } from 'src/app/services/lang.service';
import { TranslateService } from '@ngx-translate/core';

import { LiveDatePipe } from '@shared/pipes/live-datetime.pipe';


import { TimeService } from '../../services/time.service';

import { TranslationService } from '@/services/translation.service';
import { PageService } from '@/services/page.service';

declare const moment: any;

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.less'],

})
export class CalendarComponent implements OnInit, OnDestroy {
    @Input()
    public mobile: boolean = false;

    @Output()
    protected onChange: EventEmitter<any> = new EventEmitter();

    public form: FormGroup;


    public actualDate: any;

    public date: FormControl;
    public date2: FormControl;

    public latestDatesVal: {moment: any; format: string}[];

    private transVals: Record<'web.today' | 'web.tommorow', string>;

    public loaded: boolean = false;


    // eslint-disable-next-line no-useless-constructor
    public constructor(
        private route: ActivatedRoute,
        private page: PageService,
        public lang: LangService,
        private trans: TranslateService,
        private timePipe: LiveDatePipe,
        private ngZone: NgZone,
    ) {}

    public initCalendar(): void {
        this.page.initCalendar(this.actualDate, this.date);
    }

    public ngOnDestroy(): void {
        this.page.destroyCalendar();
    }

    public ngOnInit(): void {
        this.lang.onLangLoaded.subscribe(() => {
            this.trans.get(['web.today', 'web.tommorow']).subscribe((val) => {
                this.transVals = val;
                this.init();
                this.initCalendar();
            });
        });

        if (TranslationService.isLoaded) {
            this.trans.get(['web.today', 'web.tommorow']).subscribe((val) => {
                this.transVals = val;
                this.init();
            });
        }
    }

    public get actualDateFormated(): string {
        if (!this.lang.isArabic()) {
            this.actualDate.locale(this.lang.getLangSnapshot());
        }

        if (this.actualDate.isSame(moment(), 'day')) {
            return this.transVals?.['web.today'] ?? 'Today';
        }
        if (
            this.actualDate.isSame(
                moment()
                    .add(1, 'day')
                    .format(TimeService.dateFormat),
            )
        ) {
            return this.transVals?.['web.tommorow'] ?? 'Tommorow';
        }

        const date = this.timePipe.transform(this.actualDate, 'localDateShort');

        if (this.lang.isArabic()) {
            return `${date} ${this.actualDate.clone().locale(this.lang.getLangSnapshot())
                .format(TimeService.dateTimeFormatCalendar)}`;
        }
        return `${date} ${this.actualDate.format(TimeService.dateTimeFormatCalendar)}`;
    }

    private existsDate(date: any): boolean {
        const find = this.latestDatesVal.find(
            val => val.moment.format(TimeService.dateFormat) === date.format(TimeService.dateFormat),
        );
        return find !== undefined;
    }

    public prev(): void {
        this.actualDate.subtract(1, 'day');
        this.date.patchValue(this.actualDate);
        this.actualDate.format(TimeService.dateFormat);

        this.page.calendar.selectedDates = [this.actualDate.format(TimeService.dateFormat)];
        this.page.calendar.update({
            dates: true,
        });
    }

    public next(): void {
        this.actualDate.add(1, 'day');
        this.date.patchValue(this.actualDate);

        this.page.calendar.selectedDates = [this.actualDate.format(TimeService.dateFormat)];
        this.page.calendar.update({
            dates: true,
        });
    }

    public get name(): string {
        return this.form.get('date')!.value || '';
    }

    public trackByIndex(index: number): number {
        return index;
    }

    public get isLast(): boolean {
        const M = moment(this.date2.value);
        return this.latestDatesVal[0].moment.format(TimeService.dateFormat) === M.format(TimeService.dateFormat);
    }

    public get isFuture(): boolean {
        const M = moment(this.date2.value);
        return last(this.latestDatesVal)!.moment.format(TimeService.dateFormat) ===
            M.format(TimeService.dateFormat);
    }

    public latestDates(): {moment: any; format: string}[] {
        const Dates = [];

        if (!this.lang.isArabic()) {
            moment.locale(this.lang.getLangSnapshot());
        }

        for (let x = 0; x < 2; x += 1) {
            let x2 = x % 2 === 0 ? 20 : 0;
            const Tr = true;
            while (Tr) {
                let d: any = moment();
                if (x === 0) {
                    d = moment().subtract(x2, 'day');
                    x2 -= 1;
                    if (x2 < 0) {
                        break;
                    }
                }
                if (x === 1) {
                    d = moment().add(x2, 'day');
                    x2 += 1;
                    if (x2 > 20) {
                        break;
                    }
                }
                let f;
                if (d!.isSame(moment())) {
                    f = this.transVals?.['web.today'] ?? 'Today';
                } else if (d!.isSame(moment().add(1, 'day'))) {
                    f = this.transVals?.['web.tommorow'] ?? 'Tommorow';
                } else {
                    const date = this.timePipe.transform(d, 'localDateShort');

                    f = `${date} ${d!.format(TimeService.dateTimeFormatCalendar2)}`;
                    if (this.lang.isArabic()) {
                        f = `${date} ${d!.clone().locale(this.lang.getLangSnapshot())
                            .format(TimeService.dateTimeFormatCalendar2)}`;
                    }
                }
                Dates.push({ moment: d, format: f });
            }
        }
        return Dates;
    }

    private init(): void {
        this.latestDatesVal = this.latestDates();
        this.date = new FormControl();
        this.date.valueChanges.subscribe((val): void => {
            val = moment(val);
            this.latestDatesVal = this.latestDates();
            this.actualDate = val;
            this.onChange.emit(val);
            if (!this.existsDate(val)) {
                this.latestDatesVal.push({ moment: val,
                    format: `${this.timePipe.transform(val, 'localDateShort')}
                ${val.format(TimeService.dateTimeFormatCalendar2)}` });
            }
            // console.log(this.latestDatesVal);s
            // this.date2.patchValue(this.dateFormat(val));
        });

        this.date2 = new FormControl();
        const val = this.dateFormat(moment());
        this.date2.patchValue(val);
        this.actualDate = moment(val);
        this.date2.valueChanges.subscribe((val): void => {
            const M = moment(val);
            this.actualDate = M;
            this.onChange.emit(M);
            this.page.calendar.selectedDates = [this.actualDate.format(TimeService.dateFormat)];
            this.page.calendar.update({
                dates: true,
            });
        });

        this.route.paramMap.subscribe((param): void => {
            this.actualDate = param.has('date') ? moment(param.get('date') as string) : moment();
            if (param.has('date')) {
                this.date.patchValue(this.actualDate);
                this.date2.patchValue(this.actualDate.format(TimeService.dateFormat));
            }
        });


        this.loaded = true;
    }

    public dateFormat(date: any): string {
        return date.format(TimeService.dateFormat);
    }
}
