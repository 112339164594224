/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Player, PlayerExtend } from '@interfaces/match-data.interface';
import { PageService } from '@services/page.service';
import { BaseComponent } from '@components/base.component';
import { snakeCase } from 'lodash-es';

@Component({
    selector: 'app-player-match-stats',
    templateUrl: './player-match-stats.component.html',
})
export class PlayerMatchStatsComponent extends BaseComponent implements OnInit {
    @Input()
    public players: Player[];

    public player: PlayerExtend | null = null;

    public visible: boolean = false;

    private statsOrder: Record<string, number> = {

        GoalsScored: -1,
        Goals: -1,
        Points: -1,
        Assists: -1,
        FirstAssists: -1,
        SecondAssists: -1,
        MinutesPlayed: -1,
        GoalsByHead: -1,
        GoalsByPenalty: -1,
        GoalsConceded: -1,
        OwnGoals: -1,
        FieldGoals: -1,
        SevenMGoals: -1,

        Line1: -2,

        Offsides: -1,
        PassesTotal: -1,
        PassesSuccessful: -1,
        PassesUnsuccessful: -1,
        CrossesTotal: -1,
        CrossesSuccessful: -1,
        LongPassesTotal: -1,
        LongPassesSuccessful: -1,
        LongPassesUnsuccessful: -1,
        ChancesCreated: -1,
        Line2: -2,
        ShotsOnTarget: -1,
        Shots: -1,
        ShotsOnGoal: -1,
        ShotsOffGoal: -1,
        ShotsOffTarget: -1,
        ShotsBlocked: -1,
        ShotsFacedTotal: -1,
        ShotsFacedSaved: -1,
        PlusMinus: -1,
        DribblesCompleted: -1,

        Line3: -2,

        YellowCards: -1,
        RedCards: -1,
        YellowRedCards: -1,
        FoulsCommitted: -1,
        WasFouled: -1,


        Line4: -2,

        Clearances: -1,
        TacklesSuccessful: -1,
        TacklesTotal: -1,
        CornerKicks: -1,
        DefensiveBlocks: -1,
        Interceptions: -1,

        Line5: -2,

        DivingSaves: -1,
        LossOfPossession: -1,
        PenaltiesFaced: -1,
        PenaltiesMissed: -1,
        PenaltiesSaved: -1,
        SubstitutedIn: -1,
        SubstitutedOut: -1,
    };

    constructor(@Inject(SportConfigToken) private sportConfig: Record<string, any>, public page: PageService) {
        super();
    }

    public ngOnInit(): void {
        this.page.$onPlayerMatch.subscribe((player: PlayerExtend) => {
            this.close();
            this.player = null;
            this.player = player;

            this.visible = true;
        });
    }

    get stats(): [string, unknown][] {
        const merged = this.player?.statistics ? { ...this.statsOrder, ...this.player?.statistics } : [];
        let stats: [string, number][] = merged ? Object.entries(merged) : [];


        stats = stats.filter((v: [string, number]) => v[1] !== -1);

        stats = stats.filter((v: [string, number], key) => {
            if (stats[key - 1] && stats[key - 1]![1] === -2 && v[1] === -2) {
                return false;
            }

            return true;
        });

        return stats;
    }

    public snakeCase(val:string): string {
        return snakeCase(val);
    }

    get hasStats(): boolean {
        return this.stats && this.stats.length > 0;
    }

    get name(): string {
        return this.player?.name_short ?? this.player?.name ?? '';
    }

    public close(): void {
        this.visible = false;
        // this.player = null;
    }
}
