<div
    class="table-sport__subhead d-flex align-items-center py-0"
    [class.bg-light]="!isFavorite2(cg?.tournament_id)"
    [class.bg-primary]="isFavorite2(cg?.tournament_id)"
    [class.bg-primary--light]="isFavorite2(cg?.tournament_id)"
    [attr.id]="'category-' + cg?.category_id + cg?.sub_tournament_id"
    (click)="open(cg)"
    data-toggle="collapse"
    [attr.href]="'#collapse-' + cg?.category_id + cg?.sub_tournament_id"
    role="button"
    [attr.aria-expanded]="cg?.isopen"
    [class.collapsed]="!cg?.isopen"
    [attr.aria-controls]="'collapse-' + cg?.category_id + cg?.sub_tournament_id"
>
    <img
        *ngIf="isMobile && cg?.tournament_country_iso && sportName === 'tennis'; else adminIcon"
        src="{{ cdn + '/img/flags/1x1/' + (cg?.tournament_country_iso | lowercase) + '.svg' }}"
        width="20"
        class="mr-2 rounded-circle"
        alt="{{ cg?.tournament_country_iso }}"
    />
    <ng-template #adminIcon>
        <img
            src="{{ cdn + cg?.image }}"
            width="20"
            height="20"
            loading="lazy"
            class="mr-2 rounded-circle"
            alt="{{ cg?.category_name }}  {{ cg?.sub_tournament_name }}"
        />
    </ng-template>

    <div class="table-sport__subhead__text my-2 my-sm-1 text-truncate mr-auto pr-1">
        <ng-container *ngIf="isMobile">
            <ng-container *ngIf="sportName !== 'tennis' && !isBox; else other"
                ><span>{{ cg.sub_tournament_name }}</span>
                <div class="text-muted mt-1 mt-sm-0">{{ cg.category_name }}</div>
            </ng-container>
            <ng-template #other>
                <ng-container *ngIf="isBox; else tennis"
                    ><span>{{ cg.tournament_name }}</span> | <span>{{ cg.sub_tournament_name }}</span>
                </ng-container>
                <ng-template #tennis>
                    <span *ngIf="!cg?.sub_tournament_city">{{ cg.sub_tournament_name }}</span>
                    <ng-container *ngIf="cg?.sub_tournament_city">{{ cg.sub_tournament_city }}</ng-container>
                    <ng-container
                        *ngIf="cg?.tournament_country || cg?.tournament_country_iso3 || cg?.tournament_country_iso"
                    >
                        | {{ cg.tournament_country || cg.tournament_country_iso3 || cg.tournament_country_iso }}
                    </ng-container>
                    <div class="text-muted mt-1 mt-sm-0">
                        {{ cg.category_name
                        }}<ng-container *ngIf="cg?.singles_doubles">
                            <ng-container *ngIf="cg?.singles_doubles === 1 && !isMobile">{{
                                'web.singles' | translate
                            }}</ng-container>
                            <ng-container *ngIf="cg?.singles_doubles === 2">
                                - {{ 'web.doubles' | translate }}</ng-container
                            >
                        </ng-container>
                        <ng-container *ngIf="cg?.sub_tournament_qualifying === 1">
                            - {{ 'web.qualifying' | translate }}</ng-container
                        >
                        <ng-container *ngIf="cg?.sub_tournament_ground">
                            - {{ cg.sub_tournament_ground | translate | lowercase }}
                        </ng-container>
                    </div>
                </ng-template>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="!isMobile">
            <ng-container *ngIf="showCategoryName">
                <ng-container *ngIf="!isBox">
                    <span class="d-none d-sm-inline" *ngIf="!isMobile || cg.showCategory"
                        >{{ cg.category_name }} |
                    </span>
                    <span class="d-sm-none" *ngIf="!isMobile || cg.showCategory">{{ cg.category_name_short }} | </span>
                </ng-container>
                <ng-container *ngIf="isBox">
                    <span>{{ cg.tournament_name }} | </span>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="sportName !== 'tennis'; else tennis">
                <span> {{ cg.sub_tournament_name }}</span>
            </ng-container>
            <ng-template #tennis>
                <span *ngIf="!cg?.sub_tournament_city"> {{ cg.sub_tournament_name }}</span>
                <span>
                    <ng-container *ngIf="cg?.sub_tournament_city">{{ cg.sub_tournament_city }}</ng-container>

                    <ng-container *ngIf="cg?.tournament_country_iso3 || cg?.tournament_country_iso">
                        ({{ cg.tournament_country_iso3 || cg.tournament_country_iso }})</ng-container
                    ></span
                >
                <ng-container *ngIf="cg?.sub_tournament_qualifying === 1">
                    - <span>{{ 'web.qualifying' | translate }}</span></ng-container
                ><ng-container *ngIf="cg?.sub_tournament_ground">
                    - <span>{{ cg.sub_tournament_ground! | translate | lowercase }}</span></ng-container
                >
            </ng-template>
        </ng-container>
    </div>
    <a
        *ngIf="cg.hasTable"
        class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link app-mob-category-link"
        [routerLink]="[
            '../../event',
            sportName + '-' + sportId,
            cg?.tournament_id,
            { sid: cg?.sub_tournament_id, table: 1 }
        ]"
    >
        <svg class="icon icon--14 icon--gray mb-1 app-icon-table"><use xlink:href="#icon-table2"></use></svg>
        {{ 'web.table' | translate | transinit }}
    </a>
    <a
        *ngIf="cg.hasCupTree"
        class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link app-mob-category-link"
        [routerLink]="['../../event', sportName + '-' + sportId, cg?.tournament_id, { cuptree: 1 }]"
    >
        <svg class="icon icon--14 icon--gray mb-1 app-icon-table"><use xlink:href="#icon-cup"></use></svg>
        {{ 'web.cup_tree' | translate | transinit }}
    </a>
    <a
        *ngIf="!cg.hasTable && !cg.hasCupTree"
        class="xsmall text-gray d-flex d-sm-none flex-column align-items-center text-uppercase p-2 border-left border-white app-category-detail-link app-mob-category-link"
        [routerLink]="['../../event', sportName + '-' + sportId, cg?.tournament_id, { sid: cg?.sub_tournament_id }]"
    >
        <svg class="icon icon--14 icon--gray mb-1"><use xlink:href="#icon-info"></use></svg>
        {{ 'web.detail' | translate | transinit }}
    </a>

    <app-favorite
        *ngIf="isMobile"
        [tournamentId]="cg?.tournament_id"
        type="match-list-mobile"
        [sportId]="sportId"
        class="d-flex flex-column xsmall d-sm-none font-weight-normal align-items-center app-cursor text-uppercase p-2 border-left border-white text-gray"
    >
        <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
        <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
    </app-favorite>
    <app-favorite
        [tournamentId]="cg?.tournament_id"
        [type]="'tournament'"
        [sportId]="sportId"
        *ngIf="!isMobile"
        class="d-none d-sm-flex"
    >
        <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
        <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
    </app-favorite>
    <a
        *ngIf="cg.hasTable"
        [routerLink]="[
            '../../event',
            sportName + '-' + sportId,
            cg?.tournament_id,
            { sid: cg?.sub_tournament_id, table: 1 }
        ]"
        class="small text-reset d-none d-sm-inline app-category-detail-link"
    >
        <svg class="icon icon--12 mr-2 d-none dm-md-inline app-icon-table">
            <use xlink:href="#icon-table2" />
        </svg>
        {{ 'web.table' | translate | transinit }}&nbsp;|&nbsp;
    </a>
    <a
        *ngIf="cg.hasCupTree"
        [routerLink]="['../../event', sportName + '-' + sportId, cg?.tournament_id, { cuptree: 1 }]"
        class="small text-reset d-none d-sm-inline app-category-detail-link"
    >
        <svg class="icon icon--12 mr-2 d-none dm-md-inline app-icon-table">
            <use xlink:href="#icon-table2" />
        </svg>
        {{ 'web.cup_tree' | translate | transinit }}&nbsp;|&nbsp;
    </a>
    <a
        [routerLink]="['../../event', sportName + '-' + sportId, cg?.tournament_id, { sid: cg?.sub_tournament_id }]"
        class="small text-reset d-none d-sm-inline app-category-detail-link"
    >
        <svg class="icon icon--12 mr-2 d-none dm-md-inline app-icon-table">
            <use xlink:href="#icon-table" />
        </svg>
        {{ 'web.detail' | translate | transinit }}</a
    >
    <!-- <svg class="icon icon--12 icon--primary ml-2"><use href="#icon-star-fill"/></svg> -->
</div>

<div
    class="collapse"
    [class.show]="cg?.isopen"
    role="tabpanel"
    [attr.aria-labelledby]="'category-' + cg?.category_id + cg?.sub_tournament_id"
    [attr.id]="'collapse-' + cg?.category_id + cg?.sub_tournament_id"
>
    <ng-content></ng-content>
</div>
