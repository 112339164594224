import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from '@pages/pages.component';
import { PageAccountComponent } from '@pages/page-account/page-account.component';
import { DashboardComponent } from '@pages/page-account/dashboard/dashboard.component';
import { PageBlankComponent } from '@pages/page-blank/page-blank.component';
import { RegistrationConfirmComponent } from '@pages/page-blank/registration-confirm/registration-confirm.component';
import { MatchListComponent } from '@pages/page-sport/match-list/match-list.component';
import { PageSportComponent } from '@pages/page-sport/page-sport.component';
import { MatchPageComponent } from '@pages/page-sport/match/match.component';
import { PlayerComponent } from '@pages/page-sport/player/player.component';
import { TournamentComponent } from '@pages/page-sport/tournament/tournament.component';
import { ParticipantComponent } from '@pages/page-sport/participant/participant.component';
import { MyMatchesComponent } from '@pages/my-matches/my-matches.component';

// import { ParticipantCompareComponent } from '@pages/page-sport/participant-compare/participant-compare.component';

// import { ParticipantCompareResolverService }
// from '@pages/page-sport/participant-compare/participant-compare.service';

import { VenuesComponent } from '@pages/venues/venues.component';

import { VenueComponent } from '@pages/venues/venue.component';

import { CategoryComponent } from '@pages/page-sport/category/category.component';

import { DashboardFavoriteComponent } from '@pages/page-account/favorite/favorite.component';

import { TransitionGuard } from './guards/auth/transition.guard';
import { EnterGuard } from './guards/auth/enter.guard';

const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        data: { animation: 'Page' },
        children: [
            {
                path: '',
                redirectTo: '/hp',
                pathMatch: 'full',
            },
            {
                path: 'stage',
                loadChildren: (): any => import('./page-stage/stage.module').then((m): any => m.StageModule),
            },
            {
                path: 'golf',
                loadChildren: (): any => import('./page-golf/golf.module').then((m): any => m.GolfModule),
            },
            {
                path: 'static/:type',
                loadChildren: (): any => import('./static/static.module').then((m): any => m.StaticModule),
            },

            {
                path: 'sport',
                component: PageSportComponent,
                // loadChildren: (): any => import('./page-sport/page-sport.module')
                // .then((m): any => m.PageSportModule),
                children: [
                    {
                        path: '',
                        redirectTo: '/hp',
                        pathMatch: 'full',
                    },
                    {
                        path: 'event/:sport/:tournament',
                        component: TournamentComponent,
                        // loadChildren: (): any => import('./tournament/tournament.module')
                        // .then((m): any => m.TournamentModule),
                    },
                    {
                        path: 'participant/:sport/:id',
                        component: ParticipantComponent,
                        // loadChildren: (): any => import('./participant/participant.module')
                        // .then((m): any => m.ParticipantModule),
                    },
                    // {
                    //     path: 'compare/:sport/:first/:second',
                    //     component: ParticipantCompareComponent,
                    //     resolve: {
                    //         participants: ParticipantCompareResolverService,
                    //     },
                    // },
                    {
                        path: 'match-list/:sport',
                        component: MatchListComponent,
                    },
                    {
                        path: 'category/:sport/:category',
                        component: CategoryComponent,
                    },

                    { path: 'match/:sport/:id', component: MatchPageComponent },
                    { path: 'my-matches', component: MyMatchesComponent },
                    {
                        path: 'venues',
                        children: [
                            { path: '',
                                component: VenuesComponent },
                            {
                                path: ':id',
                                component: VenueComponent,
                            },
                        ],


                    },

                    {
                        path: 'player/:sport/:id',
                        component: PlayerComponent,
                    },
                ],
            },
            {
                path: 'my-matches',
                redirectTo: 'sport/my-matches',
                pathMatch: 'full',
            },

            {
                path: 'account',
                canActivate: [TransitionGuard, EnterGuard],
                component: PageAccountComponent,
                children: [
                    {
                        path: 'dashboard',
                        component: DashboardComponent,
                        data: { needAuth: true },
                    },
                    {
                        path: 'favorite',
                        component: DashboardFavoriteComponent,
                        data: { needAuth: true },
                    },
                ],
            },

            {
                path: 'blk',
                component: PageBlankComponent,
                children: [{ path: 'registration-confirm', component: RegistrationConfirmComponent }],
            },
            {
                path: 'see',
                loadChildren: (): any => import('./page-regular/regular.module').then((m): any => m.RegularModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {}
