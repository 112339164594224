<div class="ng-star-inserted">
    <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
        <span class="font-weight-bold text-uppercase">
            {{ stats.tournamentName }}
            <ng-container *ngIf="stats.tournamentYear">{{ stats.tournamentYear }}</ng-container>
        </span>
    </div>
    <div class="bg-white">
        <div id="collapse-drivers" class="collapse show">
            <div class="table-responsive">
                <table class="table table-sm stage-table">
                    <tbody>
                        <ng-container *ngFor="let group of statsVal | keys; trackBy: trackByIndex">
                            <tr *ngIf="group.key !== 'init'" class="bg-light2">
                                <td class="small align-middle text-center" colspan="2">
                                    {{ 'web.stats_' + group.key | translate }}
                                </td>
                            </tr>

                            <tr *ngFor="let stat of group.value | keys; trackBy: trackByIndex" class="ng-star-inserted">
                                <td class="text-nowrap align-middle" width="80%">
                                    {{ 'web.participant_stats_' + stat.key | translate }}
                                </td>
                                <td class="text-nowrap align-middle text-right pr-2">
                                    {{ stat.value | statsVal: stat.key }}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
