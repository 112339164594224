/* eslint-disable @typescript-eslint/naming-convention */
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';
import { FavoriteService } from 'src/app/services/favorite.service';
import { StorageService } from 'src/app/services/storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';

import { CategoryInterface } from '../../../interfaces/category.interface';
import SPORT_CONFIG from '../../../config/sport.config';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-component-category',
    templateUrl: './category.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryComponent extends BaseComponent implements OnInit {
    @Input()
    public cg: CategoryInterface;

    @Input()
    public sportId: number;

    @Input()
    public sportName: string;

    @Input()
    public showCategoryName: boolean = true;

    @Output()
    public cgChange: EventEmitter<CategoryInterface> = new EventEmitter();

    public isFavoriteVal: boolean = false;

    public isMobile: boolean = false;

    public constructor(
        private favorite: FavoriteService,
        private storage: StorageService,
        private deviceService: DeviceDetectorService,
    ) {
        super();
        this.isMobile = this.deviceService.isMobile();
    }

    public ngOnInit(): void {
        this.updateFavorite();
    }

    private updateFavorite(): void {
        this.isFavoriteVal = this.favorite.existsTournament(
            this.sportId,
            this.cg.tournament_id,
        );
    }

    /**
     * Toggle open state
     * @param  cg  - category
     * @return {void}
     */
    public open(cg: CategoryInterface): void {
        window.requestAnimationFrame((): void => {
            cg.isopen = !cg.isopen;

            const openCategories =
                this.storage.get<any[]>(SPORT_CONFIG.categoryIndex) || [];

            const index = openCategories.findIndex(
                (val): boolean => val === cg.sub_tournament_id,
            );

            if (index !== -1 && !cg.isopen) {
                openCategories.splice(index, 1);
            } else if (cg.isopen) {
                openCategories.push(cg.sub_tournament_id);
            }

            this.storage.set(SPORT_CONFIG.categoryIndex, openCategories);
            this.cgChange.emit(cg);
        });
    }

    /**
     * Check if tournamen tin favorite
     * @param {number} tId - tournament id
     * @return {object}
     */
    public isFavorite(tId: number): Record<string, boolean> {
        const isF = this.favorite.existsTournament(this.sportId, tId);
        return {
            'bg-primary--light': isF,
            'bg-primary': isF,
            'bg-light': !isF,
        };
    }

    /**
     * Check if tournamen tin favorite
     * @param {number} tId - tournament id
     * @return {boolean}
     */
    public isFavorite2(tId: number): boolean {
        return this.favorite.existsTournament(this.sportId, tId);
    }

    public get isBox(): boolean {
        return SPORT_CONFIG.isBox(this.sportName);
    }

    /**
     * Get Category header if tournament is in favorite
     * @param {number} tId - tournament id
     * @return {Array<string>}
     */
    public getFavoriteClass(tId: number): { [prop: string]: boolean } {
        const className: Record<string, boolean> = {
            'bg-light': true,
            'bg-primary': false,
            'bg-primary--light': false,
        };

        if (this.isFavorite(tId)) {
            const keys = Object.keys(className);
            keys.forEach((vl): void => {
                className[vl] = !className[vl];
            });
        }
        return className;
    }
}
