/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer, of, merge, Observable } from 'rxjs';
import { FormControl, AbstractControl, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import * as _ from 'underscore';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CupTreeComponent } from 'src/app/components/cup-tree/cup-tree.component';
import { DeviceDetectorService } from 'ngx-device-detector';

import { PageService } from '@services/page.service';
import { TournamentService } from '@services/tournament.service';
import { UtilsService } from '@services/utils.service';
import { ClientService } from '@services/client.service';
import { LangService } from '@services/lang.service';
import { InfoService } from '@services/info.service';
import { MetaService } from '@services/meta.service';

import { WindowRefService } from '@shared/window-ref';

import { Sources } from '@interfaces/source.interface';

import { ViewportScroller } from '@angular/common';

import $ from '../../../shared/jquery';
import { BaseComponent } from '../../base.component';

import { TournamentInterface } from '../../../interfaces/tournament.interface';
import { MatchData, MatchState } from '../../../interfaces/match-data.interface';


import SPORT_CONFIG from '../../../config/sport.config';
import { environment } from '../../../../environments/environment';

declare const moment: any;

const FIRST_MATCH_LIMIT = 40;
const MATCH_LIMIT = 100;

@Component({
    selector: 'app-tournament',
    templateUrl: './tournament.component.html',
})
export class TournamentComponent extends BaseComponent implements OnInit, OnDestroy {
    public sportId: number;

    public sportName: string;

    public tournamentId: number;

    public data: TournamentInterface | null;

    public finishedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    public notStartedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    private subscriber: Subscription;

    private subscriberReload: Subscription;

    public matchId: number | null = null;

    public tracker: boolean = false;
    public stream: boolean = false;

    public reverseSeason: any[] = [];

    public parentClass: Record<string, boolean> = { 'col-lg-12': false, 'col-lg-8': true };

    public matchClass: Record<string, boolean> = {
        'col-lg-4': true,
        'd-none': true,
        'd-lg-block': true,
    };

    public normalizeNotStarted: MatchData[][] = [];

    public normalizeFinished: MatchData[][] = [];

    public normalizeFinishedByDate: MatchData[][];

    public normalizeNotStartedByDate: MatchData[][];

    public matchState: MatchState;

    public actualSeasonProgress: number = 0;

    public progress: { [prop: string]: any } | null = null;

    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    /**
     * If true this tournament matches has no rounds
     * Separate info for finished and not started
     */
    public isNoRoundF: boolean = false;

    public isNoRoundNS: boolean = false;

    public cuptree: any;

    public activeTab: number = 1;

    private first: boolean = true;

    private limitMax: number = MATCH_LIMIT;

    /**
     * Detect if tournament is in all subtournaments mode
     */
    public allMode: boolean = false;

    public subTournamentName: string = '';

    private sidQuery: number | null = null;

    private showFirstTable = false;
    private showFirstCuptree = false;

    /**
     * Id of selected season
     */
    public seasonControl: AbstractControl;

    public subTournamentControl: AbstractControl;

    public constructor(
        protected page: PageService,
        private tournament: TournamentService,
        private router: ActivatedRoute,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public dialog: MatDialog,
        private deviceService: DeviceDetectorService,
        private metaService: MetaService,
        private windowRefService: WindowRefService,
        private viewportScroller: ViewportScroller,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.seasonControl = new FormControl('', [Validators.required]);
        this.subTournamentControl = new FormControl(0, [Validators.required]);

        merge(
            this.seasonControl.valueChanges.pipe(
                switchMap(
                    (val): Observable<any> => {
                        if (!this.first) {
                            this.subTournamentControl.setValue(0);
                        }

                        return of(val);
                    },
                ),
            ),
            this.subTournamentControl.valueChanges,
        ).subscribe((): void => {
            if (this.subscriber instanceof Subscription) {
                this.subscriber.unsubscribe();
            }
            if (this.subscriberReload instanceof Subscription) {
                this.subscriberReload.unsubscribe();
            }

            if (!this.first) {
                this.normalizeFinished = [];
                this.normalizeNotStarted = [];
            }

            this.first = true;
            this.reset();

            this.start();
            this.reload();
        });

        this.router.paramMap.subscribe((param): void => {
            this.reset();
            this.first = true;
            this.reverseSeason.splice(0);
            this.limitMax = MATCH_LIMIT;
            if (param.has('sid')) {
                this.subTournamentControl.setValue(+param.get('sid')!);
                this.sidQuery = +param.get('sid')!;
            } else {
                this.subTournamentControl.setValue(0);
                this.sidQuery = null;
            }

            if (param.has('table')) {
                this.showFirstTable = true;
            } else if (param.has('cuptree')) {
                this.showFirstCuptree = true;
            }

            this.data = null;

            this.normalizeFinished = [];
            this.normalizeNotStarted = [];
            this.normalizeFinishedByDate = [];
            this.normalizeNotStartedByDate = [];
            this.parentClass['col-lg-8'] = true;
            this.parentClass['col-lg-12'] = false;
            this.matchClass['d-none'] = true;
            this.matchClass['d-lg-block'] = true;

            // $$('html, body').animate({ scrollTop: 0 }, 1000);
            this.viewportScroller.scrollToPosition([0, 0]);
            if (param.has('tournament')) {
                this.tournamentId = +param.get('tournament')!;
                this.seasonControl.setValue('');
                [this.sportId, this.sportName] = UtilsService.parseSportUrl(param.get('sport')!);
                this.ngOnDestroy();
                this.reload();
            } else {
                this.error();
            }
        });

        this.router.fragment.subscribe((fragment): void => {
            this.tab(fragment || '');
        });

        setTimeout((): void => {
            $('#tournamentTab li:first-child a').tab('show');
        }, 100);
    }


    public ngOnDestroy(): void {
        this.tab('overview');
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        $('[data-toggle="tooltip"]').tooltip('hide');

        this.tournament.emitLoad(null);
    }

    public trackByFnSeason(index: number, item: Record<string, any>): number {
        return item.season_id;
    }

    public trackByFnSubtournament(index: number, item: Record<string, any>): number {
        return item.sub_tournament_id;
    }

    /**
     * Start reloading data
     * @return {void}
     */
    private reload(): void {
        this.subscriberReload = timer(0, environment.matchTournamentReloadTime)
            .pipe(switchMap((val): Observable<any> => of(val)))
            .subscribe((): void => {
                this.load();
            });
    }

    get showSeasonPeriod(): boolean {
        return this.data?.sourceName !== Sources.BETSAPI;
    }

    get displayTransfers(): boolean {
        return !SPORT_CONFIG.isHandball(this.sportName);
    }

    /**
     * Toggle aside menu
     * @return {void}
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    public openCupTree(): void {
        const DialogRef = this.dialog.open(CupTreeComponent, {
            maxWidth: '94%',
            width: '94%',
            maxHeight: '94%',
            height: '94%',
            panelClass: 'app-cup-tree',
            data: {
                subId: this.subTournamentControl.value,
                seasonId: this.seasonControl.value,
            },
        });

        DialogRef.afterClosed().subscribe((): void => {
            // console.log('The dialog was closed');
        });
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    public get cupTreeOverview(): MatchData[][] {
        const Slice = this.normalizeFinishedByDate[0] && this.normalizeFinishedByDate[0].length > 20 ? 1 : 4;
        return this.normalizeFinishedByDate.length > 0
            ? this.normalizeFinishedByDate.slice(0, Slice)
            : this.normalizeNotStartedByDate.slice(0, Slice).reverse();
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_tournament').subscribe((): void => {
            const Tournament = this.data ? this.data.tournament_name : '';
            const Sport = this.translate.instant(`sport.${this.sportName}`);
            const Trans = this.translate.instant('web.title_tournament');
            const Desc = this.translate.instant('web.description_tournament');
            const Client = this.client.getMainDomain();
            // eslint-disable-next-line camelcase
            const Category = this.data?.category_name;
            this.setTitle(
                {
                    title: `${Tournament} - ${Category} - ${Sport} - ${Trans} | ${Client}`,
                    description: `${Tournament} - ${Sport} ${Desc}`,
                },
                true,
            );
        });
    }

    /**
     * Event fired when some match is selected/clicked
     * @event MatchListComponent#loadMatch
     * @param  match
     * @return {void}
     */
    public loadMatch(match: any): void {
        if (this.matchId != null && this.matchId === match.id) {
            return;
        }
        this.parentClass['col-lg-8'] = true;
        this.parentClass['col-lg-12'] = false;
        // this.matchClass['d-none'] = false;
        this.matchClass['d-lg-block'] = true;
        this.matchState = match.match_state;
        this.matchId = match.id;
        this.tracker = false;
        if (_.has(match, 'tracker')) {
            this.tracker = match.tracker as boolean;
        }
        if (_.has(match, 'stream')) {
            this.stream = match.stream as boolean;
        }
    }

    /**
     * Open tab
     * @param {string} tab
     */
    public tab(tab: string): void {
        if (tab === 'overview') {
            this.activeTabFce(1);
        }

        if (tab === 'results') {
            this.activeTabFce(2);
        }
        if (tab === 'future') {
            this.activeTabFce(3);
        }
        if (tab === 'table') {
            this.activeTabFce(4);
        }
        if (tab === 'player-stats') {
            this.activeTabFce(6);
        }
        if (tab === 'players-transfers') {
            this.activeTabFce(7);
        }
        if (tab === 'tennis-tournament-info') {
            this.activeTabFce(8);
        }

        setTimeout((): void => {
            $(`#tournamentTab li a[href="#${tab}"]`).tab('show');
            // $$('html, body').animate({ scrollTop: 0 }, 1000);
        }, 100);
    }

    public activeTabFce(tab: number): void {
        this.activeTab = tab;

        this.finishedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;
        this.notStartedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;

        if (!this.allMode && !this.isNoRoundF) {
            this.finishedLimit = SPORT_CONFIG.tournament.roundLimit;
        }
        if (!this.allMode && !this.isNoRoundNS) {
            this.notStartedLimit = SPORT_CONFIG.tournament.roundLimit;
        }

        if (this.activeTab === 5) {
            this.cuptree = this.tournament.getCupTree(this.data!.sub_tournament_id, this.data!.season_id);
        }

        this.windowRefService.nativeWindow.dataLayer.push({
            event: 'click_tab',
            tab_name: `tournamnet_tab_${tab}`,
            tab_sport: this.sportName,
            tab_league: this.data?.tournament_name,

        });
    }

    // TODO: change after API update https://bumpsro.atlassian.net/browse/LIV-5417
    get hasOptaStats(): boolean {
        return this.client.use('opta') &&
        this.data?.opta_competition !== null && this.data?.opta_season !== null;
    }

    /**
     * Check of page loading is finished
     * @param  {string} type
     * @return  {boolean}
     */
    public isDone(type: string): boolean {
        if (type === 'finished') {
            if (!this.allMode && !this.isNoRoundF && this.normalizeFinished.length <= this.finishedLimit) {
                return true;
            }
            if ((this.allMode || this.isNoRoundF) && Number(this.data!.data.finished.length) <= this.finishedLimit) {
                return true;
            }
        }
        if (type === 'not_started') {
            if (!this.allMode && !this.isNoRoundNS && this.normalizeNotStarted.length <= this.notStartedLimit) {
                return true;
            }
            if (
                (this.allMode || this.isNoRoundNS) &&
                Number(this.data!.data.not_started.length) <= this.notStartedLimit
            ) {
                return true;
            }
        }

        return false;
    }

    /**
     * Go to next result
     * @param {number} offset
     */
    public nextResult(): void {
        const OffsetF =
            !this.allMode && !this.isNoRoundF
                ? SPORT_CONFIG.tournament.roundLimit
                : SPORT_CONFIG.tournament.roundLimitNoRounds;
        const OffsetNS =
            !this.allMode && !this.isNoRoundNS
                ? SPORT_CONFIG.tournament.roundLimit
                : SPORT_CONFIG.tournament.roundLimitNoRounds;
        this.finishedLimit += OffsetF;
        this.notStartedLimit += OffsetNS;

        const Num = (!this.allMode && !this.isNoRoundF) || (!this.allMode && !this.isNoRoundNS) ? 20 : 2;
        this.limitMax = Math.max(MATCH_LIMIT, Num * Math.max(this.finishedLimit, this.notStartedLimit));
        this.load();
    }

    /**
     * Open default match on first load
     * @return {void}
     */
    private openDefault(): void {
        if (this.deviceService.isMobile()) {
            return;
        }

        if (this.data!.not_started_r && this.data!.not_started_r.length > 0) {
            this.data!.not_started_r[0]!.tracker = null;
            this.loadMatch(this.data!.not_started_r[0]);
        } else if (this.data!.data.not_started.length > 0) {
            this.data!.data.not_started[0]!.tracker = null;
            this.loadMatch(this.data!.data.finished[0]);
        } else if (this.data!.data.finished.length > 0) {
            this.data!.data.finished[0]!.tracker = null;
            this.loadMatch(this.data!.data.finished[0]);
        }
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public get tournamentLink(): boolean {
        return SPORT_CONFIG.tournamentLink.includes(this.sportName);
    }

    /**
     * Load tournament data
     *
     * @return {void}
     */
    protected async load(): Promise<any> {
        try {
            if (!this.tabVisible) {
                return;
            }
            const short = true;


            const Limit = this.first ? FIRST_MATCH_LIMIT : this.limitMax;

            // if (Limit !== MATCH_LIMIT) {
            //     short = true;
            // }


            const Data = await this.tournament.getTournament(
                this.tournamentId,
                this.seasonControl.value,
                this.subTournamentControl.value,
                'all',
                short,
                Limit,
            );

            this.subscriber = Data.subscribe(
                (val): void => {
                    const Backup = this.data;
                    this.data = val;

                    const sportTrans = this.translate.instant(`sport.${this.sportName}`);
                    const { hostname } = this.windowRefService.nativeWindow.location;
                    const jsonLD = {
                        '@context': 'https://schema.org',
                        '@type': 'BreadcrumbList',
                        itemListElement: [{
                            '@type': 'ListItem',
                            position: 1,
                            name: `${sportTrans}`,
                            item: `https://${hostname}/page/sport/match-list/${this.sportName}-${this.sportId}`,
                        }, {
                            '@type': 'ListItem',
                            position: 2,
                            name: `${this.data.tournament_name}`,
                        }],
                    };
                    this.metaService.jsonLDSetter$.next(jsonLD);

                    if (val.data.not_started) {
                        this.data.not_started_r = [...val.data.not_started.slice()];
                    }
                    const Flength = val.data.finished.length;
                    const NSlength = val.data.not_started.length;
                    this.seasonProgressBar();

                    if (!this.first) {
                        if (val.data.finished.length === 0) {
                            this.data.data.finished = Backup!.data.finished;
                        }
                        if (val.data.not_started.length === 0) {
                            const NotStarted = 'not_started';
                            this.data.data[NotStarted] = Backup!.data[NotStarted];
                        }
                        if (val.data.not_started) {
                            this.data.not_started_r = [...val.data.not_started.slice()];
                        }
                        // TODO ecause all data are still coming this
                        // muset be commented otherwise ended livematches remain
                        // if (val.data.live.length === 0) {
                        //     this.data.data.live = _backup.data.live;
                        // }
                    } else {
                        if (this.data.subtournaments.length === 1) {
                            this.subTournamentControl.setValue(this.data.subtournaments[0]!.sub_tournament_id);
                        } else if (
                            this.data.last_sub_tournament_id &&
                            !_.isNull(this.data.last_sub_tournament_id) &&
                            SPORT_CONFIG.displayType.subTournamentUpdate.includes(this.sportName) &&
                            this.subTournamentControl.pristine &&
                            this.sidQuery === null
                        ) {
                            this.subTournamentControl.setValue(this.data.last_sub_tournament_id);
                        }
                        this.lang.getLang().then((): Promise<any> => this.createTitle());
                        this.reverseSeason = this.data.seasons.reverse();
                    }

                    this.normalizeData(Flength, NSlength);
                    if (this.seasonControl.value === '') {
                        this.seasonControl.setValue(this.data.season_id);
                    }

                    if (this.subTournamentControl.value === 0) {
                        this.allMode = true;
                    } else {
                        this.allMode = false;
                    }

                    this.finished(true);

                    if (this.first) {
                        this.actualSubtournament();
                        this.openDefault();
                        if (this.showFirstTable) {
                            this.activeTabFce(4);
                        } else if (this.showFirstCuptree) {
                            this.activeTabFce(5);
                            this.openCupTree();
                        }
                    }
                    this.first = false;
                    this.tournament.emitLoad(this.data.category_id);
                },
                (): void => {
                    if (this.first) {
                        this.error();
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error();
            }
        }
    }

    /**
     * Get actual sub tournament name
     */
    public actualSubtournament(): void {
        const Found = this.data!.subtournaments.find(
            (val): boolean => val.sub_tournament_id === +this.subTournamentControl.value,
        );

        if (Found) {
            this.subTournamentName = Found.sub_tournament_name;
            return;
        }

        this.subTournamentName = '';
    }

    public get selectedSubtournament(): number {
        return +this.subTournamentControl.value;
    }

    /**
     * IF data has no rounds
     */
    public get hasNoRounds(): boolean {
        return this.isNoRoundF && this.isNoRoundNS;
    }

    /**
     * Prepare data
     */
    private normalizeData(flenght: number, nSlength: number): void {
        this.isNoRoundF = false;
        this.isNoRoundNS = false;

        if (!this.data!.hasCupTree && !this.data!.hasTable) {
            this.isNoRoundF = true;
            this.isNoRoundNS = true;
        }

        if (this.data!.data.finished.length > 0 && this.data!.data.finished[0]!.round == null) {
            this.isNoRoundF = true;
        }

        if (
            this.isNoRoundF &&
            !_.every(this.data!.data.finished, (val): boolean => val.round === null) &&
            this.data!.hasCupTree
        ) {
            this.isNoRoundF = false;
        }

        if (_.every(this.data!.data.finished, (val): boolean => val.round_name === null && val.round === null) ||
        _.every(this.data!.data.finished, (val): boolean => val.round_name === null && val.round == 1)) {
            this.isNoRoundF = true;
        }

        if (_.every(this.data!.data.not_started, (val): boolean => val.round_name === null && val.round === null) ||
        _.every(this.data!.data.not_started, (val): boolean => val.round_name === null && val.round == 1)) {
            this.isNoRoundNS = true;
        }

        if (this.data!.data.not_started.length > 0 && this.data!.data.not_started[0]!.round == null) {
            this.isNoRoundNS = true;
        }

        if (!this.isNoRoundNS) {
            let numLimit = 1;
            if (this.data!.hasCupTree) {
                numLimit = 3;
            }
            if (nSlength !== 0) {
                let lastRound: number | string | null = null;
                let x = -1;
                this.normalizeNotStartedByDate = [];
                this.normalizeNotStarted = [];
                this.data!.data.not_started.forEach((val): void => {
                    if (lastRound === null || (lastRound !== val.round && lastRound !== val.round_name)) {
                        x += 1;
                        lastRound = val.round_name ?? val.round;
                        if (x <= numLimit) {
                            this.normalizeNotStartedByDate[x] = [];
                        }
                        this.normalizeNotStarted[x] = [];
                    }
                    if (x <= numLimit) {
                        this.normalizeNotStartedByDate[x]!.push(val);
                    }
                    this.normalizeNotStarted[x]!.push(val);
                });
            }
        }

        if (!this.isNoRoundF) {
            let numLimit = 1;
            if (this.data!.hasCupTree) {
                numLimit = 3;
            }

            if (flenght !== 0) {
                let lastRound: number | string | null = null;
                let x = -1;
                this.normalizeFinishedByDate = [];
                this.normalizeFinished = [];
                this.data!.data.finished.forEach((val): void => {
                    if (this.data!.hasCupTree && val.round === null && val.round_name === null) {
                        return;
                    }

                    if (lastRound == null || (lastRound !== val.round && lastRound !== val.round_name)) {
                        x += 1;
                        lastRound = val.round_name ?? val.round;
                        if (x <= numLimit) {
                            this.normalizeFinishedByDate[x] = [];
                        }
                        this.normalizeFinished[x] = [];
                    }

                    if (x <= numLimit) {
                        this.normalizeFinishedByDate[x]!.push(val);
                    }
                    this.normalizeFinished[x]!.push(val);
                });
            }
        }
    }

    private seasonProgressBar(): void {
        const Season = this.data!.seasons.find((val): boolean => val.season_id == this.seasonControl.value);

        if (!_.isUndefined(Season)) {
            this.progress = Season as any;


            const Start = moment(Season!.start);
            const End = moment(Season!.end);
            const Now = moment().utc();
            const SeasonLen = Math.abs(Start.diff(End, 'days'));
            const UntilTodayLen = Math.abs(Start.diff(Now, 'days'));

            setTimeout((): void => {
                if (Now.isBefore(Start)) {
                    this.actualSeasonProgress = 0;
                } else if (End.isBefore(Now)) {
                    this.actualSeasonProgress = 100;
                } else {
                    this.actualSeasonProgress = Math.floor((UntilTodayLen / SeasonLen) * 100);
                }
            }, 1500);
        }
    }
}
