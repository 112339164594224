<!-- The Modal -->
<div class="app-slide-modal" (click)="close()">
    <!-- The Close Button -->
    <span class="app-slide-modal__close" (click)="close()">&times;</span>

    <!-- Modal Content (The Image) -->
    <div class="app-slide-modal__modal-content">
        <ng-content select=".content"></ng-content>
    </div>
</div>
