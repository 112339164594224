import { Component } from '@angular/core';

@Component({
    selector: 'app-page-blank',
    templateUrl: './page-blank.component.html',
})
export class PageBlankComponent {


}
