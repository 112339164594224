/**
 *  Sort array of sport by sport position
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { SportService } from 'src/app/services/sport.service';

@Pipe({
    name: 'sortBySport',
    pure: true,
})
export class SportSortPipe implements PipeTransform {
    public constructor(private sport: SportService) {}

    public transform(value: any): any {
        if (!value || this.sport.data === null) {
            return value;
        }

        value = _.sortBy(value, (val: any): number => this.sport.data!.findIndex(
            (sport): boolean => sport.code_name === val.key,
        ),
        );
        return value;
    }
}
