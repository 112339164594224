<div
    class="app-notify-modal d-flex flex-nowrap justify-content-between align-items-center app-notify-modal__item"
    *ngIf="loaded && client.use('notification')"
    [class.app-notify-modal--active]="active"
>
    <div class="app-notify-modal__item app-notify-modal__img">
        <img
            [src]="'img/client/' + client.getName() + '/icons/icon.png'"
            [alt]="client.getName()"
            class="img-fluid app-participant-logo"
            width="50"
            height="50"
        />
    </div>
    <div class="aapp-notify-modal__item app-notify-modal__text">
        {{ 'web.text_ntf' | translate }}
    </div>
    <div class="app-notify-modal__item app-notify-modal__action">
        <button class="btn btn-secondary" data-dismiss="modal" (click)="no()" type="button">
            {{ 'web.decline_ntf' | translate }}
        </button>
        <button class="btn btn-primary" type="button" (click)="yes()">{{ 'web.accept_ntf' | translate }}</button>
    </div>
</div>

<div class="app-notify-action app-notify-action--yes d-none" [class.d-none]="showYes">
    <i class="far fa-hand-pointer fa-2x app-notify-action__icon"></i> <br />

    {{ 'web.ntf_accept_info' | translate }}
</div>

<div class="app-notify-action app-notify-action--no d-none" [class.d-none]="showNo">
    <i class="fas fa-info-circle fa-2x app-notify-action__icon"></i><br />
    {{ 'web.ntf_declice_info' | translate }}
</div>

<div class="app-notify-action app-notify-action--no d-none" [class.d-none]="showError">
    <i class="fas fa-frown fa-2x app-notify-action__icon"></i><br />
    {{ 'web.ntf_error_info' | translate }}
</div>
<!-- <app-lang class="d-none"></app-lang> -->
