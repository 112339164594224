<ng-container *ngIf="banner && visible">
    <aside class="d-sm-none app-banner-mobile-layer">
        <a
            (click)="close()"
            class="app-banner-mobile-layer__close bottom-fixed-banner-close text-center app-cursor m-2 d-block"
            >{{ 'web.close' | translate }}</a
        >
        <a
            *ngIf="banner.url; else elseBlock"
            target="_blank"
            href="{{ banner.url }}?{{ utm() }}"
            [id]="bannerPositionId + ' | ' + banner.url"
            (click)="click()"
            class="app-banner-main"
        >
            <img
                src="{{ banner.path }}"
                class="banner"
                alt="{{ banner.altText }}"
                style="width: 100%"
                srcset="{{ banner.small_image }} 600w,
                {{ banner.medium_image }} 1024w,
                {{ banner.large_image }} 1600w"
            />
        </a>
        <ng-template #elseBlock>
            <img
                *ngIf="!banner.url"
                src="{{ banner.path }}"
                class="banner"
                alt="{{ banner.altText }}"
                style="width: 100%"
                srcset="{{ banner.small_image }} 600w,
                {{ banner.medium_image }} 1024w,
                {{ banner.large_image }} 1600w"
            />
        </ng-template>
    </aside>
</ng-container>
