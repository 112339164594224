<ng-container *ngIf="loaded">
    <ng-container *ngIf="!mobile; else isMobile">
        <a (click)="prev()" class="px-2 prev app-cursor" rel="nofollow" data-cy="calendar-prev"
            ><span class="sr-only">{{ 'web.previous' | translate }}</span></a
        >
        <a
            class="nav-link bg-white px-1 px-sm-2 text-first-letter app-calendar-date app-cursor"
            id="livescore-calendar"
            rel="nofollow"
        >
            <svg class="icon icon--16 mr-2"><use xlink:href="#icon-calendar" /></svg>
            {{ this.actualDateFormated | transinit }}
        </a>
        <a (click)="next()" rel="nofollow" class="px-2 next app-cursor"
            ><span class="sr-only">{{ 'web.next' | translate }}</span></a
        >
    </ng-container>
    <ng-template #isMobile>
        <div class="col-md-3 pt-md-3 mb-n2 mb-md-0">
            <div class="bg-white d-flex align-items-center justify-content-between px-2 h-100 py-2">
                <a
                    class="pr-6 prev app-cursor app-calendar-icon"
                    rel="nofollow"
                    data-cy="calendar-prev"
                    (click)="prev()"
                    ><span class="sr-only">{{ 'web.previous' | translate }}</span></a
                >
                <a class="px-2 app-calendar-date" data-cy="calendar-date" id="livescore-calendar">
                    <svg class="icon icon--16 mr-2">
                        <use href="#icon-calendar"></use>
                    </svg>
                </a>
                <select class="custom-select border-light2" [formControl]="date2" data-cy="calendar-select">
                    <option
                        *ngFor="let date of latestDatesVal; trackBy: trackByIndex"
                        [value]="dateFormat(date.moment)"
                    >
                        {{ date.format }}
                    </option>
                </select>

                <a
                    class="pl-6 next app-cursor app-calendar-icon"
                    rel="nofollow"
                    data-cy="calendar-next"
                    (click)="next()"
                    ><span class="sr-only">{{ 'web.next' | translate }}</span></a
                >
            </div>
        </div>
    </ng-template>
</ng-container>
<style>
    .app-calendar-icon.next::after {
        right: 7px;
        left: initial;
    }
</style>
