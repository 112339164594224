<div class="box-placeholder">
  <div class="px-4 pt-5 pb-4 row no-gutters">
      <div class="col-2">
          <div class="image-icon"></div>
      </div>
      <div class="col align-self-center">
             <span class="pro text" *ngIf="progress"></span>
      </div>
  </div>

  <div class="px-4 pt-2 pb-0">
    <span class="category text link2"></span>
  </div>

  <div class="px-4 pt-4 pb-0">
    <span class="category text link"></span>
  </div>

  <div class="excerpt px-4 pb-4">
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text"></div>
  </div>

  <div class="px-4 pt-4 pb-0">
    <span class="category text link"></span>
  </div>

  <div class="excerpt px-4 pb-4">
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text line"></div>
    <div class="text"></div>
  </div>
</div>
